import React from 'react';
import PropTypes from 'prop-types';

import {LEVEL} from '@core/user/photo/constants';
import useCorrectedPhotoLevel from '@core/user/photo/utils/useCorrectedPhotoLevel';

import VideoPreview from '@phoenix/user/video/containers/VideoPreview';
import VideoWithMotivation from '@phoenix/user/video/containers/VideoWithMotivation';
import NaughtyModeSize from '@phoenix/user/video/constants/NaughtyModeSize';
import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';

import css from '../styles/VideoLayout.css';

const VideoLayout = ({
  video,
  my,
  userId,
  withOverlay,
  motivationAfterVideo,
  skipBlur,
  onCanPlay,
}) => {
  const {previewUrl, url, level} = video;
  const {blurLevel, error, loading} = useCorrectedPhotoLevel({
    level,
    isMy: my,
    skipBlur,
  });

  if (loading) {
    return null;
  }

  if (error) {
    return <ErrorBoundary error={error} />;
  }

  if (!my && blurLevel !== LEVEL.NORMAL) {
    return (
      <VideoPreview
        video={video}
        my={my}
        userId={userId}
        naughtySize={NaughtyModeSize.MIDDLE}
      />
    );
  }

  return (
    <div className={css.container}>
      <VideoWithMotivation
        withOverlay={withOverlay}
        url={url}
        previewUrl={previewUrl}
        onCanPlay={onCanPlay}
        controls
        motivationAfterVideo={motivationAfterVideo}
      />
    </div>
  );
};

VideoLayout.propTypes /* remove-proptypes */ = {
  video: PropTypes.shape({
    previewUrl: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    level: PropTypes.oneOf(Object.values(LEVEL)),
  }).isRequired,
  my: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  withOverlay: PropTypes.bool,
  motivationAfterVideo: PropTypes.node,
  skipBlur: PropTypes.bool,
  onCanPlay: PropTypes.func,
};

VideoLayout.defaultProps = {
  withOverlay: false,
};

export default VideoLayout;
