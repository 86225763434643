import React, {Fragment, memo, useCallback, useRef} from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import scrollIntoView from '@core/utils/scroll/scrollIntoView';
import formatCardValue from '@core/payment/forms/card/utils/formatCardValue';

import {Align} from '@phoenix/ui/constants';
import {Link, TextMuted} from '@phoenix/typography';
import {RowLarge, InlineGroup} from '@phoenix/ui';

import PaymentCardFormWillChargedInfo from '../containers/PaymentCardFormWillChargedInfo';
import css from '../styles/PaymentCardOneClickInfo.css';

/**
 * @const {String}
 */
const PLACEHOLDER_CARD_NUMBER = '**** **** **** ****';

// This component tells the user that payment will be made by oneClick
const PaymentCardOneClickInfo = memo(
  ({
    cardNumber,
    inline,
    isPaypalOneClickAllowed,
    oneClickUsed,
    showChargeInfo,
    onClick,
    redirectUrl,
    withBigTextSize,
  }) => {
    const cardInfoRef = useRef();

    // Invoke onClick from and scroll into view if it needs
    const handleClick = useCallback(
      (values) => {
        onClick(values);

        if (window.IS_INTEGRATION_TEST_ENVIRONMENT) {
          return;
        }

        if (cardInfoRef.current) {
          if (oneClickUsed) {
            scrollIntoView({currentElem: cardInfoRef.current});
          } else {
            /**
             * We use a timeout to fix the incomplete scroll bug on some ios versions.
             * For example iphone 8 ios v.15
             */
            setTimeout(() => {
              window.scrollTo({top: 0, behavior: 'smooth'});
            }, 100);
          }
        }
      },
      [onClick, oneClickUsed],
    );

    const Wrap = inline ? InlineGroup : Fragment;

    return (
      <div ref={cardInfoRef}>
        <RowLarge
          horizontal={inline}
          align={Align.CENTER}
          className={css.details}
        >
          <Wrap>
            <TextMuted
              inline={inline}
              small={!withBigTextSize}
              bold={withBigTextSize}
            >
              {isPaypalOneClickAllowed
                ? t('paymentPage', 'text.pay_pal_one_click_details')
                : t('paymentPage', 'text.current_payment_details')}
            </TextMuted>
            {!isPaypalOneClickAllowed && (
              <TextMuted inline={inline} small={!withBigTextSize}>
                {cardNumber
                  ? formatCardValue(cardNumber)
                  : PLACEHOLDER_CARD_NUMBER}
              </TextMuted>
            )}
            <Link
              data-test="changePaymentDetails"
              onClick={handleClick}
              trackingName={
                oneClickUsed ? 'changePaymentDetails' : 'hidePaymentDetails'
              }
              href={redirectUrl}
              small={!withBigTextSize}
            >
              {oneClickUsed
                ? t('paymentPage', 'text.change_payment_details')
                : t('paymentPage', 'text.hide_payment_details')}
            </Link>
          </Wrap>
        </RowLarge>
        {showChargeInfo && !inline && oneClickUsed && (
          <PaymentCardFormWillChargedInfo oneClickUsed={oneClickUsed} />
        )}
      </div>
    );
  },
);

PaymentCardOneClickInfo.propTypes /* remove-proptypes */ = {
  cardNumber: PropTypes.string,
  inline: PropTypes.bool,
  oneClickUsed: PropTypes.bool,
  showChargeInfo: PropTypes.bool,
  onClick: PropTypes.func,
  redirectUrl: PropTypes.string, // In case if we want to redirect user somewhere
  withBigTextSize: PropTypes.bool,
  isPaypalOneClickAllowed: PropTypes.bool,
};

PaymentCardOneClickInfo.defaultProps = {
  showChargeInfo: true,
};

export default PaymentCardOneClickInfo;
