import {Action, ViaEnum} from '@core/types';
import PopupService from '@core/popup/utils/PopupService';
import type {
  PaymentAnswer,
  PaymentAnswerAbortable,
} from '@core/payment/payProcess/types/paymentAnswerProps';
import getHistory from '@core/application/utils/getHistory';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import {PAYMENT_FLOW_ABORT} from '@core/payment/payProcess/utils/getProcessPaymentFlow';

import openFreeTrialSuccessPaymentPopup from '@phoenix/freeFeaturePopup/utils/openFreeTrialSuccessPaymentPopup';

const showFreeTrialSuccessPaymentCompletePopup = (
  paymentAnswer: PaymentAnswer,
): PaymentAnswerAbortable => {
  const {status, method, prevVia, stockId} = paymentAnswer;

  if (status) {
    PopupService.closePopup();
    openFreeTrialSuccessPaymentPopup();
  } else {
    getHistory().push(
      generatePayUrl({
        stage: Action.features,
        urlParams: {
          prevVia,
          viaMethod: method,
          stockId,
          skipStepOnBack: false,
          via: ViaEnum.inpage_decline,
        },
      }),
    );

    return PAYMENT_FLOW_ABORT;
  }

  return paymentAnswer;
};

export default showFreeTrialSuccessPaymentCompletePopup;
