import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import Timer from '@core/utils/timer';

import PopupFrame from '@phoenix/popup/components/PopupFrame';
import {H3, TextMuted} from '@phoenix/typography';
import {Button, Progress, Icon, Row, RowShort, SpacingShort} from '@phoenix/ui';
import {
  Align,
  IconSize,
  ButtonType,
  SpacingDirection,
} from '@phoenix/ui/constants';

import FreeFeatureBenefits from './FreeFeatureBenefits';
import freeFeatureConfigPropType from '../constants/freeFeatureConfigPropType';
import css from '../styles/FreeFeatureStartPopupLayout.css';

const FreeFeatureStartPopupLayout = ({
  progress,
  config,
  onTimeUpdate,
  onActionClick,
  onTimeOver,
}) => (
  <PopupFrame
    content={
      <Fragment>
        <Row align={Align.CENTER}>
          <div className={css.countdown}>
            <Progress rotate={false} startingPoint={0} percentage={progress}>
              <Icon
                size={IconSize.SIZE_36}
                type={config.icon}
                className={config.animationClass}
              />
            </Progress>
          </div>
        </Row>
        <RowShort align={Align.CENTER}>
          <H3>{config.title}</H3>
        </RowShort>
        <RowShort align={Align.CENTER}>
          <TextMuted>{config.text}</TextMuted>
        </RowShort>
        <RowShort className={css.timerRow}>
          <TextMuted>{t('microfeatures', 'text.time_left')}</TextMuted>
          <SpacingShort direction={SpacingDirection.HORIZONTAL} stretch={false}>
            <Icon type="clock" muted />
          </SpacingShort>
          <Timer
            backward
            initialTime={config.time}
            duration={config.time}
            onTimeUpdate={onTimeUpdate}
            onTimeOver={onTimeOver}
          />
        </RowShort>
        <FreeFeatureBenefits benefits={config.benefits} />
      </Fragment>
    }
    actions={
      <Button
        fullWidth
        type={ButtonType.PRIMARY}
        onClick={onActionClick}
        trackingName="action"
      >
        {config.actionText}
      </Button>
    }
  />
);

FreeFeatureStartPopupLayout.propTypes /* remove-proptypes */ = {
  progress: PropTypes.number.isRequired,
  config: freeFeatureConfigPropType.isRequired,
  onTimeUpdate: PropTypes.func.isRequired,
  onActionClick: PropTypes.func.isRequired,
  onTimeOver: PropTypes.func.isRequired,
};

export default FreeFeatureStartPopupLayout;
