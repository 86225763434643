import React, {memo, useState, useEffect, forwardRef} from 'react';

/* eslint-disable no-restricted-imports */
import type {AccordionProps} from '@core/ui/components/accordion/Accordion';
import type {AccordionGroupProps} from '@core/ui/components/accordion/AccordionGroup';
import type {ActionProps} from '@core/ui/components/actions/Action';
import type {ActionsProps} from '@core/ui/components/actions/Actions';
import type {AttachedButtonProps} from '@core/ui/components/attachedButton/AttachedButton';
import type {BadgeProps} from '@core/ui/components/badge/Badge';
import type {BarProps} from '@core/ui/components/card/Bar';
import type {ButtonProps} from '@core/ui/components/button/Button';
import type {ButtonLinkProps} from '@core/ui/components/button/ButtonLink';
import type {CardProps, CardShadowLevel} from '@core/ui/components/card/Card';
import type {
  CheckboxProps,
  ChangeCustomEventHandler,
} from '@core/ui/components/checkbox/Checkbox';
import type {CheckboxGroupProps} from '@core/ui/components/checkbox/CheckboxGroup';
import type {ContainerProps} from '@core/ui/components/container/Container';
import type {GroupProps} from '@core/ui/components/group/Group';
import type {IconProps} from '@core/ui/components/icon/Icon';
import type {IconSubstrateProps} from '@core/ui/components/icon/IconSubstrate';
import type {FittingImageProps} from '@core/ui/components/image/FittingImage';
import type {BlurredImageProps} from '@core/ui/components/image/BlurredImage';
import type {ImageSubstrateProps} from '@core/ui/components/image/ImageSubstrate';
import type {InputProps} from '@core/ui/components/input/Input';
import type {InputFrameProps} from '@core/ui/components/input/InputFrame';
import type {ErrorMessageProps} from '@core/ui/components/input/ErrorMessage';
import type {TextareaProps} from '@core/ui/components/input/Textarea';
import type {LoaderProps} from '@core/ui/components/loader/Loader';
import type {LabelProps} from '@core/ui/components/label/Label';
import type {NoticeProps} from '@core/ui/components/notice/Notice';
import type {OverlayProps} from '@core/ui/components/overlay/Overlay';
import type {PlaceholderBarProps} from '@core/ui/components/placeholder/PlaceholderBar';
import type {PlaceholderBlockProps} from '@core/ui/components/placeholder/PlaceholderBlock';
import type {PlaceholderCircleProps} from '@core/ui/components/placeholder/PlaceholderCircle';
import type {BarProgressProps} from '@core/ui/components/progress/BarProgress';
import type {ProgressProps} from '@core/ui/components/progress/Progress';
import type {RadioProps} from '@core/ui/components/radio/Radio';
import type {RadioGroupProps} from '@core/ui/components/radio/RadioGroup';
import type {RowProps} from '@core/ui/components/row/Row';
import type {SeparatorProps} from '@core/ui/components/separator/Separator';
import type {SpacingProps} from '@core/ui/components/spacing/Spacing';
import type {StepsProps} from '@core/ui/components/steps/Steps';
import type {TabProps} from '@core/ui/components/tab/Tab';
import type {TabGroupProps} from '@core/ui/components/tab/TabGroup';
import type {TabLinkProps} from '@core/ui/components/tab/TabLink';
import type {TagProps} from '@core/ui/components/tag/Tag';
import type {
  MenuItemProps,
  MenuItemClickHandler,
} from '@core/ui/components/menu/MenuItem';
import type {MenuProps} from '@core/ui/components/menu/Menu';
import type {MenuItemContentProps} from '@core/ui/components/menu/MenuItemContent';
import type {SelectFrameProps} from '@core/ui/components/select/SelectFrame';
import type {SelectProps} from '@core/ui/components/select/Select';
import type {MultipleSelectProps} from '@core/ui/components/select/MultipleSelect';
import type {PopoverProps, Popper} from '@core/ui/components/popper/Popover';
import type {TooltipProps} from '@core/ui/components/popper/Tooltip';
import type {ClosablePopoverProps} from '@core/ui/components/popper/ClosablePopover';
import type {MapProps} from '@core/ui/components/map';
import type {SliderProps} from '@core/ui/components/slider/Slider';
/* eslint-enable no-restricted-imports */
import getTheme from '@core/application/utils/getTheme';

import UI_KIT_MODULES from '@phoenix/theming/constants/uiKitModules';

export type {
  AccordionProps,
  AccordionGroupProps,
  ActionProps,
  ActionsProps,
  AttachedButtonProps,
  BadgeProps,
  BarProps,
  ButtonProps,
  ButtonLinkProps,
  CardProps,
  CardShadowLevel,
  CheckboxProps,
  CheckboxGroupProps,
  ContainerProps,
  GroupProps,
  IconProps,
  IconSubstrateProps,
  FittingImageProps,
  BlurredImageProps,
  ImageSubstrateProps,
  InputProps,
  InputFrameProps,
  ErrorMessageProps,
  TextareaProps,
  LoaderProps,
  LabelProps,
  NoticeProps,
  OverlayProps,
  PlaceholderBarProps,
  PlaceholderBlockProps,
  PlaceholderCircleProps,
  BarProgressProps,
  ProgressProps,
  RadioProps,
  ChangeCustomEventHandler,
  RadioGroupProps,
  RowProps,
  SeparatorProps,
  SliderProps,
  SpacingProps,
  StepsProps,
  TabProps,
  TabGroupProps,
  TabLinkProps,
  TagProps,
  MenuItemProps,
  MenuProps,
  MenuItemContentProps,
  MenuItemClickHandler,
  SelectFrameProps,
  SelectProps,
  MultipleSelectProps,
  Popper,
  PopoverProps,
  TooltipProps,
  ClosablePopoverProps,
  MapProps,
};

let UIKit;

const UIKitPromise = UI_KIT_MODULES[getTheme().ui]().then((components) => {
  UIKit = components;
  return components;
});

/**
 * Creator function for single UI kit widget (component).
 * In a nutshell it creates a proxy component that imitates "deferred"
 * rendering of component until UI kit isn't already loaded.
 *
 * Eslint was disabled for hooks since it doesn't recognize components
 * inside function creators.
 */
/* eslint-disable react-hooks/rules-of-hooks */
const createDeferredUIComponent = <T extends Record<string, any>, R = null>(
  name: string,
) => {
  const deferredComponent = forwardRef<R, T>((props, ref) => {
    const [component, setComponent] = useState(null);

    useEffect(() => {
      let isMounted = true;
      // If UI kit is already loaded - don't need to find component in pending promise
      if (!UIKit) {
        // If UI kit is loading - pipe it and when is loaded - set component
        UIKitPromise.then((components) => {
          if (isMounted) {
            // Since without function react interprets component as updater function
            setComponent(() => components[name]);
          }
        });
      }

      return () => {
        isMounted = false;
      };
    }, []);

    // Fallback until UI kit isn't loaded yet
    if (!component && !UIKit) return null;

    // Can't set component directly in state if ui kit is loaded
    // since react doesn't like components in state and throws strange errors.
    const Component = UIKit ? UIKit[name] : component;

    // When component if ready - attach all passed props and render it
    return <Component {...props} ref={ref} />;
  });

  deferredComponent.displayName = `Deferred${name}`;

  return memo(deferredComponent);
};
/* eslint-enable react-hooks/rules-of-hooks */

export const Accordion = createDeferredUIComponent<AccordionProps>('Accordion');
export const AccordionGroup =
  createDeferredUIComponent<AccordionGroupProps>('AccordionGroup');
export const Actions = createDeferredUIComponent<ActionsProps>('Actions');
export const Action = createDeferredUIComponent<ActionProps>('Action');
export const AttachedButton =
  createDeferredUIComponent<AttachedButtonProps>('AttachedButton');
export const Badge = createDeferredUIComponent<BadgeProps>('Badge');
export const Bar = createDeferredUIComponent<BarProps, HTMLDivElement>('Bar');
export const Steps = createDeferredUIComponent<StepsProps>('Steps');
export const Button = createDeferredUIComponent<ButtonProps>('Button');

/**
 * Is used for `Button*` components where `type` is defined inside.
 */
type ButtonPropsWithoutType = Omit<ButtonProps, 'type'>;
export const ButtonPrimary =
  createDeferredUIComponent<ButtonPropsWithoutType>('ButtonPrimary');
export const ButtonPay =
  createDeferredUIComponent<ButtonPropsWithoutType>('ButtonPay');
export const ButtonFlat =
  createDeferredUIComponent<ButtonPropsWithoutType>('ButtonFlat');
export const ButtonFlatCover =
  createDeferredUIComponent<ButtonPropsWithoutType>('ButtonFlatCover');

export const ButtonLink =
  createDeferredUIComponent<ButtonLinkProps>('ButtonLink');
export const ButtonLinkPrimary =
  createDeferredUIComponent<Omit<ButtonLinkProps, 'type'>>('ButtonLinkPrimary');
export const Card = createDeferredUIComponent<CardProps>('Card');
export const Checkbox = createDeferredUIComponent<CheckboxProps>('Checkbox');
export const CheckboxGroup =
  createDeferredUIComponent<CheckboxGroupProps>('CheckboxGroup');
export const Container = createDeferredUIComponent<ContainerProps>('Container');
export const Group = createDeferredUIComponent<GroupProps, HTMLDivElement>(
  'Group',
);
export const InlineGroup = createDeferredUIComponent<GroupProps>('InlineGroup');
export const Icon = createDeferredUIComponent<IconProps>('Icon');
export const IconSubstrate =
  createDeferredUIComponent<IconSubstrateProps>('IconSubstrate');
export const FittingImage =
  createDeferredUIComponent<FittingImageProps>('FittingImage');
export const BlurredImage =
  createDeferredUIComponent<BlurredImageProps>('BlurredImage');
export const ImageSubstrate =
  createDeferredUIComponent<ImageSubstrateProps>('ImageSubstrate');

export const Input = createDeferredUIComponent<
  Omit<InputProps, 'inputComponent'>,
  HTMLInputElement
>('Input');
export const InputFrame =
  createDeferredUIComponent<InputFrameProps>('InputFrame');
export const ErrorMessage =
  createDeferredUIComponent<ErrorMessageProps>('ErrorMessage');
export const Textarea = createDeferredUIComponent<TextareaProps>('Textarea');
export const Label = createDeferredUIComponent<LabelProps>('Label');
export const Loader = createDeferredUIComponent<LoaderProps>('Loader');
export const Menu = createDeferredUIComponent<MenuProps>('Menu');
export const MenuItem = createDeferredUIComponent<MenuItemProps, HTMLElement>(
  'MenuItem',
);
export const MenuItemContent =
  createDeferredUIComponent<MenuItemContentProps>('MenuItemContent');
export const Notice = createDeferredUIComponent<NoticeProps>('Notice');
export const Overlay = createDeferredUIComponent<OverlayProps>('Overlay');
export const PlaceholderBar =
  createDeferredUIComponent<PlaceholderBarProps>('PlaceholderBar');
export const PlaceholderInput =
  createDeferredUIComponent<PlaceholderBarProps>('PlaceholderInput');
export const PlaceholderCircle =
  createDeferredUIComponent<PlaceholderCircleProps>('PlaceholderCircle');
export const PlaceholderBlock =
  createDeferredUIComponent<PlaceholderBlockProps>('PlaceholderBlock');
export const Tooltip = createDeferredUIComponent<TooltipProps>('Tooltip');
export const Popover = createDeferredUIComponent<PopoverProps, Popper>(
  'Popover',
);
export const ClosablePopover =
  createDeferredUIComponent<Omit<ClosablePopoverProps, 'buttonComponent'>>(
    'ClosablePopover',
  );
export const Progress = createDeferredUIComponent<
  ProgressProps,
  HTMLDivElement
>('Progress');
export const BarProgress =
  createDeferredUIComponent<BarProgressProps>('BarProgress');
export const Radio = createDeferredUIComponent<RadioProps>('Radio');
export const RadioGroup =
  createDeferredUIComponent<RadioGroupProps>('RadioGroup');
export const Row = createDeferredUIComponent<RowProps>('Row');
/**
 * Is used for `Row*` components where `space` is defined inside.
 */
type RowPropsWithoutSpace = Omit<RowProps, 'space'>;

export const RowShortest =
  createDeferredUIComponent<RowPropsWithoutSpace>('RowShortest');
export const RowShort =
  createDeferredUIComponent<RowPropsWithoutSpace>('RowShort');
export const RowLarge =
  createDeferredUIComponent<RowPropsWithoutSpace>('RowLarge');
export const RowLargest =
  createDeferredUIComponent<RowPropsWithoutSpace>('RowLargest');

export const Select =
  createDeferredUIComponent<
    Omit<SelectProps, 'frameComponent' | 'menuComponent'>
  >('Select');
export const SelectFrame =
  createDeferredUIComponent<Omit<SelectFrameProps, 'frameComponent'>>(
    'SelectFrame',
  );
export const MultipleSelect =
  createDeferredUIComponent<MultipleSelectProps>('MultipleSelect');
export const SelectItem = createDeferredUIComponent<MenuItemProps, HTMLElement>(
  'SelectItem',
);
export const SelectItemContent =
  createDeferredUIComponent<MenuItemContentProps>('SelectItemContent');
export const Separator = createDeferredUIComponent<
  SeparatorProps,
  HTMLDivElement
>('Separator');
export const Slider = createDeferredUIComponent<SliderProps>('Slider');
export const Spacing = createDeferredUIComponent<SpacingProps, HTMLDivElement>(
  'Spacing',
);

/**
 * Is used for `Spacing*` components where `size` is defined inside.
 */
type SpacingPropsWithoutSize = Omit<SpacingProps, 'size'>;

export const SpacingShortest = createDeferredUIComponent<
  SpacingPropsWithoutSize,
  HTMLDivElement
>('SpacingShortest');
export const SpacingShort = createDeferredUIComponent<
  SpacingPropsWithoutSize,
  HTMLDivElement
>('SpacingShort');
export const SpacingLarge = createDeferredUIComponent<
  SpacingPropsWithoutSize,
  HTMLDivElement
>('SpacingLarge');

export const Tab = createDeferredUIComponent<
  Omit<TabProps, 'css'>,
  HTMLButtonElement
>('Tab');
export const TabLink = createDeferredUIComponent<
  Omit<TabLinkProps, 'css'>,
  HTMLAnchorElement
>('TabLink');
export const TabGroup = createDeferredUIComponent<TabGroupProps>('TabGroup');
export const Tag = createDeferredUIComponent<TagProps>('Tag');

type TagPropsWithoutType = Omit<TagProps, 'type'>;

export const TagAccent =
  createDeferredUIComponent<TagPropsWithoutType>('TagAccent');
export const TagDanger =
  createDeferredUIComponent<TagPropsWithoutType>('TagDanger');
export const TagPrimary =
  createDeferredUIComponent<TagPropsWithoutType>('TagPrimary');
export const TagSuccess =
  createDeferredUIComponent<TagPropsWithoutType>('TagSuccess');
export const TagTranslucent =
  createDeferredUIComponent<TagPropsWithoutType>('TagTranslucent');
export const Map = createDeferredUIComponent<MapProps>('Map');
