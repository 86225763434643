import {useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';

import getPrimaryPhotoData from '@core/user/photo/utils/getPrimaryPhotoData';
import type {PrimaryPhotoData} from '@core/user/photo/types';

import TARGET_USER_PRIMARY_PHOTO_QUERY from '../graphql/queries/targetUserPrimaryPhoto.gql';
import type {
  TargetUserPrimaryPhotoQuery,
  TargetUserPrimaryPhotoQueryVariables,
} from '../graphql/queries/targetUserPrimaryPhoto';

type UseTargetUserMainPhotoDataParams = {
  userId: string;
  skip?: boolean;
};

type UseTargetUserMainPhotoDataResult = {
  isWebcamProfile?: boolean;
  isPaid?: boolean;
  photo?: PrimaryPhotoData;
  online?: boolean;
  recentlyOnline?: boolean;
  loading: boolean;
  error?: Error;
};

const useTargetUserMainPhotoData = ({
  userId,
  skip = false,
}: UseTargetUserMainPhotoDataParams): UseTargetUserMainPhotoDataResult => {
  const {partnerName} = useParams<{partnerName: string}>();

  const {loading, error, data} = useQuery<
    TargetUserPrimaryPhotoQuery,
    TargetUserPrimaryPhotoQueryVariables
  >(TARGET_USER_PRIMARY_PHOTO_QUERY, {
    variables: {userId, partner: partnerName},
    skip,
  });

  if (loading || error || skip) {
    return {loading, error};
  }

  const {
    profile: {
      statuses: {isOnline, isRecentlyOnline, isWebcamProfile},
    },
    viewPermissions: {photo: photoPermission},
  } = data!.user;

  const photo = getPrimaryPhotoData(data!.user.profile);

  return {
    isWebcamProfile,
    isPaid: photoPermission,
    photo,
    online: isOnline,
    recentlyOnline: isRecentlyOnline,
    loading,
  };
};

export default useTargetUserMainPhotoData;
