import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';
import {getClientInstance} from '@core/graphql/client';
import type {PaymentAnswer} from '@core/payment/payProcess/types/paymentAnswerProps';

/**
 * Live cam search can change after pay success, and it needs to flush user list not to have
 * double search output in live cam list
 * @TODO[BB_removed]: Think about invalidate cache in 'updateCacheAfterPayment' util by 'action' prop,
 *   because not to need to invalidate cache here, e.x., if action is 'remarketingOffer' or 'features',
 *   only if it's membership action.
 */
const invalidateSearchLivecamAfterSuccess = (
  paymentAnswer: PaymentAnswer,
): PaymentAnswer => {
  if (paymentAnswer.status) {
    invalidateCacheByTypename(getClientInstance(), 'Search', 'livecam');
  }

  return paymentAnswer;
};

export default invalidateSearchLivecamAfterSuccess;
