import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import {Group, Bar, SpacingLarge, Spacing, RowShort} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';
import {Text, TextMuted} from '@phoenix/typography';
import PopupFrame from '@phoenix/popup/components/PopupFrame';

import MediaUploadPopupBack from './MediaUploadPopupBack';
import {mediaUploadPopupPropTypes} from '../constants/propTypes';
import css from '../styles/MediaUploadPopupLayout.css';

const MediaUploadRequirements = ({
  requirements,
  light,
  wrappedRequirements,
  small,
}) => {
  if (wrappedRequirements) {
    return (
      <Bar spacingSize={SpacingSize.NONE}>
        <Spacing>
          <TextMuted small>{requirements}</TextMuted>
        </Spacing>
      </Bar>
    );
  }

  if (light) {
    return (
      <Text
        small={small}
        align={TextMuted.ALIGN.CENTER}
        type={Text.TYPE.DISABLED}
      >
        {requirements}
      </Text>
    );
  }

  return (
    <TextMuted small={small} align={TextMuted.ALIGN.CENTER}>
      {requirements}
    </TextMuted>
  );
};

MediaUploadRequirements.propTypes /* remove-proptypes */ = {
  requirements: PropTypes.node.isRequired,
  light: PropTypes.bool,
  wrappedRequirements: PropTypes.bool,
  small: PropTypes.bool,
};

/**
 * Default layout with anything placed inside content.
 */
const MediaUploadPopupLayout = ({
  showBackButton,
  onBackClick,
  spacedAbove,
  unspacedBelow,
  spacedMotivation,
  // Content props that are conditionally displayed
  motivation,
  preview,
  webcam,
  upload,
  requirements,
  webcamRequirements,
  wrappedRequirements,
  requirementsAboveActions,
  light,
  error,
  pmaCheckbox,
  footer,
  spacedFooter,
  withoutTop,
  contentSpace,
}) => {
  return (
    <PopupFrame
      spacedAbove={spacedAbove}
      unspacedBelow={unspacedBelow}
      spacedContent={false}
      content={
        <Fragment>
          {!spacedMotivation && motivation && <RowShort>{motivation}</RowShort>}
          <SpacingLarge withoutTop={withoutTop} className={css.contentWrapper}>
            <Group space={contentSpace}>
              {(preview || webcam) && (
                <Group>
                  <Spacing
                    onlyTop
                    size={spacedAbove ? SpacingSize.NONE : SpacingSize.LARGE}
                  >
                    {preview}
                    {webcam}
                  </Spacing>
                  {webcamRequirements && (
                    <TextMuted small align={TextMuted.ALIGN.CENTER}>
                      {webcamRequirements}
                    </TextMuted>
                  )}
                </Group>
              )}
              {
                // prettier-ignore
                ((spacedMotivation && motivation) || (requirementsAboveActions && error) || (requirementsAboveActions && requirements)) && (
                <Group>
                  {spacedMotivation && motivation}
                  {requirementsAboveActions && requirements && (
                    <MediaUploadRequirements
                      light={light}
                      requirements={requirements}
                      wrappedRequirements={wrappedRequirements}
                    />
                  )}
                  {requirementsAboveActions && error}
                </Group>
              )
              }
              {
                // prettier-ignore
                (upload || (!requirementsAboveActions && error) || (!requirementsAboveActions && requirements)) && (
                <Group>
                  {upload}
                  {!requirementsAboveActions && requirements &&(
                    <MediaUploadRequirements
                      small
                      light={light}
                      requirements={requirements}
                      wrappedRequirements={wrappedRequirements}
                    />
                  )}
                  {!requirementsAboveActions && error}
                </Group>
              )
              }
              {pmaCheckbox}
            </Group>
          </SpacingLarge>
          {showBackButton && <MediaUploadPopupBack onClick={onBackClick} />}
        </Fragment>
      }
      footer={
        footer && (
          <Bar
            withoutTop
            transparent
            {...(!spacedFooter && {
              spacingSize: SpacingSize.NONE,
              rounded: false,
            })}
          >
            {footer({wrappedFooter: true})}
          </Bar>
        )
      }
    />
  );
};

MediaUploadPopupLayout.propTypes /* remove-proptypes */ = mediaUploadPopupPropTypes;

MediaUploadPopupLayout.defaultProps = {
  unspacedBelow: true,
  light: false,
  spacedMotivation: true,
  spacedFooter: true,
  withoutTop: true,
  contentSpace: SpacingSize.LARGE,
  requirementsAboveActions: false,
};

export default MediaUploadPopupLayout;
