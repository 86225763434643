import type {FC} from 'react';
import React from 'react';

import t from '@core/translations/translate';

import {ButtonPrimary, Button} from '@phoenix/ui';
import {TextMuted} from '@phoenix/typography';
import PopupNotice from '@phoenix/popup/components/PopupNotice';

type InappropriateContentSendPopupLayoutProps = {
  handleSendMessageClick: () => void;
  handleCancelMessageClick: () => void;
  disabled: boolean;
};

const InappropriateContentSendPopupLayout: FC<
  InappropriateContentSendPopupLayoutProps
> = ({handleSendMessageClick, handleCancelMessageClick, disabled}) => (
  <PopupNotice
    data-test="mediaFilteredByContentLevelPopupLayout"
    icon="danger"
    title={t('messenger', 'text.still_send')}
    description={
      <TextMuted>{t('messenger', 'text.inappropriate_content_send')}</TextMuted>
    }
    actions={[
      <ButtonPrimary
        onClick={handleSendMessageClick}
        trackingName="send"
        disabled={disabled}
        fullWidth
      >
        {t('messenger', 'button.send_anyway')}
      </ButtonPrimary>,
      <Button
        onClick={handleCancelMessageClick}
        trackingName="cancel"
        disabled={disabled}
        fullWidth
      >
        {t('messenger', 'button.cancel')}
      </Button>,
    ]}
  />
);

export default InappropriateContentSendPopupLayout;
