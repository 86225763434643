import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import CoinsCommonSuccessPopup from '../components/CoinsCommonSuccessPopup';

/**
 * Popup with message about success coins purchase, with animation
 * @param packages {array}
 * @param onButtonClick {function}
 * @return {JSX.Element}
 * @constructor
 */
const CoinsSuccessPopup = ({onButtonClick, packages}) => {
  return (
    <CoinsCommonSuccessPopup
      packages={packages}
      title={t('coins', 'title.success')}
      description={t('coins', 'text.success_description')}
      action={t('credits', 'button.got_it')}
      onButtonClick={onButtonClick}
    />
  );
};

CoinsSuccessPopup.propTypes /* remove-proptypes */ = {
  packages: PropTypes.arrayOf(PropTypes.string).isRequired,
  onButtonClick: PropTypes.func,
};

export default CoinsSuccessPopup;
