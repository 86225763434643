import React from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import isInRouteList from '@core/utils/routing/isInRouteList';
import FULLSCREEN_ROUTES from '@core/application/constants/fullscreenRoutes';
import ROUTES from '@core/application/constants/routes';

import HeaderTopWrapper from './HeaderTopWrapper';

/**
 * By design there should be no spacing between header and toolbar
 * on search page even if toolbar (subheader) isn't sticked.
 * @const {String}
 */
const SHIFTED_ROUTE = '/search';

const LocationDependentHeaderTopWrapper = ({
  children,
  sticked,
  withoutTop,
  withoutBottom,
  unneeded,
  ...props
}) => {
  const location = useLocation();
  /* Don't add ROUTES.DESCRIPTOR to FULLSCREEN_ROUTES because this disabled footer on it.
     On ROUTES.DESCRIPTOR used footer */
  const isFullScreenRoute = isInRouteList(
    [...FULLSCREEN_ROUTES, ROUTES.DESCRIPTOR],
    location.pathname,
  );
  const isPhone = usePhoneBreakpoint();

  return (
    <HeaderTopWrapper
      shiftedTop={!sticked && !isPhone && location.pathname === SHIFTED_ROUTE}
      {...props}
      withoutTop={withoutTop || isFullScreenRoute}
      withoutBottom={withoutBottom || isFullScreenRoute}
      unneeded={unneeded}
    >
      {children}
    </HeaderTopWrapper>
  );
};

LocationDependentHeaderTopWrapper.defaultProps = {
  withoutTop: true,
  withoutBottom: false,
  sticked: true,
};

LocationDependentHeaderTopWrapper.propTypes /* remove-proptypes */ = {
  withoutTop: PropTypes.bool,
  withoutBottom: PropTypes.bool,
  children: PropTypes.node.isRequired,
  sticked: PropTypes.bool,
  /**
   * This wrapper is used even when unneeded.
   * It allows to keep consistent components tree and avoid children re-mount.
   * In such case this prop will be `true`.
   */
  unneeded: PropTypes.bool,
};

export default LocationDependentHeaderTopWrapper;
