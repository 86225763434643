import PopupService from '@core/popup/utils/PopupService';
import getHistory from '@core/application/utils/getHistory';
import getPayMethodWithBackboneModel from '@core/payment/payProcess/utils/getPayMethodWithBackboneModel';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import PAYMENT_METHODS from '@core/payment/common/constants/paymentMethods';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import ROUTES from '@core/application/constants/routes';
import trackPaymentClick from '@core/payment/common/utils/trackPaymentClick';
import PAY_BUTTON_TRACKING from '@core/payment/widgets/buttons/constants/payButtonTracking';
import {CARD_FORM_NAME} from '@core/payment/payProcess/constants/formNames';
import {getPageViewTime} from '@core/payment/payProcess/utils/pageViewTime';

import openFreeFeaturePopup from '@phoenix/freeFeaturePopup';
import FreeFeaturesNames from '@phoenix/freeFeaturePopup/constants/freeFeatureNames';
import {MICROFEATURES} from '@phoenix/microfeatures/common/constants/microfeatures';
import openMicrofeaturesSuccessPopup from '@phoenix/microfeatures/popups/utils/openMicrofeaturesSuccessPopup';
import updateCachesAfterPayment from '@phoenix/payment/payProcess/utils/updateCachesAfterPayment';
import {MODEL_TYPES} from '@phoenix/microfeatures/common/constants/modelTypes';
import getMicrofeatureData from '@phoenix/microfeatures/common/utils/getMicrofeatureData';

import MICROFEATURES_AVAILABILITY_DATA_QUERY from '../../microfeatures/common/graphql/queries/microfeaturesAvailabilityData.gql';
import MICROFEATURES_LIST_QUERY from '../../microfeatures/common/graphql/queries/microfeaturesList.gql';
import UPSELL_POPUP_STATISTIC_QUERY from '../graphql/queries/upsellPopupStatistic.gql';

/**
 * Open popup with statistic of usage free micro feature.
 * If there is no statistic by any reason, don't open popup.
 * @param {ApolloClient} client
 * @param {string} type
 * @return {Promise<void>}
 */
const openUpsellPopup = async (client, type) => {
  let name;
  /** Feature name need for get statistics */
  const featureName = type.substring(0, type.indexOf('PermissionEnd'));

  switch (featureName) {
    default:
      return;
    case MICROFEATURES.PRIORITY_MAN: {
      name = FreeFeaturesNames.PRIORITY_MAN_UPSELL;
      break;
    }
    case MICROFEATURES.SUPER_BOOST: {
      name = FreeFeaturesNames.BOOST_UPSELL;
      break;
    }
    case MICROFEATURES.SEX_ALARM: {
      name = FreeFeaturesNames.SEX_ALARM_UPSELL;
      break;
    }
  }

  const {
    data: {
      extraPopups: {upsellPopupStatistic},
      myUser,
    },
  } = await client.query({
    query: UPSELL_POPUP_STATISTIC_QUERY,
    variables: {featureName},
  });

  if (!upsellPopupStatistic.enabled) return;

  const {
    data: {
      microFeatures: {
        microFeaturesConfiguration: {modelType},
      },
    },
  } = await client.query({
    query: MICROFEATURES_AVAILABILITY_DATA_QUERY,
  });

  const {
    data: {microFeatures: microfeaturesList},
  } = await client.query({
    query: MICROFEATURES_LIST_QUERY,
    variables: {
      isCreditModel: modelType === MODEL_TYPES.CREDIT,
    },
  });

  const {stockId} = getMicrofeatureData(
    modelType,
    microfeaturesList,
    featureName,
  );
  const history = getHistory();

  const makePayment = getPayMethodWithBackboneModel({
    action: PAYMENT_ACTIONS.MICRO_FEATURES,
    method: PAYMENT_METHODS.CARD,
    payFromPopup: true,
  });

  const trackClick = () => {
    trackPaymentClick({
      action: PAYMENT_ACTIONS.MICRO_FEATURES,
      isFormHidden: true,
      isFrontendValidationPassed: true,
      isOneClickShown: false,
      isButtonActive: true,
      payButton: PAY_BUTTON_TRACKING.DEFAULT,
      paymentForm: CARD_FORM_NAME,
      timeInterval: getPageViewTime(),
    });
  };

  const handleActionClick = async () => {
    trackClick();

    const result = await makePayment({
      stockId,
      prevVia: featureName,
      hidePaymentForm: true,
      isInstantRepeat: 0,
    });

    if (result.status) {
      PopupService.closePopup();
      openMicrofeaturesSuccessPopup({featureName});
      await updateCachesAfterPayment();
    } else {
      const paymentVia = result.failPageLogic
        ? result.failPageLogic
        : featureName;

      const prevVia = result.failPageLogic ? featureName : null;

      const params = {};

      if (featureName === MICROFEATURES.TELEGRAM_CONVERSATION) {
        params.returnPath = ROUTES.SEARCH;
        params.skipStepOnBack = true;
      }

      history.push(
        generatePayUrl({
          stage: PAYMENT_ACTIONS.MICRO_FEATURES,
          urlParams: {
            prevVia,
            via: paymentVia,
            viaMethod: PAYMENT_METHODS.CARD,
            packageId: stockId,
            stage: PAYMENT_ACTIONS.CREDIT,
            ...params,
          },
        }),
      );
    }
  };

  openFreeFeaturePopup({
    name,
    onActionClick: handleActionClick,
    options: {
      ...upsellPopupStatistic,
      gender: myUser.profile.gender,
    },
  });
};

export default openUpsellPopup;
