import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import t, {createTranslationsMap} from '@core/translations';
import MountTracker from '@core/tracking/babcia/containers/MountTracker';

import {Loader, Row, RowLarge, SelectItem} from '@phoenix/ui';
import {Align} from '@phoenix/ui/constants';
import {FormInput} from '@phoenix/form/input';
import {TextMuted} from '@phoenix/typography';
import {FormSelect} from '@phoenix/form/select';
import GDPR from '@phoenix/gdpr/GDPR';

import {FIRST_STEP_FIELD_NAMES, MAX_LENGTH_FIELD} from '../contants/fields';
/**
 * @const {String}
 */
const {FIRST_NAME, LAST_NAME, COUNTRY, LANGUAGE, PHONE} =
  FIRST_STEP_FIELD_NAMES;

const LOCALES = createTranslationsMap((translate) => ({
  en: translate('paymentTrusted', 'text.sms.languageSelect.en'),
  es: translate('paymentTrusted', 'text.sms.languageSelect.es'),
  fr: translate('paymentTrusted', 'text.sms.languageSelect.fr'),
  da: translate('paymentTrusted', 'text.sms.languageSelect.da'),
  fi: translate('paymentTrusted', 'text.sms.languageSelect.fi'),
  de: translate('paymentTrusted', 'text.sms.languageSelect.de'),
  it: translate('paymentTrusted', 'text.sms.languageSelect.it'),
  pt: translate('paymentTrusted', 'text.sms.languageSelect.pt'),
  sv: translate('paymentTrusted', 'text.sms.languageSelect.sv'),
  cs: translate('paymentTrusted', 'text.sms.languageSelect.cs'),
  pl: translate('paymentTrusted', 'text.sms.languageSelect.pl'),
  ru: translate('paymentTrusted', 'text.sms.languageSelect.ru'),
  tr: translate('paymentTrusted', 'text.sms.languageSelect.tr'),
  ja: translate('paymentTrusted', 'text.sms.languageSelect.ja'),
  ko: translate('paymentTrusted', 'text.sms.languageSelect.ko'),
}));

const COUNTRIES = createTranslationsMap((translate) => ({
  GBR: translate('paymentTrusted', 'text.sms.countrySelect.GBR'),
  USA: translate('paymentTrusted', 'text.sms.countrySelect.USA'),
  CAN: translate('paymentTrusted', 'text.sms.countrySelect.CAN'),
  MEX: translate('paymentTrusted', 'text.sms.countrySelect.MEX'),
  AUS: translate('paymentTrusted', 'text.sms.countrySelect.AUS'),
  NZL: translate('paymentTrusted', 'text.sms.countrySelect.NZL'),
  CHL: translate('paymentTrusted', 'text.sms.countrySelect.CHL'),
  IND: translate('paymentTrusted', 'text.sms.countrySelect.IND'),
  BEL: translate('paymentTrusted', 'text.sms.countrySelect.BEL'),
  MYS: translate('paymentTrusted', 'text.sms.countrySelect.MYS'),
  SGP: translate('paymentTrusted', 'text.sms.countrySelect.SGP'),
  JPN: translate('paymentTrusted', 'text.sms.countrySelect.JPN'),
  KOR: translate('paymentTrusted', 'text.sms.countrySelect.KOR'),
  THA: translate('paymentTrusted', 'text.sms.countrySelect.THA'),
  HKG: translate('paymentTrusted', 'text.sms.countrySelect.HKG'),
  TWN: translate('paymentTrusted', 'text.sms.countrySelect.TWN'),
  IDN: translate('paymentTrusted', 'text.sms.countrySelect.IDN'),
  DEU: translate('paymentTrusted', 'text.sms.countrySelect.DEU'),
  NLD: translate('paymentTrusted', 'text.sms.countrySelect.NLD'),
  CHE: translate('paymentTrusted', 'text.sms.countrySelect.CHE'),
  AUT: translate('paymentTrusted', 'text.sms.countrySelect.AUT'),
}));

/**
 * @see TrustedVerificationSmsFirstStep.js
 */
const TrustedVerificationSmsFirstStepLayout = ({
  availableUserPersonalData,
  handleSubmit,
  isSubmitting,
  phonePrefix,
  locales,
  countries,
  handleChangeCountry,
  inPopup,
  actions,
}) => {
  let firstNameLabel;
  let lastNameLabel;

  if (availableUserPersonalData) {
    firstNameLabel = t('paymentTrusted', 'text.firstName');
    lastNameLabel = t('paymentTrusted', 'text.lastName');
  }

  return (
    <Loader active={isSubmitting}>
      <form
        onSubmit={handleSubmit}
        data-test="trustedVerificationFirstStep"
        id="firstStepForm"
      >
        {availableUserPersonalData ? (
          <Fragment>
            <Row>
              <FormInput
                data-test="firstNameInput"
                name={FIRST_NAME}
                label={firstNameLabel}
                placeholder={firstNameLabel}
                maxLength={MAX_LENGTH_FIELD}
                spellCheck={false}
                autoComplete="turn-off"
              />
            </Row>
            <Row>
              <FormInput
                data-test="lastNameInput"
                name={LAST_NAME}
                label={lastNameLabel}
                placeholder={lastNameLabel}
                maxLength={MAX_LENGTH_FIELD}
                spellCheck={false}
                autoComplete="turn-off"
              />
            </Row>
          </Fragment>
        ) : null}
        <Row>
          <FormInput
            data-test="phoneNumberInput"
            name={PHONE}
            prefix={phonePrefix}
            placeholder="XXXXXXXXXXX"
            label={t('paymentTrusted', 'text.phoneNumber')}
          />
        </Row>
        <Row>
          <FormSelect
            name={COUNTRY}
            label={t('paymentTrusted', 'text.country')}
            onChange={handleChangeCountry}
          >
            {countries.map(({country}) => (
              <SelectItem key={country} value={country}>
                {COUNTRIES[country]}
              </SelectItem>
            ))}
          </FormSelect>
        </Row>
        <Row>
          <FormSelect
            name={LANGUAGE}
            label={t('paymentTrusted', 'text.language')}
          >
            {locales.map((local) => (
              <SelectItem key={local} value={local}>
                {LOCALES[local]}
              </SelectItem>
            ))}
          </FormSelect>
        </Row>
        {inPopup ? (
          <Row align={Align.CENTER}>
            <TextMuted small>
              {t('paymentTrusted', 'text.proceed_verification')}
            </TextMuted>
          </Row>
        ) : (
          <RowLarge align={Align.CENTER}>
            {actions}
            <Row align={Align.CENTER}>
              <TextMuted small>
                {t('paymentTrusted', 'text.proceed_verification')}
              </TextMuted>
            </Row>
            <Row align={Align.CENTER}>
              <GDPR muted small />
            </Row>
          </RowLarge>
        )}
      </form>
      <MountTracker name={MountTracker.NAMES.PAGE_LAYOUT} />
    </Loader>
  );
};

TrustedVerificationSmsFirstStepLayout.propTypes /* remove-proptypes */ = {
  inPopup: PropTypes.bool,
  actions: PropTypes.node,
  availableUserPersonalData: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  phonePrefix: PropTypes.number.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      phone: PropTypes.number.isRequired,
      country: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  locales: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChangeCountry: PropTypes.func.isRequired,
};

TrustedVerificationSmsFirstStepLayout.defaultProps = {
  inPopup: false,
};

export default TrustedVerificationSmsFirstStepLayout;
