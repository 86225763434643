import React from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';

import useCorrectedPhotoLevel from '@core/user/photo/utils/useCorrectedPhotoLevel';
import {LEVEL, PRESET} from '@core/user/photo/constants';
import useCleanProductRestrictions from '@core/application/utils/useCleanProductRestrictions';
import createVideoPreviewUrl from '@core/user/video/utils/createVideoPreviewUrl';

import openNaughtyModePopup from '@phoenix/user/photo/utils/openNaughtyModePopup';
import PHOTO_DATA_QUERY from '@phoenix/user/photo/graphql/queries/photoData.gql';
import useThemedMediaPreview from '@phoenix/theming/utils/useThemedMediaPreview';
import {VIDEO} from '@phoenix/naughtyMode/constants/naughtyModePopupWidgetTypes';
import {ButtonType} from '@phoenix/ui/constants';

import NaughtyModeSize from '../constants/NaughtyModeSize';

const VideoPreview = ({
  video,
  mediaCounter,
  my,
  naughtySize,
  trackingName,
  onClick,
  onLoad,
  withNaughtyModePopup,
  payButtonText,
  onNaughtyModeClick,
  spacedPlayButton,
  scaling,
  forceLevel,
  showNavigationOverlay,
  showOverlay,
  showPlayButton,
  playButtonType,
  withLockOverlay,
  preset,
  isAdultChangeSizeAvailable,
  mediaCounters,
  naughtyModeText,
  skipBlur,
  customOverlay,
  withAutoplay,
}) => {
  const {loading, data: {videoPreview: VideoPreviewLayout} = {}} =
    useThemedMediaPreview();
  const {loading: galleryPhotoLoading} = useQuery(PHOTO_DATA_QUERY);
  const {withCleanProductRestrictions} = useCleanProductRestrictions();

  const {videoPreviewUrl, previewUrl, level} = video;
  const {level: correctedLevel, blurLevel} = useCorrectedPhotoLevel({
    level,
    forceLevel,
    isMy: my,
    skipBlur,
  });

  const openPopupHandler = () => {
    onNaughtyModeClick();
    openNaughtyModePopup({
      url: previewUrl,
      level,
      blurLevel,
      widgetType: VIDEO,
    });
  };

  const showPopup =
    withNaughtyModePopup && blurLevel !== LEVEL.NORMAL && !skipBlur;

  if (loading || galleryPhotoLoading) {
    return null;
  }

  return (
    <VideoPreviewLayout
      withAutoplay={withAutoplay}
      isAdultChangeSizeAvailable={isAdultChangeSizeAvailable}
      showNavigationOverlay={showNavigationOverlay}
      mediaCounter={mediaCounter}
      showOverlay={showOverlay}
      showButton={showPlayButton}
      buttonType={playButtonType}
      url={createVideoPreviewUrl(previewUrl, preset)}
      videoPreviewUrl={videoPreviewUrl}
      centered
      spacedPlayButton={spacedPlayButton}
      payButtonText={payButtonText}
      level={correctedLevel}
      blurLevel={blurLevel}
      naughtySize={naughtySize}
      scaling={scaling}
      onLoad={onLoad}
      trackingName={trackingName}
      onClick={showPopup ? openPopupHandler : onClick}
      withLockOverlay={withLockOverlay}
      text={naughtyModeText}
      mediaCounters={mediaCounters}
      withCleanProductRestrictions={withCleanProductRestrictions}
      customOverlay={customOverlay}
    />
  );
};

/**
 * Can't directly reassign static property from in chain from child component
 * since child component is a dynamic loadable one (@see useThemedMediaPreview.js)
 */
VideoPreview.PLAY_BUTTON_TYPE = {
  FLAT: ButtonType.FLAT,
  FLAT_COVER: ButtonType.FLAT_COVER,
};

VideoPreview.propTypes /* remove-proptypes */ = {
  video: PropTypes.shape({
    previewUrl: PropTypes.string.isRequired,
    videoPreviewUrl: PropTypes.string,
    level: PropTypes.oneOf(Object.values(LEVEL)),
  }).isRequired,
  my: PropTypes.bool.isRequired,
  naughtySize: PropTypes.oneOf(Object.values(NaughtyModeSize)),
  // this property with false prevents to open NaughtyModePopup, and will call onClick
  withNaughtyModePopup: PropTypes.bool,
  /**
   * In some places, the text should be indented before the button more than the default
   * @see TargetUserMediaWithActions
   */
  spacedPlayButton: PropTypes.bool,
  forceLevel: PropTypes.oneOf(Object.values(LEVEL)),
  trackingName: PropTypes.string,
  onClick: PropTypes.func,
  onLoad: PropTypes.func,
  // Scaling for preview (@see VideoPreviewLayout.js)
  showNavigationOverlay: PropTypes.bool,
  mediaCounter: PropTypes.number,
  mediaCounters: PropTypes.node,
  payButtonText: PropTypes.string,
  naughtyModeText: PropTypes.string,
  scaling: PropTypes.string,
  onNaughtyModeClick: PropTypes.func,
  withLockOverlay: PropTypes.bool,
  showPlayButton: PropTypes.bool,
  skipBlur: PropTypes.bool,
  isAdultChangeSizeAvailable: PropTypes.bool,
  playButtonType: PropTypes.string,
  showOverlay: PropTypes.bool,
  preset: PropTypes.string,
  customOverlay: PropTypes.node,
  withAutoplay: PropTypes.bool,
};

VideoPreview.defaultProps = {
  showNavigationOverlay: false,
  withNaughtyModePopup: true,
  spacedPlayButton: false,
  isAdultChangeSizeAvailable: false,
  forceLevel: LEVEL.NORMAL,
  showOverlay: true,
  preset: PRESET.NORMAL,
  onNaughtyModeClick: () => {},
  withAutoplay: true,
};

export default VideoPreview;
