import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import {translateMessageEntityMapPropTypes} from '@core/payment/widgets/footer/constants/propTypes';

import {Link, TextMuted, Text} from '@phoenix/typography';

import FooterRow from './FooterRow';

/**
 * Render company address and phone number
 */
const PaymentHotLine = ({
  inverse,
  phoneNumber,
  workingHoursFrom,
  workingHoursTo,
  translateMessageEntityMap,
  shortInfo,
  small,
}) => {
  if (shortInfo) {
    if (phoneNumber) {
      return (
        <FooterRow>
          <TextMuted inverse={inverse} data-test="helpLine" small={small}>
            {`${t('paymentPageSidebar', 'text.free_helpline')} `}
            <Text inline bold inverse={inverse} small={small}>
              {phoneNumber}
            </Text>
          </TextMuted>
        </FooterRow>
      );
    }

    return null;
  }

  const {hotlineCreditCard, openningWorkTime, workTimeHour} =
    translateMessageEntityMap;

  const workingTime =
    workingHoursFrom &&
    workTimeHour
      .replace('{from}', workingHoursFrom)
      .replace('{to}', workingHoursTo);

  if (!hotlineCreditCard && !phoneNumber && !workingTime) {
    return null;
  }

  return (
    <FooterRow>
      {hotlineCreditCard && `${hotlineCreditCard} `}
      {phoneNumber && (
        <Link
          data-test="phoneNumber"
          href={`tel:${phoneNumber}`}
          small
          inherit
          underline
        >
          {phoneNumber}
        </Link>
      )}
      {workingTime && (
        <TextMuted
          data-test="workingTime"
          small
          textDecoration={Text.DECORATION.UNDERLINE}
        >
          <div>{openningWorkTime}</div>
          {workingTime}
        </TextMuted>
      )}
    </FooterRow>
  );
};

PaymentHotLine.propTypes /* remove-proptypes */ = {
  inverse: PropTypes.bool,
  shortInfo: PropTypes.bool,
  phoneNumber: PropTypes.string,
  workingHoursFrom: PropTypes.string,
  workingHoursTo: PropTypes.string,
  translateMessageEntityMap: translateMessageEntityMapPropTypes,
  small: PropTypes.bool,
};

PaymentHotLine.defaultProps = {
  inverse: false,
  shortInfo: false,
};

export default PaymentHotLine;
