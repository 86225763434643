import omit from 'lodash/omit';

import fetchData from '@core/application/utils/fetchData';
import getProcessPaymentFlow from '@core/payment/payProcess/utils/getProcessPaymentFlow';
import PAYMENT_STATUSES from '@core/payment/paymentPending/constants/paymentStatuses';
import type {ViaEnum} from '@core/types';

import getProcessExternalPayFlow from '@phoenix/payment/payProcess/paymentFlow/flows/getProcessExternalPayFlow';

type ResponseData = {
  data: {
    failPageLogic?: string | null;
    orderId?: string | null;
    resultPageMessage: boolean;
    redirectUrl: string;
    status: PAYMENT_STATUSES;
    prevVia: ViaEnum;
    via: ViaEnum;
  };
};

const processAfterExternal3DSecurePay = async (
  action: string,
  is3dSecureSuccess: boolean,
): Promise<void> => {
  return fetchData({
    url: `/api/v1/pay/threeDSecureComplete?is3dSecureSuccess=${is3dSecureSuccess}&action=${action}`,
  })
    .then((response: Response): Promise<ResponseData> => response.json())
    .then(({data}: ResponseData): void => {
      if (data.resultPageMessage) {
        const {status} = data;

        getProcessPaymentFlow(getProcessExternalPayFlow())({
          ...omit(data, 'resultPageMessage'),
          action,
          status: status === PAYMENT_STATUSES.SUCCESS,
          withPendingPopup: status === PAYMENT_STATUSES.PENDING,
        });
      }
    });
};

export default processAfterExternal3DSecurePay;
