import type {PaymentAnswer} from '@core/payment/payProcess/types/paymentAnswerProps';

import {OPEN_PP_POPUP_WEEK_SPEAK} from '@phoenix/coins/buyCoins/constants/vias';
import getCoinsSpecialOfferStepsAfterPayment from '@phoenix/payment/payProcess/paymentFlow/methods/getCoinsSpecialOfferStepsAfterPayment';
import getCoinsBuyOfferStepsAfterPayment from '@phoenix/payment/payProcess/paymentFlow/methods/getCoinsBuyOfferStepsAfterPayment';

const nextCoinsBuyOfferStepAfterPayment = (
  paymentAnswer: PaymentAnswer,
): PaymentAnswer => {
  /**
   * TODO[Removed_BB]: Think about using 'via' from 'paymentAnswer' instead of 'prevVia'.
   * Maybe here FE-39373
   */
  if (paymentAnswer.prevVia === OPEN_PP_POPUP_WEEK_SPEAK) {
    getCoinsSpecialOfferStepsAfterPayment(paymentAnswer);
  } else {
    getCoinsBuyOfferStepsAfterPayment(paymentAnswer);
  }

  return paymentAnswer;
};

export default nextCoinsBuyOfferStepAfterPayment;
