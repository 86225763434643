import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
// Can't use @phoenix/ui because it doesn't render children while loading.
// eslint-disable-next-line no-restricted-imports
import {Spacing} from '@core/ui';

import {SpacingDirection} from '@phoenix/ui/constants';

import css from '../styles/HeaderTopWrapper.css';

/**
 * Workaround for WEB and MOB environment in terms of design.
 * On WEB environment content is separated from header and displayed inside 'cards'.
 * On MOB - cards are boundless and attached to header.
 * Anyway is a spike. We should unify 2 platforms together.
 */
const HeaderTopWrapper = ({
  children,
  shiftedTop,
  withoutTop,
  withoutBottom,
  unneeded,
  stretch,
  spacingId,
  ...props
}) => {
  const isPhone = usePhoneBreakpoint();

  return (
    <Spacing
      id={spacingId}
      withoutTop={isPhone || withoutTop || unneeded}
      withoutBottom={isPhone || withoutBottom || unneeded}
      direction={SpacingDirection.VERTICAL}
      stretch={stretch}
      className={cn(
        css.wrapper,
        !unneeded && css.zIndex,
        shiftedTop && css.shiftedTop,
      )}
      {...props}
    >
      {children}
    </Spacing>
  );
};

HeaderTopWrapper.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  shiftedTop: PropTypes.bool,
  withoutBottom: PropTypes.bool,
  withoutTop: PropTypes.bool,
  stretch: PropTypes.bool,
  spacingId: PropTypes.string,
  /**
   * This wrapper is used even when unneeded.
   * It allows to keep consistent components tree and avoid children re-mount.
   * In such case this prop will be `true`.
   */
  unneeded: PropTypes.bool,
};

HeaderTopWrapper.defaultProps = {
  withoutBottom: false,
  withoutTop: true,
  stretch: false,
};

export default HeaderTopWrapper;
