import baseProcessThreeDSecureAnswer from '@core/payment/payProcess/paymentFlow/methods/baseProcessThreeDSecureAnswer';
import type {
  PaymentAnswer,
  PaymentAnswerAbortable,
} from '@core/payment/payProcess/types/paymentAnswerProps';

import PaymentPendingPopupLayout from '../../../paymentPending/components/PaymentPendingPopupLayout';
import ThreeDSecureAbortPopup from '../../../widgets/threeDSecure/components/ThreeDSecureAbortPopup';

const processThreeDSecureAnswer = (
  paymentAnswer: PaymentAnswer,
): Promise<PaymentAnswerAbortable> => {
  return baseProcessThreeDSecureAnswer({
    PaymentPendingPopupLayout,
    ThreeDSecureAbortPopup,
  })(paymentAnswer);
};

export default processThreeDSecureAnswer;
