import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import PopupSourceEvent from '@core/popup/constants/PopupSourceEvent';
import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';
import {getClientInstance} from '@core/graphql/client';

import CoinsSuccessPopup from '@phoenix/coins/buyCoins/containers/CoinsSuccessPopup';

import WeekSpeakOfferSuccessPopup from '../specialOffer/components/WeekSpeakOfferSuccessPopup';
import {WEEK_SPEAK} from '../specialOffer/constants/specialOfferPopups';

/**
 * Open success popup after buying credit
 * @param {string} type
 * @param {array} packages
 * @param {function} onClose
 */
const openCoinsSuccessPopup = ({packages, type, onClose}) => {
  const isWeekSpeak = type === WEEK_SPEAK;
  PopupService.openPopup(
    isWeekSpeak ? (
      <WeekSpeakOfferSuccessPopup onButtonClick={onClose} />
    ) : (
      <CoinsSuccessPopup packages={packages} onButtonClick={onClose} />
    ),
    {
      small: true,
      autoLoadTracking: true,
      trackingName: isWeekSpeak ? 'weekSpeakOfferSuccess' : 'coinsSuccess',
      onClose: (sourceEventType) => {
        if (
          sourceEventType === PopupSourceEvent.CLOSE_BUTTON_CLICK &&
          onClose
        ) {
          onClose();
        }
      },
    },
  );

  // Invalidate coins payment banner after success payment when user don`t changing it`s payment status
  invalidateCacheByTypename(getClientInstance(), 'Payment', 'discountData');
};

export default openCoinsSuccessPopup;
