import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import {Icon} from '@phoenix/ui';

import {ICONS} from '../constants/icons';
import {MICROFEATURES} from '../../common/constants/microfeatures';
import MicrofeaturesIconLayout from './MicrofeaturesIconLayout';
import css from '../styles/MicrofeaturesIconMoreLikes.css';

const MicrofeaturesIconMoreLikes = ({withAnimation, active, ...props}) => (
  <MicrofeaturesIconLayout
    icon={
      <Icon
        className={cn(withAnimation && css.animation)}
        type={ICONS[MICROFEATURES.MORE_LIKES]}
        inherit={!active}
      />
    }
    type={MicrofeaturesIconLayout.TYPE.DANGER}
    active={active}
    {...props}
  />
);

MicrofeaturesIconMoreLikes.SIZE = MicrofeaturesIconLayout.SIZE;

MicrofeaturesIconMoreLikes.propTypes /* remove-proptypes */ = {
  withAnimation: PropTypes.bool,
  active: PropTypes.bool,
};

MicrofeaturesIconMoreLikes.defaultProps = {
  withAnimation: true,
  active: false,
};

export default MicrofeaturesIconMoreLikes;
