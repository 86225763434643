import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
} from 'react';
import upperFirst from 'lodash/upperFirst';
import cn from 'classnames';
import PropTypes from 'prop-types';

import useSmallTabletBreakpoint from '@core/responsive/useSmallTabletBreakpoint';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import useIsPopupOpenAndAnimatedVar from '@core/popup/utils/useIsPopupOpenAndAnimatedVar';
import getUserAgentParser from '@core/utils/getUserAgentParser';
import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';
import PopupOverflowService from '@core/popup/utils/PopupOverflowService';
import {POPUP_ACTIONS_ID} from '@core/popup/constants/popup';
import PopupContext from '@core/popup/containers/PopupContext';
import getBoundingClientRect from '@core/application/utils/getBoundingClientRect';

import {Align} from '@phoenix/ui/constants';

import css from '../styles/PopupFrame.css';

const isAppleOS = ['iOS', 'Mac OS'].includes(getUserAgentParser().getOS().name);

/**
 * Wrapper component for unified spacing solution around content inside popups.
 *
 * Is used only in 2 places for avoiding circular dependency between UI package and other codebase
 * @see PopupFrame.js
 * @see MultipleSelect.js
 */
const PopupFrameBase = ({
  content,
  actions,
  actionsWrapper: ActionsWrapper,
  actionClassName,
  header,
  titleAlign,
  title,
  footer,
  spacedContent,
  spacedAbove,
  unspacedBelow,
  fixedActions,
  actionWithTopMargin,
  className,
  trackingName,
  forceFixedActions,
  adaptive,
  ...props
}) => {
  const isPopupOpenAndAnimated = useIsPopupOpenAndAnimatedVar();
  const {options} = useContext(PopupContext);

  /**
   * Use callback-ref since standard ref doesn't call re-render
   * when ref is setted on DOM node.
   */
  const [contentHTML, setContentHTML] = useState(null);
  const contentRef = useCallback((node) => {
    if (node === null) return;
    setContentHTML(node);
  }, []);

  const isPhone = usePhoneBreakpoint();
  const isSmallTablet = useSmallTabletBreakpoint();

  const actionsRef = useRef();

  const [isPopupOverflowing, setPopupOverflowing] = useState(false);
  const withFixedActions = fixedActions && !forceFixedActions;
  const applyFixedActions = isPopupOverflowing || forceFixedActions;
  const withActionsAnimationFallback =
    !isPopupOpenAndAnimated && applyFixedActions && (isPhone || isAppleOS);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (withFixedActions && contentHTML) {
      PopupOverflowService.setPopupContentMutationObservable(contentHTML);
      const subscription =
        PopupOverflowService.isOverflowingObservable.subscribe(
          setPopupOverflowing,
        );
      return () => subscription.unsubscribe();
    }
    /**
     * options.key - key of active popup, when popup is hide or show key is changed.
     * Each time when key is changed need update isPopupOverflowing state.
     */
  }, [withFixedActions, contentHTML, options.key]);

  const actionsWrapperClassName = cn(
    css.actions,
    actionClassName,
    applyFixedActions && css.fixedActions,
    actionWithTopMargin && css.actionWithTopMargin,
    withActionsAnimationFallback && css.fixedActionsAnimationFallback,
  );

  const wrapper = (
    <div
      className={cn(
        className,
        css.wrapper,
        adaptive && isSmallTablet && css.phone,
      )}
      ref={contentRef}
      {...props}
    >
      {header && <div className={css.header}>{header}</div>}
      {title && (
        <div className={cn(css.title, css[`title${upperFirst(titleAlign)}`])}>
          {title}
        </div>
      )}
      <div
        className={cn(
          spacedContent && css.content,
          title && css.withTitle,
          header && css.withHeader,
          spacedAbove && css.spacedAbove,
          unspacedBelow && css.unspacedBelow,
          !actions && spacedContent && css.withoutActions,
        )}
      >
        {content}
      </div>
      {actions && (
        <>
          {withActionsAnimationFallback && (
            /**
             * Position sticky doesn't working with css transform!
             * So, render fallback for avoid visual jump actions
             */
            <div
              className={cn(actionsWrapperClassName, css.animationFallback)}
              style={
                actionsRef.current
                  ? {
                      height: `${getBoundingClientRect(actionsRef.current).height}px`,
                    }
                  : null
              }
            />
          )}
          <ActionsWrapper
            id={POPUP_ACTIONS_ID}
            shadowLevel={applyFixedActions ? 3 : 0}
            boundless
            className={actionsWrapperClassName}
            innerRef={actionsRef}
          >
            {actions}
          </ActionsWrapper>
        </>
      )}
      {footer && (
        <div className={cn(isPopupOverflowing && fixedActions && css.footer)}>
          {footer}
        </div>
      )}
    </div>
  );

  return trackingName ? (
    <AddBabciaUBTracking trackingName={trackingName}>
      {wrapper}
    </AddBabciaUBTracking>
  ) : (
    wrapper
  );
};

PopupFrameBase.propTypes /* remove-proptypes */ = {
  fixedActions: PropTypes.bool,
  content: PropTypes.node.isRequired,
  /**
   * It's like sticked header on top of popup
   */
  header: PropTypes.node,
  /**
   * Just a centered title that don't overlap close button
   */
  title: PropTypes.node,
  actions: PropTypes.node,
  actionsWrapper: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.func,
  ]).isRequired,
  footer: PropTypes.node,
  spacedContent: PropTypes.bool,
  actionClassName: PropTypes.string,
  className: PropTypes.string,
  trackingName: PropTypes.string,
  actionWithTopMargin: PropTypes.bool,
  /**
   * Some popups should have increased spacing on top. See brandbook
   */
  spacedAbove: PropTypes.bool,
  unspacedBelow: PropTypes.bool,
  titleAlign: PropTypes.oneOf(Object.values(Align)),
  forceFixedActions: PropTypes.bool,
  adaptive: PropTypes.bool,
};

PopupFrameBase.defaultProps = {
  actionWithTopMargin: true,
  spacedContent: true,
  fixedActions: true,
  titleAlign: Align.CENTER,
  forceFixedActions: false,
  adaptive: true,
};

export default PopupFrameBase;
