import React from 'react';
import PropTypes from 'prop-types';

import t, {createTranslationsMap} from '@core/translations';
import PopupService from '@core/popup/utils/PopupService';
import {PHOTO, VIDEO} from '@core/mediaUpload/constants/uploadTypes';

import {ButtonPrimary} from '@phoenix/ui';
import PopupNotice from '@phoenix/popup/components/PopupNotice';

const TEXT_TITLE = createTranslationsMap((translate) => ({
  [PHOTO]: translate('popupPhotoUploadDisabled', 'text.upload_disabled'),
  [VIDEO]: translate('popupPhotoUploadDisabled', 'text.video_upload_disabled'),
}));

const handleClick = () => PopupService.closePopup(true);

const MediaUploadDisabledPopup = ({mediaType}) => {
  return (
    <PopupNotice
      icon="denied"
      title={TEXT_TITLE[mediaType]}
      actions={[
        <ButtonPrimary
          fullWidth
          onClick={handleClick}
          trackingName="closePopup"
        >
          {t('popupPhotoUploadDisabled', 'button.ok')}
        </ButtonPrimary>,
      ]}
    />
  );
};

MediaUploadDisabledPopup.propTypes /* remove-proptypes */ = {
  mediaType: PropTypes.string,
};

MediaUploadDisabledPopup.defaultProps = {
  mediaType: PHOTO,
};

export default MediaUploadDisabledPopup;
