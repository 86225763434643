export const READY_PAYMENT_PAGE_OPENED_IN_NEW_TAB_EVENT =
  'readyPaymentPageOpenedInNewTab';

const dispatchReadyPaymentPageEvent = () => {
  window.dispatchEvent(
    new CustomEvent(READY_PAYMENT_PAGE_OPENED_IN_NEW_TAB_EVENT),
  );
};

export default dispatchReadyPaymentPageEvent;
