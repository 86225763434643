import {useEffect, useRef} from 'react';
import once from 'lodash/once';

import {getClientInstance} from '@core/graphql/client';
import usePaymentParams from '@core/payment/common/utils/usePaymentParams';
import useActivePackage from '@core/payment/widgets/package/utils/useActivePackage';
import usePaymentProcessingStatus from '@core/payment/common/utils/usePaymentProcessingStatus';
import PAYMENT_SOURCES from '@core/payment/common/constants/paymentSources';
import PROCESSING_STATUS from '@core/payment/common/constants/processingStatus';

import TRACK_PAYMENT_VISIT_MUTATION from '../graphql/mutations/trackPaymentVisit.gql';
import dispatchReadyPaymentPageEvent from '../../utils/dispatchReadyPaymentPageEvent';

const setIsReadyPaymentPage = once(dispatchReadyPaymentPageEvent);

export const trackPaymentVisit = (
  action,
  via,
  prevVia,
  viaProfileId,
  source,
) => {
  getClientInstance()
    .mutate({
      mutation: TRACK_PAYMENT_VISIT_MUTATION,
      variables: {
        action,
        via,
        prevVia,
        viaProfileId,
        source,
      },
    })
    .then(() => {
      /**
       * If the current page is opened in a new tab, notify initial tab when the page is ready.
       * Need for correctly update query that is responsible for opening the payment page in a new tab.
       */
      if (source === PAYMENT_SOURCES.PAYMENT_PAGE && window.opener) {
        setIsReadyPaymentPage();
      }
    });
};

const useTrackPaymentVisit = () => {
  const {action, via, prevVia, viaProfileId, source} = usePaymentParams();
  const {activePackage} = useActivePackage();
  const hasActivePackage = Boolean(activePackage);
  const processingStatus = usePaymentProcessingStatus();
  const trackPaymentVisitRef = useRef(once(trackPaymentVisit));

  useEffect(() => {
    if (processingStatus !== PROCESSING_STATUS.READY || !hasActivePackage) {
      return;
    }

    trackPaymentVisitRef.current(action, via, prevVia, viaProfileId, source);
  }, [
    action,
    via,
    prevVia,
    viaProfileId,
    processingStatus,
    hasActivePackage,
    source,
  ]);
};

export default useTrackPaymentVisit;
