import omit from 'lodash/omit';

import type {
  PaymentAnswer,
  PaymentAnswerAbortable,
} from '@core/payment/payProcess/types/paymentAnswerProps';
import ThreeDSecureProcessor from '@core/payment/payProcess/utils/ThreeDSecureProcessor';

import PaymentPendingPopupLayout from '../../../paymentPending/components/PaymentPendingPopupLayout';
import ThreeDSecureAbortPopup from '../../../widgets/threeDSecure/components/ThreeDSecureAbortPopup';

const openPendingPopupIfNeeded = async (
  paymentAnswer: PaymentAnswer,
): Promise<PaymentAnswerAbortable> => {
  const {orderId, via, withPendingPopup} = paymentAnswer;

  if (withPendingPopup) {
    const processor = await new ThreeDSecureProcessor(
      via,
      orderId,
      PaymentPendingPopupLayout,
      ThreeDSecureAbortPopup,
    ).openPendingPopup();

    return processor.getPromise();
  }

  return Promise.resolve(omit(paymentAnswer, 'withPendingPopup'));
};

export default openPendingPopupIfNeeded;
