import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {capture} from 'modernizr';

import t from '@core/translations/translate';
import * as ACCEPT from '@core/mediaUpload/constants/accept';
import useSmallTabletBreakpoint from '@core/responsive/useSmallTabletBreakpoint';
import useEventCallback from '@core/utils/react/useEventCallback';
import MediaUploadActionsAppearance from '@core/theming/constants/features/MediaUploadActionsAppearance';

import {Button, Separator, Row} from '@phoenix/ui';
import {ButtonType} from '@phoenix/ui/constants';
import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';
import {TextMuted} from '@phoenix/typography';

import UploadActions from './UploadActions';
import FileButton from './FileButton';
import css from '../styles/PhotoUploadActions.css';

const PhotoUploadActions = ({
  showWebcamButton,
  onFileSelect,
  onCaptureClick,
  multiple,
  accept,
  browserChangeAction,
  uploadPhotoButtonType,
  unifiedUpload,
}) => {
  const {data: actions} = useThemeFeature('mediaUpload', 'actions');
  const isSmallTablet = useSmallTabletBreakpoint();
  const withIcons = actions !== MediaUploadActionsAppearance.WITHOUT_ICONS;

  const showCaptureButton = capture && isSmallTablet;
  // Don't show webcam for phone, there is <input capture="camera" /> instead.
  const showWebcam = showWebcamButton && !isSmallTablet;
  const photoIcon = !showCaptureButton && !showWebcam && !unifiedUpload;

  const handleSelectFile = useEventCallback((...props) => {
    onFileSelect(...props);
  });

  const acceptParameters = {
    ...(actions !== MediaUploadActionsAppearance.UNIFIED_FOR_JOINED_UPLOAD &&
      !unifiedUpload && {
        capture: 'camera',
        accept: ACCEPT.IMAGES,
      }),
  };

  return (
    <Fragment>
      <UploadActions>
        {showWebcam && (
          <Button
            icon={withIcons && 'photo'}
            onClick={onCaptureClick}
            data-test="webcamCapture"
            trackingName="webcamCapture"
          >
            {t('mediaUpload', 'text.capture_photo')}
          </Button>
        )}
        <FileButton
          type={
            browserChangeAction ? ButtonType.DEFAULT : uploadPhotoButtonType
          }
          onFileSelect={handleSelectFile}
          multiple={multiple}
          icon={withIcons && (photoIcon ? 'photo' : 'gallery')}
          accept={accept}
          trackingName={unifiedUpload ? 'uploadMedia' : 'uploadPhoto'}
          data-test="uploadPhotoButton"
        >
          {unifiedUpload
            ? t('mediaUpload', 'text.upload_media')
            : t('mediaUpload', 'text.upload_photo')}
        </FileButton>
        {showCaptureButton && (
          <FileButton
            onFileSelect={handleSelectFile}
            multiple={multiple}
            icon={withIcons && 'photo'}
            trackingName={unifiedUpload ? 'captureMedia' : 'capturePhoto'}
            {...acceptParameters}
          >
            {unifiedUpload
              ? t('mediaUpload', 'text.capture_media')
              : t('mediaUpload', 'text.capture_photo')}
          </FileButton>
        )}
        {isSmallTablet && browserChangeAction && (
          /* Need wrapper for apply actions styles. That`s why we use empty div */
          <div>
            <Separator className={css.motivation}>
              <TextMuted small align={TextMuted.ALIGN.CENTER}>
                {t('uploadButtonMessages', 'text.trouble_with_upload')}
              </TextMuted>
            </Separator>
          </div>
        )}
        {isSmallTablet && browserChangeAction}
      </UploadActions>
      {!isSmallTablet && browserChangeAction && (
        <Row>
          <UploadActions vertical fullWidth={false}>
            <Separator>
              {t('uploadButtonMessages', 'text.trouble_with_upload')}
            </Separator>
            {browserChangeAction}
          </UploadActions>
        </Row>
      )}
    </Fragment>
  );
};

PhotoUploadActions.propTypes /* remove-proptypes */ = {
  onFileSelect: PropTypes.func.isRequired,
  onCaptureClick: PropTypes.func,
  multiple: PropTypes.bool,
  accept: PropTypes.oneOf(Object.values(ACCEPT)),
  /**
   * In case of a problematic browser the action motivates to change it.
   * May be snapchat browser change button or Ios install app button.
   */
  browserChangeAction: PropTypes.node,
  uploadPhotoButtonType: PropTypes.oneOf(Object.values(ButtonType)),
  // unified single action for joined photo and video upload
  unifiedUpload: PropTypes.bool,
  showWebcamButton: PropTypes.bool,
};

PhotoUploadActions.defaultProps = {
  accept: ACCEPT.IMAGES,
  showWebcamButton: true,
  uploadPhotoButtonType: ButtonType.PRIMARY,
};

export default PhotoUploadActions;
