/**
 * Dictionary names of inventory tracking events.
 * @see inventoryTracking.gql (events)
 */
const VIEW = 'view';
const CLICK = 'click';

const EVENTS = {VIEW, CLICK};

export default EVENTS;
