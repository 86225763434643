import React, {memo} from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {Text, H3} from '@phoenix/typography';
import {Group} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';

const BoughtPackages = memo(
  ({packages, align, heading: Heading, space, title}) => {
    return (
      <Text align={align}>
        <Group space={space}>
          <Heading>
            {title || `${t('paymentPage', 'text.you_activated')}:`}
          </Heading>
          {packages.map((packageTitle, index) => (
            <Text
              // eslint-disable-next-line react/no-array-index-key -- packages with same title can repeat
              key={packageTitle + index}
              type={Text.TYPE.MUTED}
              data-test="boughtPackage"
            >
              {packageTitle}
            </Text>
          ))}
        </Group>
      </Text>
    );
  },
);

BoughtPackages.ALIGN = Text.ALIGN;

BoughtPackages.propTypes /* remove-proptypes */ = {
  packages: PropTypes.arrayOf(PropTypes.string),
  heading: PropTypes.func,
  title: PropTypes.string,
  align: PropTypes.oneOf(Object.values(BoughtPackages.ALIGN)),
  space: PropTypes.oneOf(Object.values(SpacingSize)),
};

BoughtPackages.defaultProps = {
  packages: [],
  align: BoughtPackages.ALIGN.CENTER,
  heading: H3,
  space: SpacingSize.SHORT,
};

export default BoughtPackages;
