import React, {Fragment, useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {csspositionsticky} from 'modernizr';
import cn from 'classnames';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import useIsSearchFormOpen from '@core/search/utils/useIsSearchFormOpen';
import HeaderPositionAppearance from '@core/theming/constants/features/HeaderPositionAppearance';
import HeaderRoundedAppearance from '@core/theming/constants/features/HeaderRoundedAppearance';
import getUserAgentParser from '@core/utils/getUserAgentParser';
import useElementPositionAboveKeyboard from '@core/utils/iosKeyboardUtils/useElementPositionAboveKeyboard';

import {Card} from '@phoenix/ui';
import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';

import LocationDependentHeaderTopWrapper from './LocationDependentHeaderTopWrapper';
import useHeaderProps from '../utils/useHeaderProps';
import {DEFAULT_PROPS} from '../graphql/vars/headerPropsVar';
import css from '../styles/StickedPositioner.css';
import POSITION_TYPES from '../constants/headerPositionTypes';

const IS_ANDROID = getUserAgentParser().getOS().name === 'Android';
const IS_FIREFOX_BROWSER = getUserAgentParser().getBrowser().name === 'Firefox';

/**
 * Firefox on Android having issues with 'position: sticky;'.
 * Specifically, elements may lose clickability after scrolling.
 * Therefore, it is better to use 'position: fixed;'
 */
const isPositionFixed = IS_ANDROID && IS_FIREFOX_BROWSER;

/**
 * Wrapping component that positions and makes content
 * sticky based on position passed to it.
 * Using only for unsupported browsers.
 * Don`t use in new logic because it will be deleted in near future.
 * @deprecated
 */
const StickedPositioner = ({
  children,
  position,
  rounded,
  floating,
  spacingId,
  ...props
}) => {
  /**
   * Since such wrapper can render many other widgets, and they can be visible, or invisible on
   * certain pages, we just subscribe to DOM mutation and add or remove spacings for case, when we
   * use "floating" appearance;
   */
  const [empty, setEmpty] = useState(spacingId && floating);
  const {withShadow, transparent, positionType} = useHeaderProps();
  const isSearchFormOpen = useIsSearchFormOpen();
  const isPhone = usePhoneBreakpoint();
  const stickedBottomWrapperRef = useRef();

  // Position prop changes only TOP header position type
  const positionSticky =
    (position === HeaderPositionAppearance.TOP
      ? positionType
      : DEFAULT_PROPS.positionType) === POSITION_TYPES.STICKY;

  useElementPositionAboveKeyboard({
    ref: stickedBottomWrapperRef,
    withCheckCaretOverlap: false,
  });

  useEffect(() => {
    let observer;

    if (spacingId && floating) {
      // Set in initial mount
      setEmpty(!document.getElementById(spacingId).clientHeight);

      // Subscribe for future DOM updates
      observer = new MutationObserver(() => {
        setEmpty(!document.getElementById(spacingId).clientHeight);
      });
      observer.observe(document.getElementById(spacingId), {
        childList: true,
        subtree: true,
      });
    }
    return () => {
      observer && observer.disconnect();
    };
  }, [spacingId, floating]);

  const content = (
    <div
      id={spacingId}
      className={cn(
        rounded && css[rounded],
        floating && css.floating,
        empty && css.empty,
      )}
    >
      {transparent ? (
        children
      ) : (
        <Card shadowLevel={empty || !withShadow ? 0 : 2} boundless>
          {children}
        </Card>
      )}
    </div>
  );

  const {data: headerPosition} = useThemeFeature('header', 'position');

  const {data: stickedSubheader} = useThemeFeature(
    'header',
    'stickedSubheader',
  );

  /**
   * Since 'sticky' position doesn't tolerate wrapping div's
   * we wrap into "spacer" wrapper inside sticked element and
   * re-assign shadow little down.
   */
  if (position === HeaderPositionAppearance.TOP) {
    /**
     * Polyfill to support older versions of Chrome < 55 version
     * in case when we swipe down past the end of the page
     */
    const needStickyPolyfill =
      (isSearchFormOpen || !csspositionsticky) &&
      stickedSubheader &&
      positionSticky;

    return (
      <Fragment>
        {needStickyPolyfill && (
          <div
            className={
              headerPosition === HeaderPositionAppearance.BOTTOM && isPhone
                ? css.toolbarPolyfill
                : css.headerPolyfill
            }
          />
        )}
        <div
          className={cn(
            css.wrapper,
            (positionSticky || needStickyPolyfill) && css.top,
            css[needStickyPolyfill ? POSITION_TYPES.FIXED : positionType],
          )}
          {...props}
        >
          <LocationDependentHeaderTopWrapper>
            {content}
          </LocationDependentHeaderTopWrapper>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {/* Polyfill to support older versions of Chrome < 55 version */}
      {(!csspositionsticky || isPositionFixed) && (
        <div className={css.headerPolyfill} />
      )}
      <div
        className={cn(
          css.wrapper,
          css[position],
          positionSticky && css.sticky,
          (!csspositionsticky || isPositionFixed) && css.fixed,
        )}
        {...props}
        ref={stickedBottomWrapperRef}
      >
        {content}
      </div>
    </Fragment>
  );
};

StickedPositioner.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf(Object.values(HeaderPositionAppearance)).isRequired,
  rounded: PropTypes.oneOf(Object.values(HeaderRoundedAppearance)),
  floating: PropTypes.bool,
  spacingId: PropTypes.string,
};

StickedPositioner.defaultProps = {
  rounded: HeaderRoundedAppearance.NONE,
};

export default StickedPositioner;
