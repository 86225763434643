import reportStartPayment from '@core/payment/payProcess/paymentFlow/methods/reportStartPayment';
import reportEndPayment from '@core/payment/payProcess/paymentFlow/methods/reportEndPayment';
import processPaymentAnswer from '@core/payment/payProcess/paymentFlow/methods/processPaymentAnswer';
import trackPaymentDescriptor from '@core/payment/payProcess/paymentFlow/methods/trackPaymentDescriptor';
import goToNextStep from '@core/payment/payProcess/paymentFlow/methods/goToNextStep';
import addSuccessOrder from '@core/payment/payProcess/paymentFlow/methods/addSuccessOrder';
import updateMicroFeaturesAfterPayment from '@core/payment/payProcess/paymentFlow/methods/updateMicroFeaturesAfterPayment';
import updateCSRFToken from '@core/payment/payProcess/paymentFlow/methods/updateCSRFToken';
import addBrowserDetails from '@core/payment/payProcess/paymentFlow/methods/addBrowserDetails';
import payByMethodFromRemarketingPopup from '@core/payment/payProcess/utils/payByMethodFromRemarketingPopup';
import externalThreeDSRedirect from '@core/payment/payProcess/paymentFlow/methods/externalThreeDSRedirect';
import goToNextStepOnDecline from '@core/payment/payProcess/paymentFlow/methods/goToNextStepOnDecline';

import showRedirectUrlInConsoleIfNeed from '../methods/showRedirectUrlInConsoleIfNeed';
import updateRemarketingOfferAfterPayment from '../methods/updateRemarketingOfferAfterPayment';
import remarketingPopupCancelSubscription from '../methods/remarketingPopupCancelSubscription';
import invalidateSearchLivecamAfterSuccess from '../methods/invalidateSearchLivecamAfterSuccess';
import processThreeDSecureAnswer from '../methods/processThreeDSecureAnswer';

const getRemarketingPopupFlow = () => {
  return [
    reportStartPayment,
    addBrowserDetails,
    payByMethodFromRemarketingPopup,
    updateCSRFToken,
    reportEndPayment,
    showRedirectUrlInConsoleIfNeed,
    externalThreeDSRedirect,
    processThreeDSecureAnswer,
    processPaymentAnswer,
    trackPaymentDescriptor,
    remarketingPopupCancelSubscription,
    invalidateSearchLivecamAfterSuccess,
    updateMicroFeaturesAfterPayment,
    updateRemarketingOfferAfterPayment,
    addSuccessOrder,
    goToNextStepOnDecline,
    goToNextStep,
  ];
};

export default getRemarketingPopupFlow;
