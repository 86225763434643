import React from 'react';
import PropTypes from 'prop-types';
import {useReactiveVar} from '@apollo/client';

import BANNER_WRAPPER from '../constants/bannerWrapper';
import BannerLoader from '../components/BannerLoader';
import RemarketingBannerContent from './RemarketingBannerContent';
import RemarketingBannerService from '../RemarketingBannerService';
import useRemarketingBannerData from '../utils/useRemarketingBannerData';
import getBannerSizeVar, {getBannerKey} from '../utils/getBannerSizeVar';

const parseZoneInfoData = ({height, width, ifrSrc, ...zone}) => ({
  ...zone,
  height,
  width,
  /**
   * Need to modify src of iframe. Without this operation
   * no postmessage will be generated and no banner will be shown (but rendered)
   */
  ifrSrc: `${ifrSrc}&context=#${window.location.origin}|${zone.divId}`,
});

/**
 * Main entry component if you want to render remarketing banner.
 * @param {Object} props
 * @param {string} props.placementId - Banner placement id
 * @param {boolean} [props.inline]
 * @param {boolean} [props.fullsize]
 */
const RemarketingBanner = ({placementId, inline, fullsize, ...props}) => {
  const {remarketing, loading} = useRemarketingBannerData(placementId);

  const size = useReactiveVar(getBannerSizeVar(getBannerKey(placementId)));
  const hasData = !loading && remarketing.banner;
  let bannerProps = {};

  if (hasData) {
    const {banner, bannerSystemUrl, validatePostMessageData} = remarketing;

    RemarketingBannerService.setMessageParams(
      bannerSystemUrl,
      validatePostMessageData,
    );

    bannerProps = {
      data: parseZoneInfoData(banner),
    };
  }

  return (
    <BannerLoader
      height={size?.height}
      loading={loading}
      inline={inline}
      fullsize={fullsize}
      {...props}
    >
      {hasData ? (
        <RemarketingBannerContent
          inline={inline}
          fullsize={fullsize}
          placementId={placementId}
          {...bannerProps}
          {...props}
        />
      ) : null}
    </BannerLoader>
  );
};

RemarketingBanner.WRAPPER = BANNER_WRAPPER;

RemarketingBanner.defaultProps = {
  wrapper: BANNER_WRAPPER.DEFAULT,
};

RemarketingBanner.propTypes /* remove-proptypes */ = {
  /**
   * placementId could by specified in code or come from server.
   * @see ../graphql/queries/bannerMixSettings.gql for server queries.
   */
  placementId: PropTypes.string.isRequired,
  wrapper: PropTypes.oneOf(Object.values(BANNER_WRAPPER)),
  inline: PropTypes.bool,
  fullsize: PropTypes.bool,
};

export default RemarketingBanner;
