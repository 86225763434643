import React, {useCallback, useEffect} from 'react';
import {useQuery} from '@apollo/client';
import PropTypes from 'prop-types';

import logger from '@core/logger';
import useSuccessMessageDisplayer from '@core/confirmation/utils/useSuccessMessageDisplayer';
import useResendConfirmEmail from '@core/confirmation/utils/useResendConfirmEmail';
import CONFIRMATION_QUERY from '@core/confirmation/graphql/queries/confirmation.gql';
import MAIL_ACTION from '@core/confirmation/constants/mailAction';
import ConfirmationUserMotivation from '@core/confirmation/containers/ConfirmationUserMotivation';
import ConfirmationActionsAppearance from '@core/theming/constants/features/ConfirmationActionsAppearance';

import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';
import useDeferredPageAvailable from '@phoenix/application/deferredPage/useDeferredPageAvailable';
import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';

import ConfirmationLayout from '../components/ConfirmationLayout';
import ConfirmationLayoutPlaceholder from '../components/ConfirmationLayoutPlaceholder';
import ConfirmationUserMotivationPlaceholder from '../components/ConfirmationUserMotivationPlaceholder';
import ConfirmationUserMotivationLayout from '../components/ConfirmationUserMotivationLayout';

/**
 * Confirmation widget. Used as popup after registration
 * of in case of 'open funnel' logic.
 *
 * @see openConfirmationPopup.js
 */
const Confirmation = ({
  userId,
  contentAlign,
  withMotivation,
  customTitle,
  motivationLayoutComponent: MotivationLayout,
  motivationLayoutPlaceholderComponent: MotivationPlaceholder,
  contentLayoutComponent: ContentLayout,
  contentPlaceholderComponent: ContentPlaceholder,
  footerLayoutComponent: FooterLayout,
  footerPlaceholderComponent: FooterPlaceholder,
}) => {
  const {
    data,
    loading: queryLoading,
    error,
    client,
  } = useQuery(CONFIRMATION_QUERY);
  /**
   * For now, such flag only controls existance or not of 'icons' on actions.
   * In future, if design of actions should change - rewrite it to themed component
   * inside layout
   *
   * @see ConfirmationLayout.js
   */
  const {data: actionsAppearance, loading: actionsLoading} = useThemeFeature(
    'confirmation',
    'actions',
  );
  const {setDeferredPageAvailable} = useDeferredPageAvailable();

  const loading = queryLoading || actionsLoading;

  const {email, service} = data?.userFeatures?.emailData || {};

  const showMotivation = withMotivation && Boolean(userId);
  const withMotivationContent = Boolean(
    showMotivation && MotivationLayout && MotivationPlaceholder,
  );
  const withFooter = Boolean(FooterLayout);

  /**
   * Tracking when all actions complete loading
   */
  useEffect(() => {
    if (!loading) {
      /**
       * @see DeferredPage.js
       */
      setDeferredPageAvailable(true);
    }
  }, [loading, setDeferredPageAvailable]);

  /**
   * To understand for sure that the email did not come from the backend and the problem is there
   */
  useEffect(() => {
    if (!loading && !email) {
      logger.sendError("[Confirmation]: email can't be empty");
    }
  }, [loading, email]);

  const {resendingEmail, resendEmail} = useResendConfirmEmail();
  const {showSuccessMessage, allowToShowSuccessMessage} =
    useSuccessMessageDisplayer();

  const handleResendClick = useCallback(async () => {
    await resendEmail({
      email,
      action: MAIL_ACTION.RESEND_EMAIL,
      client,
    });

    await allowToShowSuccessMessage();
  }, [resendEmail, email, client, allowToShowSuccessMessage]);

  if (error) return <ErrorBoundary />;

  if (loading) {
    return (
      <ConfirmationLayoutPlaceholder
        content={
          withMotivationContent ? (
            <MotivationPlaceholder />
          ) : (
            <ContentPlaceholder />
          )
        }
        footer={withFooter && <FooterPlaceholder />}
      />
    );
  }

  return (
    <ConfirmationLayout
      resendingEmail={resendingEmail}
      showSuccessMessage={showSuccessMessage}
      withResentLink={
        actionsAppearance ===
        ConfirmationActionsAppearance.WITH_RESEND_LINK_AND_ICONS
      }
      onResendClick={handleResendClick}
      emailService={service}
      actionsWithIcons={
        actionsAppearance ===
        (ConfirmationActionsAppearance.WITH_ICONS ||
          ConfirmationActionsAppearance.WITH_RESEND_LINK_AND_ICONS)
      }
      motivation={
        showMotivation && (
          <ConfirmationUserMotivation
            userId={userId}
            confirmationLayout={ConfirmationUserMotivationLayout}
            confirmationPlaceholder={ConfirmationUserMotivationPlaceholder}
            errorBoundary={ErrorBoundary}
          />
        )
      }
      content={
        withMotivationContent ? (
          <MotivationLayout email={email} align={contentAlign} />
        ) : (
          <ContentLayout
            siteName={data.site.name}
            email={email}
            userId={userId}
            customTitle={customTitle}
          />
        )
      }
      footer={withFooter && <FooterLayout siteName={data.site.name} />}
    />
  );
};

const componentPropType = PropTypes.oneOfType([
  PropTypes.objectOf(PropTypes.any),
  PropTypes.func,
]);

Confirmation.propTypes /* remove-proptypes */ = {
  userId: PropTypes.string,
  contentAlign: PropTypes.string,
  withMotivation: PropTypes.bool,
  customTitle: PropTypes.string,
  contentLayoutComponent: componentPropType.isRequired,
  contentPlaceholderComponent: componentPropType.isRequired,
  motivationLayoutComponent: componentPropType,
  motivationLayoutPlaceholderComponent: componentPropType,
  footerLayoutComponent: componentPropType,
  footerPlaceholderComponent: componentPropType,
};

Confirmation.defaultProps = {
  withMotivation: true,
};

export default Confirmation;
