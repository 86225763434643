import PaymentBannerPlacement from '@core/payment/banners/paymentBanner/constants/PaymentBannerPlacement';
import {PRIVATE_ALBUM} from '@core/coins/buyCoins/constants/vias';

import {MICROFEATURES} from '@phoenix/microfeatures/common/constants/microfeatures';

/**
 * Vias for buying coins
 */

// Vias for send gift by different places
export const SEND_GIFT_MESSENGER = 'send_gift_messenger';

// Vias for send message by different places
export const SEND_SMS_MESSAGE = 'coin_sms';
export const SEND_MMS_MESSAGE = 'coin_mms';
export const UNSEND_SMS_MESSAGE = 'unsend_coin_sms';
export const UNSEND_MMS_MESSAGE = 'unsend_coin_mms';
export const UNSEND_GIFT = 'unsend_gift';

// Vias for top up balance from balance banner
const TOP_UP_BALANCE = 'balance_header';
const TOP_UP_BALANCE_PROFILE = 'balance_profile';

// Via, from balance refill block on free coins page.
// @see FreeCoinsRefillMotivation.js
const FREE_COINS_BALANCE = 'app_index';

// Via, for paid reel spin on coins payment model
// @see FreeDailySpin.js
export const PAID_SPIN = 'paid_spin';

// Via, for paid reel spin on coins payment model
// @see FortuneWheelForCreditsPopup.js
export const COINS_SPIN = 'coins_spin';

// Vias for messenger paid media view
export const VIEW_PHOTO_MESSAGE = 'msg_photo';
export const VIEW_VIDEO_MESSAGE = 'msg_video';

// Vias for open PP from motivation banner
export const OPEN_PP_BANNER_SEARCH = 'coins_banner_search';
export const OPEN_PP_BANNER_SPECIAL_OFFER_SEARCH = 'banner_unlimWeek';
export const OPEN_PP_BANNER_MESSENGER = 'coins_banner_messenger';
export const OPEN_PP_BANNER_POPUP = 'coins_banner_popup';
const OPEN_PP_BANNER_PROFILE = 'coins_banner_profile';
const OPEN_PP_BANNER_ACTIVITY = 'coins_banner_activity';

export const OPEN_PP_BANNER_VIAS_BY_PLACE = {
  [PaymentBannerPlacement.MENU]: OPEN_PP_BANNER_PROFILE,
  [PaymentBannerPlacement.NOTIF]: OPEN_PP_BANNER_ACTIVITY,
};

// Vias for open PP from motivation Popup
export const OPEN_PP_POPUP_WEEK_SPEAK = 'popup_unlimWeek';

// Vias for open PP from admin messages
export const OPEN_PP_BANNER_SPECIAL_OFFER_MESSENGER = 'messenger_unlimWeek';

// Open PP from discreet profiles
export const COINS_DISCREET_PROFILES = 'coins_discreetProfiles';

// Buy coins from discreet profiles banner
export const COINS_BANNER_DISCREET_PROFILES = 'coins_banner_discreetProfiles';

// Via for chataholic banner
export const FEATURES_COINS_MSG = 'features_coins_msg';

export const PROFILE_GIFT_SEND = 'profile_gift';
export const POPUP_GIFT_SEND = 'popup_gift';

export const WORLD_WIDE = 'worldWide';

export default {
  COINS_DISCREET_PROFILES,
  COINS_BANNER_DISCREET_PROFILES,
  SEND_GIFT_MESSENGER,
  SEND_SMS_MESSAGE,
  SEND_MMS_MESSAGE,
  TOP_UP_BALANCE,
  TOP_UP_BALANCE_PROFILE,
  FREE_COINS_BALANCE,
  UNSEND_SMS_MESSAGE,
  UNSEND_MMS_MESSAGE,
  UNSEND_GIFT,
  PAID_SPIN,
  WORLD_WIDE,
  PRIVATE_ALBUM,
  VIEW_PHOTO_MESSAGE,
  VIEW_VIDEO_MESSAGE,
  OPEN_PP_BANNER_SEARCH,
  OPEN_PP_BANNER_MESSENGER,
  OPEN_PP_BANNER_VIAS_BY_PLACE,
  OPEN_PP_BANNER_SPECIAL_OFFER_MESSENGER,
  FEATURES_COINS_MSG,
  POPUP_GIFT_SEND,
  ...MICROFEATURES,
};
