import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import {Icon} from '@phoenix/ui';
import animationsCss from '@phoenix/widgets/animation/animations.css';

import {ICONS} from '../constants/icons';
import {MICROFEATURES} from '../../common/constants/microfeatures';
import MicrofeaturesIconLayout from './MicrofeaturesIconLayout';

const MicrofeaturesIconPriorityMan = ({withAnimation, active, ...props}) => (
  <MicrofeaturesIconLayout
    icon={
      <Icon
        className={cn(withAnimation && animationsCss.bounce)}
        type={ICONS[MICROFEATURES.PRIORITY_MAN]}
        inherit={!active}
      />
    }
    type={MicrofeaturesIconLayout.TYPE.PRIMARY}
    active={active}
    {...props}
  />
);

MicrofeaturesIconPriorityMan.SIZE = MicrofeaturesIconLayout.SIZE;

MicrofeaturesIconPriorityMan.propTypes /* remove-proptypes */ = {
  withAnimation: PropTypes.bool,
  active: PropTypes.bool,
};

MicrofeaturesIconPriorityMan.defaultProps = {
  withAnimation: true,
  active: false,
};

export default MicrofeaturesIconPriorityMan;
