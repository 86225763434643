import camelCase from 'lodash/camelCase';

import {ucfirst} from '@core/utils/string';

/**
 * @example Get from 'button.get_it' to 'GetIt'
 * @param {String} title
 */
const getButtonTitleForTracking = (title) => {
  return title ? ucfirst(camelCase(title.split('.')[1])) : '';
};

export default getButtonTitleForTracking;
