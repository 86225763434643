import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {motion, AnimatePresence} from 'framer-motion';

import getAnimationTime from '@core/utils/animation/utils/getAnimationTime';
import useSmallPhoneBreakpoint from '@core/responsive/useSmallPhoneBreakpoint';
import BabciaScopedProvider from '@core/tracking/babcia/containers/BabciaScopedProvider';
import {ANIMATED_TOOLBAR_ID} from '@core/header/constants/header';
import {ToolbarContext} from '@core/header/containers/HeaderToolbarContext';

import ThemedSubToolbar from '@phoenix/theming/components/ThemedSubToolbar';
import {SpacingSize} from '@phoenix/ui/constants';

import HeaderToolbarLayout from './HeaderToolbarLayout';
import css from '../styles/HeaderToolbar.css';

const AnimatedHeader = ({children, className, ...props}) => {
  return (
    <motion.div
      {...props}
      className={className}
      variants={{
        shown: {
          height: 'var(--toolbarFixedHeight)',
          overflow: 'hidden',
          transitionEnd: {
            overflow: 'visible',
          },
        },
        exit: {
          height: 0,
          overflow: 'hidden',
        },
      }}
      initial="exit"
      exit="exit"
      animate="shown"
      transition={{
        damping: 0,
        duration: getAnimationTime(),
        // Fix jumping content on FF and Safari
        delay: getAnimationTime({delay: 0.1}),
      }}
    >
      {children}
    </motion.div>
  );
};

AnimatedHeader.propTypes /* remove-proptypes */ = {
  children: PropTypes.node,
  className: PropTypes.string,
};

/**
 * Draws toolbar nodes that it receives from the provider, which in turn receives from the consumer
 * Detached toolbar from base content. Used widely on mobile environment.
 * So, if you want render something in toolbar parallely with content - you must use this component :)
 * @todo remove isHidden after move to the react
 * @constructor HeaderToolbar
 */
const HeaderToolbar = () => {
  const {children, isHidden, options} = useContext(ToolbarContext);
  const isPhone = useSmallPhoneBreakpoint();

  const content = (
    <BabciaScopedProvider context="toolbar">
      <HeaderToolbarLayout {...options}>{children}</HeaderToolbarLayout>
      <ThemedSubToolbar />
    </BabciaScopedProvider>
  );

  if (options.withAnimation) {
    return (
      <AnimatePresence>
        {children && (
          <AnimatedHeader
            key={isHidden}
            /**
             * Should be removed when we unify appearance between 2 platforms.
             * @see HeaderTopWrapper.js
             */
            className={isPhone ? null : css.additionalSpacing}
            id={ANIMATED_TOOLBAR_ID}
            data-test={ANIMATED_TOOLBAR_ID}
          >
            {content}
          </AnimatedHeader>
        )}
      </AnimatePresence>
    );
  }

  if (isHidden) {
    return null;
  }

  return content;
};

HeaderToolbar.SIZE = SpacingSize;

export default HeaderToolbar;
