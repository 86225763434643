import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {ButtonFlat, ButtonPrimary, Group} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';

/**
 * @see TrustedVerificationSmsSecondStepLayout.js
 */
const TrustedVerificationSmsSecondStepLayoutActions = ({
  handleChangePhone,
  oneColumn,
  isSubmitting,
}) => {
  const changeNumberButton = (
    <ButtonFlat
      data-test="changeMobileNumber"
      onClick={handleChangePhone}
      trackingName="changeNumber"
      adaptive
      fullWidth
      loading={isSubmitting}
    >
      {t('paymentTrusted', 'text.change.mobile.number')}
    </ButtonFlat>
  );

  return (
    <Group
      space={oneColumn ? SpacingSize.SHORT : SpacingSize.NORMAL}
      horizontal={!oneColumn}
      forceInline={!oneColumn}
    >
      {!oneColumn && changeNumberButton}
      <ButtonPrimary
        data-test="submitButton"
        form="trustedSecondStep"
        submit
        trackingName="submit"
        loading={isSubmitting}
        fullWidth
        adaptive
      >
        {t('paymentTrusted', 'text.become_trusted')}
      </ButtonPrimary>
      {oneColumn && changeNumberButton}
    </Group>
  );
};

TrustedVerificationSmsSecondStepLayoutActions.propTypes /* remove-proptypes */ = {
  handleChangePhone: PropTypes.func.isRequired,
  oneColumn: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

TrustedVerificationSmsSecondStepLayoutActions.defaultProps = {
  oneColumn: false,
  isSubmitting: false,
};

export default TrustedVerificationSmsSecondStepLayoutActions;
