import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {useInView} from 'react-intersection-observer';

import GIFT_ID_TO_IMAGE_MAP from '../constants/giftIDtoImageMap';
import GIFT_SIZE from '../constants/giftSize';
import css from '../styles/Gift.css';

const ONE_PIXEL_IMAGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

/**
 * Image of gift for images list
 * @returns {JSX.Element}
 * @constructor
 */
const Gift = ({id, size, className}) => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const imageSrc = GIFT_ID_TO_IMAGE_MAP[id];

  return imageSrc ? (
    <img
      ref={ref}
      className={cn(css.image, css[size], className)}
      src={
        inView || window.IS_INTEGRATION_TEST_ENVIRONMENT
          ? imageSrc
          : ONE_PIXEL_IMAGE
      }
      alt={id}
    />
  ) : null;
};

Gift.propTypes /* remove-proptypes */ = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(GIFT_SIZE)),
};

Gift.defaultProps = {
  size: GIFT_SIZE.FLUID,
};

export default Gift;
