import React from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';

import logger from '@core/logger';

import {Button} from '@phoenix/ui';
import {ButtonType} from '@phoenix/ui/constants';
import getPartnerRelatedPropsFor from '@phoenix/liveCam/utils/getPartnerRelatedPropsFor';
import WebCamUserPlacement from '@phoenix/liveCam/containers/WebCamUserPlacement';

import ViewAllButtonText from '../components/ViewAllButtonText';
import WEB_CAM_BUTTON from '../graphql/queries/webCamButton.gql';

const PLACEMENT_NAMES = {
  PROFILE_PAGE_BUTTON: 'profilePageButton',
  CAM_VIDEO_BUTTON: 'camVideoButton',
};

const getPlacement = (placementName, webcamPlacements) => {
  let placement = {};

  switch (placementName) {
    case PLACEMENT_NAMES.PROFILE_PAGE_BUTTON:
      if (webcamPlacements && webcamPlacements.profileProfileJoinChat) {
        placement = webcamPlacements && webcamPlacements.profileProfileJoinChat;
      }
      break;

    case PLACEMENT_NAMES.CAM_VIDEO_BUTTON:
      if (webcamPlacements && webcamPlacements.profileCamVideoButton) {
        placement = webcamPlacements.profileCamVideoButton;
      }
      break;

    default:
      logger.sendWarning('Web cam button without InventoryTracking');
  }

  return placement;
};

/**
 * Web cam button with texts for cam and inventory tracking for click
 */
const WebCamButton = ({
  userId,
  placeholder,
  fullWidth,
  narrow,
  inverse,
  round,
  icon,
  type,
  big,
  onClick,
  className,
  forceWebCamPartner,
  placementName,
  withProviderName,
}) => {
  const {loading, data} = useQuery(WEB_CAM_BUTTON, {
    variables: {userId, partner: forceWebCamPartner},
  });

  if (loading) {
    return placeholder;
  }

  const {webcamPlacements} = data.user;

  return (
    <WebCamUserPlacement
      key="livecam"
      onClick={onClick}
      webCamData={getPlacement(placementName, webcamPlacements)}
    >
      {({onClick: handleButtonClick, ref, webCamData}) => {
        const {text: camText, labelText} = getPartnerRelatedPropsFor.user(
          webCamData.partner,
          withProviderName,
        );

        return (
          <Button
            innerRef={ref}
            onClick={handleButtonClick}
            trackingName="webcam"
            fullWidth={fullWidth}
            narrow={narrow}
            inverse={inverse}
            round={round}
            icon={icon}
            type={type}
            className={className}
            big={big}
          >
            <ViewAllButtonText
              text={camText}
              subText={labelText}
              withOverflow={false}
            />
          </Button>
        );
      }}
    </WebCamUserPlacement>
  );
};

WebCamButton.TYPE = {...ButtonType};

WebCamButton.PLACEMENT_NAMES = PLACEMENT_NAMES;

WebCamButton.propTypes /* remove-proptypes */ = {
  fullWidth: PropTypes.bool,
  narrow: PropTypes.bool,
  inverse: PropTypes.bool,
  big: PropTypes.bool,
  round: PropTypes.bool,
  placeholder: PropTypes.node,
  userId: PropTypes.string.isRequired,
  icon: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  /**
   * Used in multiProviders RM logic. Depending on placement cam model can have different provider
   * We need to use it in query to get WebcamPlacements for specific partner
   */
  forceWebCamPartner: PropTypes.string,
  placementName: PropTypes.oneOf(Object.values(WebCamButton.PLACEMENT_NAMES)),
  withProviderName: PropTypes.bool,
};

WebCamButton.defaultProps = {
  placeholder: null,
  icon: null,
  placementName: WebCamButton.PLACEMENT_NAMES.PROFILE_PAGE_BUTTON,
  withProviderName: false,
};

export default WebCamButton;
