import type {PaymentAnswer} from '@core/payment/payProcess/types/paymentAnswerProps';
import showRedirectUrl from '@core/payment/payProcess/utils/showRedirectUrl';

/**
 * Need for output to be sure that isn't server 303 redirect.
 * Mostly for QA purpose.
 */
const showRedirectUrlInConsoleIfNeed = (
  paymentAnswer: PaymentAnswer,
): PaymentAnswer => {
  showRedirectUrl(paymentAnswer.redirectUrl);
  return paymentAnswer;
};

export default showRedirectUrlInConsoleIfNeed;
