import React from 'react';
import PropTypes from 'prop-types';

import {Textarea} from '@phoenix/ui';

import useRemainingCharacters from './useRemainingCharacters';
import useTextFieldMask from './useTextFieldMask';

/**
 * Input which counts symbols number and limit them
 */
const TextareaWithMaxLength = ({value, maxLength, onChange, ...props}) => {
  const {onChange: onRemainingChange, description} = useRemainingCharacters(
    value,
    maxLength,
    onChange,
  );

  const onMaskChange = useTextFieldMask(onRemainingChange);

  return (
    <Textarea
      {...props}
      value={value}
      maxLength={maxLength}
      onChange={onMaskChange}
      description={description}
    />
  );
};

TextareaWithMaxLength.propTypes /* remove-proptypes */ = {
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  value: PropTypes.string,
};

TextareaWithMaxLength.defaultProps = {
  maxLength: 500,
  value: '',
};

export default TextareaWithMaxLength;
