import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {Row} from '@phoenix/ui';
import RemarketingBanner from '@phoenix/remarketing/banner';
import PLACEMENT_IDS from '@phoenix/remarketing/banner/constants/placementIds';

import Disclaimer from './Disclaimer';
import FooterDNSMPI from './FooterDNSMPI';
import FooterDigitalServiceAct from './FooterDigitalServiceAct';
import Copyright from './Copyright';
import SiteMap from './SiteMap';
import css from '../styles/Footer.css';
import PciCertified from './PciCertified';

const FooterLayout = ({
  disclaimer,
  footerDNSMPI,
  digitalServiceAct,
  copyright,
  siteMap,
  license,
  showSiteMap,
  withSpacedAction,
  allowedPciCertified,
  showBanner,
}) => (
  <div className={cn(css.footer, withSpacedAction && css.withSpacedAction)}>
    <div data-test="footer">
      {allowedPciCertified && <PciCertified />}
      {showBanner && (
        <RemarketingBanner placementId={PLACEMENT_IDS.TEXT_BOTTOM} />
      )}

      {footerDNSMPI && <FooterDNSMPI href={footerDNSMPI} />}
      {digitalServiceAct && (
        <FooterDigitalServiceAct href={digitalServiceAct} />
      )}

      {disclaimer && <Disclaimer href={disclaimer} />}

      <Copyright copyright={copyright} />

      {!showSiteMap && (
        <Row>
          <SiteMap items={siteMap} />
        </Row>
      )}

      {license.map((str) => (
        <div key={str}>{str}</div>
      ))}
    </div>
  </div>
);

FooterLayout.propTypes /* remove-proptypes */ = {
  disclaimer: PropTypes.string,
  footerDNSMPI: PropTypes.string,
  digitalServiceAct: PropTypes.string,
  copyright: PropTypes.string.isRequired,
  siteMap: PropTypes.arrayOf(PropTypes.object).isRequired,
  license: PropTypes.arrayOf(PropTypes.string).isRequired,
  showSiteMap: PropTypes.bool,
  withSpacedAction: PropTypes.bool,
  allowedPciCertified: PropTypes.bool,
  showBanner: PropTypes.bool.isRequired,
};

FooterLayout.defaultProps = {
  showSiteMap: false,
  digitalServiceAct: null,
  allowedPciCertified: false,
};

export default FooterLayout;
