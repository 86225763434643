import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import {translateMessageEntityMapPropTypes} from '@core/payment/widgets/footer/constants/propTypes';

import {TextMuted} from '@phoenix/typography';

import FooterRow from './FooterRow';

const PaymentUserPublicId = ({
  inverse,
  publicUserId,
  translateMessageEntityMap,
  shortInfo,
  small,
}) => {
  if (shortInfo) {
    return (
      <FooterRow>
        <TextMuted inverse={inverse} small={small}>
          {t('paymentPageSidebar', 'text.tell_your_userId', {
            '{userId}': publicUserId,
          })}
        </TextMuted>
      </FooterRow>
    );
  }
  const {assistanceText, publicUserIdText} = translateMessageEntityMap;

  if (!assistanceText && !publicUserIdText) {
    return null;
  }

  return (
    <FooterRow>
      {assistanceText}
      {publicUserIdText && (
        <div
          dangerouslySetInnerHTML={{
            __html: publicUserIdText.replace('{publicId}', publicUserId),
          }}
        />
      )}
    </FooterRow>
  );
};

PaymentUserPublicId.propTypes /* remove-proptypes */ = {
  inverse: PropTypes.bool,
  shortInfo: PropTypes.bool,
  publicUserId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  translateMessageEntityMap: translateMessageEntityMapPropTypes,
  small: PropTypes.bool,
};

PaymentUserPublicId.defaultProps = {
  inverse: false,
  shortInfo: false,
};

export default PaymentUserPublicId;
