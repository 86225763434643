import React from 'react';
import PropTypes from 'prop-types';

import {TextWithIcon} from '@phoenix/typography';
import {Align, SpacingSize} from '@phoenix/ui/constants';
import COIN_ICON_SIZES from '@phoenix/credits/constants/coinColorizedIconSizes';

import CoinColorizedIcon from './CoinColorizedIcon';
import CoinColorizedIconByAmount from './CoinColorizedIconByAmount';

const CreditAmount = ({children, iconSize, align, ...textProps}) => (
  <TextWithIcon
    space={SpacingSize.SHORTEST}
    icon={
      iconSize === COIN_ICON_SIZES.MEDIUM ? (
        <CoinColorizedIcon size={iconSize} />
      ) : (
        <CoinColorizedIconByAmount size={iconSize} inline />
      )
    }
    align={align}
    {...textProps}
  >
    {children}
  </TextWithIcon>
);

CreditAmount.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  iconSize: PropTypes.oneOf(Object.values(COIN_ICON_SIZES)),
  align: PropTypes.oneOf(Object.values(Align)),
};

CreditAmount.defaultProps = {
  iconSize: COIN_ICON_SIZES.SMALL,
  align: Align.CENTER,
};

export default CreditAmount;
