import type {PaymentAnswer} from '@core/payment/payProcess/types/paymentAnswerProps';

import updateCachesAfterPayment from '../../utils/updateCachesAfterPayment';

const updateRemarketingOfferAfterPayment = async (
  paymentAnswer: PaymentAnswer,
): Promise<PaymentAnswer> => {
  if (paymentAnswer.orderId) {
    await updateCachesAfterPayment(true, ['UserFeatures', 'remarketingPopup']);
  }

  return paymentAnswer;
};

export default updateRemarketingOfferAfterPayment;
