import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import t from '@core/translations/translate';
import BabciaScopedProvider from '@core/tracking/babcia/containers/BabciaScopedProvider';

import {Align} from '@phoenix/ui/constants';
import {Link, Text} from '@phoenix/typography';
import QueryWithErrorBoundary from '@phoenix/graphql/containers/QueryWithErrorBoundary';
import StaticPagePopupHelper from '@phoenix/staticPages/utils/StaticPagePopupHelper';

import GDPR_QUERY from './graphql/queries/GDPR.gql';
import css from './GDPR.css';

/**
 * @param {Object} e
 */
const handleClick = (e) => {
  StaticPagePopupHelper.openPopup(
    StaticPagePopupHelper.PAGES.PRIVACY_POLICY,
    e,
  );
};

/**
 * GDPR legal info description.
 * If isn't available - just don't render nothing
 */
const GDPR = ({className, small, muted, align, wrap}) => (
  <QueryWithErrorBoundary
    query={GDPR_QUERY}
    errorSize={QueryWithErrorBoundary.ERROR_SIZE.ONLY_ICON}
  >
    {({data}) => {
      if (!data?.userFeatures?.gdpr) return null;

      const gdprContent = (
        <div className={cn(css[align], className)} data-test="gdpr">
          <Text small={small} type={muted ? Text.TYPE.MUTED : Text.TYPE.NORMAL}>
            {[
              t('generalDataProtectionRegulation', 'text.profile_information'),
              <Link
                key="link"
                small={small}
                trackingName="gdpr"
                onClick={handleClick}
                className={css.link}
                data-test="gdprLink"
              >
                {t('generalDataProtectionRegulation', 'text.privacy_policy')}
              </Link>,
              t('generalDataProtectionRegulation', 'text.includes_data'),
            ]}
          </Text>
        </div>
      );

      return (
        <BabciaScopedProvider context="GDPR">
          {wrap ? wrap(gdprContent) : gdprContent}
        </BabciaScopedProvider>
      );
    }}
  </QueryWithErrorBoundary>
);

GDPR.propTypes /* remove-proptypes */ = {
  wrap: PropTypes.func,
  small: PropTypes.bool,
  align: PropTypes.oneOf(Object.values(Align)),
  muted: PropTypes.bool,
  className: PropTypes.string,
};

GDPR.defaultProps = {
  small: false,
  muted: false,
  align: Align.CENTER,
};

export default GDPR;
