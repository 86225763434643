import React from 'react';
import PropTypes from 'prop-types';

import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';

import {Spacing, Group} from '@phoenix/ui';
import {
  SpacingDirection,
  SpacingSize,
  VerticalAlign,
} from '@phoenix/ui/constants';

import DisapproveReasonsTitle from './DisapproveReasonsTitle';

const DisapproveReasonsLayout = ({
  icon,
  wrapSpacing,
  className,
  rowSpacing,
  titleSpacing,
  inPopup,
  inverse,
  children,
  showTitle,
  withoutTop,
  direction,
}) => (
  <Spacing
    className={className}
    withoutTop={withoutTop}
    direction={direction}
    size={wrapSpacing}
  >
    {showTitle && (
      <Spacing size={titleSpacing} withoutTop withoutLeft withoutRight>
        <DisapproveReasonsTitle
          icon={icon}
          inPopup={inPopup}
          inverse={inverse}
        />
      </Spacing>
    )}
    <Group
      verticalAlign={VerticalAlign.TOP}
      itemWithEqualWidth
      space={rowSpacing}
    >
      {children}
    </Group>
    <PopupLoadTracker />
  </Spacing>
);

DisapproveReasonsLayout.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
  inPopup: PropTypes.bool,
  inverse: PropTypes.bool,
  wrapSpacing: PropTypes.oneOf(Object.values(SpacingSize)),
  direction: PropTypes.oneOf(Object.values(SpacingDirection)),
  rowSpacing: PropTypes.oneOf(Object.values(SpacingSize)),
  titleSpacing: PropTypes.oneOf(Object.values(SpacingSize)),
  showTitle: PropTypes.bool,
  withoutTop: PropTypes.bool,
};

DisapproveReasonsLayout.defaultProps = {
  wrapSpacing: SpacingSize.NORMAL,
  rowSpacing: SpacingSize.SHORT,
  titleSpacing: SpacingSize.NORMAL,
  inPopup: false,
  inverse: false,
  showTitle: true,
};

export default DisapproveReasonsLayout;
