import React, {forwardRef, Fragment} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import t from '@core/translations/translate';
import getUserAgentParser from '@core/utils/getUserAgentParser';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';

import {Text, TextMuted, H3} from '@phoenix/typography';
import {
  Button,
  RowShort,
  Spacing,
  Tag,
  TagSuccess,
  Group,
  Row,
  Icon,
  Bar,
  InputFrame,
} from '@phoenix/ui';
import {
  ButtonType,
  TagType,
  SpacingSize,
  SpacingDirection,
} from '@phoenix/ui/constants';
import {graphSearchUserPropType} from '@phoenix/search/widget/constants/propTypes';
import normalizePropsForSearchWidget from '@phoenix/search/widget/utils/normalizePropsForSearchWidget';
import PhotoWithWidgets from '@phoenix/user/photo/components/PhotoWithWidgets';

import css from '../styles/InAppMotivationPopupLayout.css';
import {
  CHAT,
  DEFAULT,
  PAID_FUNNEL_PAGE,
  PAYMENT_PAGE,
  USER,
  VIDEO_CALL,
} from '../constants/motivationTypes';
import {
  COPY_LINK,
  OPEN_BROWSER,
  PASTE_LINK,
  COMPLETE,
} from '../constants/tagSteps';
import HEADER_TEXT_TRANSLATION from '../constants/headerTextTranslation';

/**
 * @const {Boolean}
 */
const IS_ANDROID = getUserAgentParser().getOS().name === 'Android';

const getTag = (tagContent, activeTag) => {
  if (tagContent === activeTag) {
    return (
      <Tag data-test="activeTag" className={css.tag} type={TagType.PRIMARY}>
        {tagContent}
      </Tag>
    );
  }

  if (tagContent < activeTag) {
    return (
      <TagSuccess
        data-test="alreadyActiveTag"
        className={css.tag}
        icon="check"
      />
    );
  }

  return (
    <Tag data-test="inactiveTag" className={css.tag} type={TagType.TRANSLUCENT}>
      {tagContent}
    </Tag>
  );
};

/**
 * @param {Boolean} alterBtns
 * @param {function} onLinkClick
 * @returns {JSX.Element}
 */
const getButtons = (alterBtns, onLinkClick) => {
  if (alterBtns) {
    return (
      <Group space={SpacingSize.SHORT}>
        <div>
          <Button
            fullWidth
            type={ButtonType.PRIMARY}
            data-test="switchBtn"
            onClick={onLinkClick}
            trackingName="switchBrowser"
          >
            {t('inAppMotivation', 'text.switch_browser')}
          </Button>
        </div>
        <div>
          <Button
            fullWidth
            data-test="skipLinkBtn"
            onClick={onLinkClick}
            trackingName="skip"
          >
            {t('inAppMotivation', 'text.skip')}
          </Button>
        </div>
      </Group>
    );
  }

  return (
    <Button
      fullWidth
      type={ButtonType.PRIMARY}
      data-test="linkBtn"
      onClick={onLinkClick}
      trackingName="gotIt"
    >
      {t('inAppMotivation', 'text.got_it')}
    </Button>
  );
};

const InAppMotivationPopupLayout = forwardRef(
  (
    {
      onCopyClick,
      type,
      loginUrl,
      onLinkClick,
      onInputFocus,
      onInputBlur,
      user,
      activeTag,
      alterBtns,
      dictionaryTribes,
      distanceUnits,
    },
    ref,
  ) => {
    const isPhone = usePhoneBreakpoint();
    const motivationText = HEADER_TEXT_TRANSLATION[type];

    return (
      <Fragment>
        {user ? (
          <Bar asPopupHeading>
            <Spacing size={isPhone ? SpacingSize.SHORT : SpacingSize.NORMAL}>
              <Group horizontal strictSizeFirstItem space={SpacingSize.SHORT}>
                <div className={css.photo}>
                  <PhotoWithWidgets
                    {...normalizePropsForSearchWidget({
                      user,
                      dictionaryTribes,
                      distanceUnits,
                    })}
                    round
                    showOverlay={false}
                    withNaughtyMode={false}
                    clickable={false}
                    photoCount={null}
                    videoCount={null}
                  />
                </div>
                <Text data-test="headerText" className={css.headerText}>
                  {motivationText}
                </Text>
              </Group>
            </Spacing>
          </Bar>
        ) : (
          <Spacing direction={SpacingDirection.HORIZONTAL}>
            {type === DEFAULT ? (
              <div data-test="motivationText" className={css.header}>
                <TextMuted>{motivationText}</TextMuted>
              </div>
            ) : (
              <Spacing onlyTop size={SpacingSize.NORMAL}>
                <H3 data-test="headingText" className={css.headerText}>
                  {motivationText}
                </H3>
              </Spacing>
            )}
          </Spacing>
        )}
        <Spacing>
          <RowShort className={css.row}>
            <div className={css.title}>
              <div className={css.stepFirst} data-test="copyLinkTag">
                {getTag(COPY_LINK, activeTag)}
              </div>
              <Group space={SpacingSize.SHORT} className={css.wrap}>
                <InputFrame className={css.url}>
                  <input
                    readOnly={IS_ANDROID}
                    onChange={() => {}}
                    onFocus={onInputFocus}
                    onBlur={onInputBlur}
                    ref={ref}
                    type="text"
                    value={loginUrl}
                    data-test="browserChangeLinkArea"
                  />
                </InputFrame>
                <Button
                  inline
                  submit
                  fullWidth
                  data-test="copyBtn"
                  onClick={onCopyClick}
                  trackingName="copyLink"
                >
                  {t('inAppMotivation', 'text.copy_link')}
                </Button>
              </Group>
            </div>
          </RowShort>
          <RowShort className={css.row}>
            <div className={css.title}>
              <div className={css.step} data-test="openBrowserTag">
                {getTag(OPEN_BROWSER, activeTag)}
              </div>
              <div>{t('inAppMotivation', 'text.open_browser')}</div>
            </div>
          </RowShort>
          <RowShort className={css.rowLast}>
            <div className={css.title}>
              <div className={css.step} data-test="pasteLinkTag">
                {getTag(PASTE_LINK, activeTag)}
              </div>
              <Group space={SpacingSize.SHORT} className={css.wrapBrowser}>
                <div>{t('inAppMotivation', 'text.paste_in_browser')}</div>
                <div className={css.browser}>
                  <Spacing onlyRight stretch={false}>
                    <Icon muted type="world" />
                  </Spacing>
                  <TextMuted
                    className={cn(
                      css.text,
                      activeTag === PASTE_LINK && css.textAnimated,
                    )}
                  >
                    {loginUrl}
                  </TextMuted>
                </div>
              </Group>
            </div>
          </RowShort>
          <Row>
            <PopupLoadTracker>
              {getButtons(alterBtns, onLinkClick)}
            </PopupLoadTracker>
          </Row>
        </Spacing>
      </Fragment>
    );
  },
);

InAppMotivationPopupLayout.propTypes /* remove-proptypes */ = {
  type: PropTypes.oneOf([
    CHAT,
    PAYMENT_PAGE,
    PAID_FUNNEL_PAGE,
    USER,
    VIDEO_CALL,
    DEFAULT,
  ]).isRequired,
  activeTag: PropTypes.oneOf([COPY_LINK, OPEN_BROWSER, PASTE_LINK, COMPLETE]),
  loginUrl: PropTypes.string.isRequired,
  onLinkClick: PropTypes.func.isRequired,
  onInputFocus: PropTypes.func.isRequired,
  onInputBlur: PropTypes.func.isRequired,
  dictionaryTribes: PropTypes.arrayOf(PropTypes.object),
  distanceUnits: PropTypes.string.isRequired,
  user: PropTypes.shape(graphSearchUserPropType),
  onCopyClick: PropTypes.func.isRequired,
  alterBtns: PropTypes.bool,
};

InAppMotivationPopupLayout.defaultProps = {
  activeTag: null,
  alterBtns: false,
};

export default InAppMotivationPopupLayout;
