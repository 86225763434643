import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import COIN_ICON_SIZES from '../constants/coinColorizedIconSizes';
import css from '../styles/CoinColorizedIcon.css';

const getColorizedIconBySize = (size) => {
  if (size === COIN_ICON_SIZES.SMALL) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M9 2C12.866 2 16 5.13401 16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2Z"
          fill="#F9B80C"
        />
        <path
          opacity="0.7"
          d="M9.00001 14.6C5.90721 14.6 3.40001 12.0928 3.40001 8.99999C3.40001 5.9072 5.90721 3.39999 9.00001 3.39999C12.0928 3.39999 14.6 5.9072 14.6 8.99999C14.6 12.0928 12.0928 14.6 9.00001 14.6Z"
          fill="white"
        />
        <path
          d="M8.3 4.80002H9.7V5.57073C10.3723 5.70796 10.9895 6.03979 11.4749 6.52515L10.4849 7.51509C10.1912 7.2214 9.81705 7.0214 9.40969 6.94037C9.00233 6.85934 8.58009 6.90093 8.19636 7.05987C7.81264 7.21882 7.48466 7.48798 7.25391 7.83332C7.02316 8.17867 6.9 8.58468 6.9 9.00002C6.9 9.41536 7.02316 9.82137 7.25391 10.1667C7.48466 10.5121 7.81264 10.7812 8.19636 10.9402C8.58009 11.0991 9.00233 11.1407 9.40969 11.0597C9.81705 10.9786 10.1912 10.7786 10.4849 10.4849L11.4749 11.4749C10.9895 11.9602 10.3723 12.2921 9.7 12.4293V13.2H8.3V12.4293C8.08214 12.3848 7.868 12.3195 7.66061 12.2336C7.02107 11.9687 6.47444 11.5201 6.08986 10.9445C5.70527 10.3689 5.5 9.69225 5.5 9.00002C5.5 8.30779 5.70527 7.6311 6.08986 7.05552C6.47444 6.47995 7.02107 6.03135 7.66061 5.76644C7.868 5.68054 8.08214 5.6152 8.3 5.57073V4.80002Z"
          fill="#F9B80C"
        />
      </svg>
    );
  }

  if (size === COIN_ICON_SIZES.MEDIUM) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2Z"
          fill="#F9B80C"
        />
        <path
          opacity="0.7"
          d="M12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20Z"
          fill="white"
        />
        <path
          d="M11 6H13V7.10102C13.9604 7.29706 14.8422 7.77111 15.5355 8.46447L14.1213 9.87868C13.7018 9.45912 13.1672 9.1734 12.5853 9.05765C12.0033 8.94189 11.4001 9.0013 10.8519 9.22836C10.3038 9.45543 9.83524 9.83994 9.50559 10.3333C9.17595 10.8266 9 11.4067 9 12C9 12.5933 9.17595 13.1734 9.50559 13.6667C9.83524 14.1601 10.3038 14.5446 10.8519 14.7716C11.4001 14.9987 12.0033 15.0581 12.5853 14.9424C13.1672 14.8266 13.7018 14.5409 14.1213 14.1213L15.5355 15.5355C14.8422 16.2289 13.9604 16.7029 13 16.899V18H11V16.899C10.6888 16.8355 10.3829 16.7421 10.0866 16.6194C9.17295 16.241 8.39206 15.6001 7.84265 14.7779C7.29324 13.9556 7 12.9889 7 12C7 11.0111 7.29324 10.0444 7.84265 9.22215C8.39206 8.39991 9.17295 7.75904 10.0866 7.3806C10.3829 7.25788 10.6888 7.16455 11 7.10102V6Z"
          fill="#F9B80C"
        />
      </svg>
    );
  }

  return null;
};

/**
 * Create colorized icon by type
 */
const CoinColorizedIcon = ({size, className}) => (
  <div className={cn(css.wrap, className)}>{getColorizedIconBySize(size)}</div>
);

CoinColorizedIcon.propTypes /* remove-proptypes */ = {
  size: PropTypes.oneOf([COIN_ICON_SIZES.SMALL, COIN_ICON_SIZES.MEDIUM]),
  className: PropTypes.string,
};

CoinColorizedIcon.defaultProps = {
  size: COIN_ICON_SIZES.MEDIUM,
};

export default CoinColorizedIcon;
