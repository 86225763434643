import React, {Fragment, useEffect, useState} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import PhoneBreakpoint from '@core/responsive/PhoneBreakpoint';
import t from '@core/translations/translate';
import {setPageEnterTime} from '@core/payment/payProcess/utils/pageViewTime';

import PopupFrame from '@phoenix/popup/components/PopupFrame';
import {Button, IconSubstrate, Row, RowShort, Spacing} from '@phoenix/ui';
import {
  IconType,
  IconSubstrateSize,
  ButtonType,
  Align,
} from '@phoenix/ui/constants';
import {H3, Text, TextMuted} from '@phoenix/typography';

import freeFeatureConfigPropType from '../constants/freeFeatureConfigPropType';
import FreeFeatureBenefits from '../components/FreeFeatureBenefits';
import css from '../styles/FreeFeatureStatisticPopup.css';

const FreeFeatureStatisticPopup = ({config, onActionClick}) => {
  const [isPaymentProcessing, setPaymentProcessing] = useState(false);
  useEffect(() => setPageEnterTime(), []);

  const handleActionClick = () => {
    setPaymentProcessing(true);
    onActionClick();
  };

  return (
    <Fragment>
      <PopupFrame
        content={
          <Fragment>
            <Row align={Align.CENTER}>
              {config.customIcon || (
                <IconSubstrate
                  saturated
                  icon={config.icon}
                  iconClassName={config.animationClass}
                  className={css.icon}
                  type={IconType.PRIMARY}
                  size={IconSubstrateSize.LARGE}
                />
              )}
            </Row>
            <RowShort align={Align.CENTER}>
              <H3>{config.title}</H3>
            </RowShort>
            {config.description && (
              <RowShort align={Align.CENTER}>
                <TextMuted>{config.description}</TextMuted>
              </RowShort>
            )}
            <FreeFeatureBenefits benefits={config.benefits} />
            {config.text && (
              <Row align={Align.CENTER}>
                <Text>{config.text}</Text>
              </Row>
            )}
          </Fragment>
        }
        actions={
          <PhoneBreakpoint>
            {(matches) => (
              <div className={cn(!matches && css.action)}>
                <Button
                  fullWidth
                  type={ButtonType.PAY}
                  onClick={handleActionClick}
                  disabledInteraction
                  disabled={isPaymentProcessing}
                  trackingName="action"
                >
                  {config.actionText}
                </Button>
              </div>
            )}
          </PhoneBreakpoint>
        }
      />
      {config.bottomNotice && (
        <Spacing withoutTop>
          <TextMuted align={TextMuted.ALIGN.CENTER} small>
            {config.bottomNotice}
            {t('microfeatures', 'text.payment_will_be_charged')}
          </TextMuted>
        </Spacing>
      )}
    </Fragment>
  );
};

FreeFeatureStatisticPopup.propTypes /* remove-proptypes */ = {
  config: freeFeatureConfigPropType.isRequired,
  onActionClick: PropTypes.func.isRequired,
};

export default FreeFeatureStatisticPopup;
