import React from 'react';
import PropTypes from 'prop-types';

import ScrollableRefContext from '@core/utils/scroll/ScrollableRefContext';

import DisapproveReasonsTitle from '@phoenix/disapprove/components/DisapproveReasonsTitle';
import {SpacingSize} from '@phoenix/ui/constants';
import {Accordion} from '@phoenix/ui';
import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';

import css from '../styles/DisapproveReasonsWrapper.css';

const DisapproveReasonsWrapper = ({
  icon,
  component: Component,
  wrapped,
  withoutTop,
  withIcon,
}) => {
  const {data: sortedDisapproveReasons} = useThemeFeature(
    'mediaUpload',
    'sortedDisapproveReasons',
  );

  return (
    <ScrollableRefContext.Consumer>
      {(scrollableRef) => (
        <Accordion
          className={css.accordion}
          withoutLeft={wrapped}
          withoutRight={wrapped}
          withoutTop={withoutTop}
          unbordered
          withContentWrapper={false}
          title={<DisapproveReasonsTitle icon={icon} withIcon={withIcon} />}
          scrollableRef={scrollableRef}
          partialViewContent
        >
          <div className={css.accordionContent}>
            <Component
              rowSpacing={SpacingSize.SHORT}
              showTitle={false}
              withoutTop={!sortedDisapproveReasons}
            />
          </div>
        </Accordion>
      )}
    </ScrollableRefContext.Consumer>
  );
};

DisapproveReasonsWrapper.propTypes /* remove-proptypes */ = {
  icon: PropTypes.string.isRequired,
  /**
   * Reasons component based on DisapproveReasons.
   */
  component: PropTypes.func.isRequired,
  wrapped: PropTypes.bool,
  withoutTop: PropTypes.bool,
  withIcon: PropTypes.bool,
};

DisapproveReasonsWrapper.defaultProps = {
  withoutTop: false,
  withIcon: true,
};

export default DisapproveReasonsWrapper;
