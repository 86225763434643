import React from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';

import {Align} from '@phoenix/ui/constants';
import {TextMuted} from '@phoenix/typography';
import {RowShort, Separator, RowShortest} from '@phoenix/ui';

import CONTACTS_FOR_COMMUNICATION_QUERY from '../graphql/queries/contactsForCommunication.gql';

/**
 * Block which displays contact information
 */
const ContactsForCommunication = ({withSeparator, align, className}) => {
  const {data, loading, error} = useQuery(CONTACTS_FOR_COMMUNICATION_QUERY);
  const contacts = data?.support?.contact?.contactsForCommunication;

  if (loading || error || !contacts) return null;

  const content = (
    <>
      {withSeparator && (
        <RowShort>
          <Separator />
        </RowShort>
      )}
      <RowShortest align={align} data-test="contacts">
        <TextMuted
          small
          dangerouslySetInnerHTML={{__html: contacts.companyName}}
        />
        <TextMuted
          small
          dangerouslySetInnerHTML={{__html: contacts.addressString}}
        />
        {contacts.tel && <TextMuted small>{contacts.tel}</TextMuted>}
        {contacts.email && <TextMuted small>{contacts.email}</TextMuted>}
      </RowShortest>
    </>
  );

  if (className) {
    return <div className={className}>{content}</div>;
  }

  return content;
};

ContactsForCommunication.propTypes /* remove-proptypes */ = {
  withSeparator: PropTypes.bool,
  align: PropTypes.oneOf(Object.values(Align)),
  className: PropTypes.string,
};

ContactsForCommunication.defaultProps = {
  withSeparator: false,
  align: Align.LEFT,
};

export default ContactsForCommunication;
