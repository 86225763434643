import React from 'react';
import PropTypes from 'prop-types';
import {capture, getusermedia} from 'modernizr';

import t from '@core/translations/translate';
import * as ACCEPT from '@core/mediaUpload/constants/accept';
import useSmallTabletBreakpoint from '@core/responsive/useSmallTabletBreakpoint';
import MediaUploadActionsAppearance from '@core/theming/constants/features/MediaUploadActionsAppearance';

import {ButtonType} from '@phoenix/ui/constants';
import {Button} from '@phoenix/ui';
import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';

import FileButton from './FileButton';
import UploadActions from './UploadActions';

const VideoUploadActions = ({
  showWebcamButton,
  accept,
  onFileSelect,
  onCaptureClick,
}) => {
  const {data: actions} = useThemeFeature('mediaUpload', 'actions');
  const isSmallTablet = useSmallTabletBreakpoint();
  const withIcons = actions !== MediaUploadActionsAppearance.WITHOUT_ICONS;

  // Don't show webcam for phone, there is <input captue="camera" /> instead.
  const showWebcam =
    showWebcamButton && getusermedia && window.MediaRecorder && !isSmallTablet;

  // Need to show capture button for IPad becase of the lack of MediaRecorder support.
  const showCaptureButton = showWebcamButton && capture && !showWebcam;
  const showCaptureButtonForTablet = showCaptureButton && !isSmallTablet;
  const showCaptureButtonForPhone = showCaptureButton && isSmallTablet;

  const captureButton = (
    <FileButton
      onFileSelect={onFileSelect}
      icon={withIcons && 'video'}
      accept={ACCEPT.VIDEOS}
      trackingName="captureVideo"
      capture="camera"
    >
      {t('mediaUpload', 'text.capture_video')}
    </FileButton>
  );

  return (
    <UploadActions>
      {showWebcam && (
        <Button
          icon={withIcons && 'video'}
          onClick={onCaptureClick}
          trackingName="webcamCapture"
          data-test="webcamCapture"
        >
          {t('mediaUpload', 'text.capture_video')}
        </Button>
      )}
      {showCaptureButtonForTablet && captureButton}
      <FileButton
        type={ButtonType.PRIMARY}
        onFileSelect={onFileSelect}
        icon={withIcons && 'gallery'}
        accept={accept}
        trackingName="uploadVideo"
        data-test="uploadVideoButton"
      >
        {t('mediaUpload', 'text.upload_video')}
      </FileButton>
      {showCaptureButtonForPhone && captureButton}
    </UploadActions>
  );
};

VideoUploadActions.propTypes /* remove-proptypes */ = {
  accept: PropTypes.oneOf(Object.values(ACCEPT)),
  onFileSelect: PropTypes.func.isRequired,
  onCaptureClick: PropTypes.func,
  showWebcamButton: PropTypes.bool,
};

VideoUploadActions.defaultProps = {
  accept: ACCEPT.VIDEOS,
  showWebcamButton: true,
};

export default VideoUploadActions;
