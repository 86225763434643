import once from 'lodash/once';
import {combineLatest} from 'rxjs';
import {delay} from 'rxjs/operators';

import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';
import isNeedOpenPaymentPageInNewTab from '@core/payment/common/utils/isNeedOpenPaymentPageInNewTab';

import updateCachesAfterPayment from '../payProcess/utils/updateCachesAfterPayment';
import ORDER_PROCESSED_SUBSCRIPTION from '../graphql/subscriptions/orderProcessed.gql';
import {paymentPageOpenedInNewTab$} from './openPaymentPageInNewTab';

const PAYMENT_STATUSES = {
  SUCCESS: 'success',
  FAIL: 'fail',
};

/**
 * Delay update to be sure data is updated on backend.
 */
const UPDATE_CACHES_DELAY = 500;

/**
 * Update payment data for another opened tabs
 */
const startUpdatePaymentDataListener = once(async (client) => {
  /**
   * Update payment banner cache each time when payment page opened in new tab
   * Open payment page in new tab depended on payment page visits and userFeatures.paymentBanner.isOpenInNewWindow
   */
  paymentPageOpenedInNewTab$.subscribe(async (openedInNewTab) => {
    if (openedInNewTab) {
      await isNeedOpenPaymentPageInNewTab();
    }
  });

  combineLatest([
    paymentPageOpenedInNewTab$,
    client.subscribe({query: ORDER_PROCESSED_SUBSCRIPTION}),
  ])
    .pipe(delay(UPDATE_CACHES_DELAY))
    .subscribe(([openedInNewTab, orderProcessedData]) => {
      /**
       * Was case when payment success socket received before than payment request is resolved (payment on RM popup and then opened PP in new tab)
       * For prevent this case used canUpdateCacheAfterSuccessPayment
       * Update cache data after PP was opened in new tab
       * @see openPaymentPageInNewTab.js
       */
      if (openedInNewTab) {
        const {paymentStatus} = orderProcessedData.data.payment;

        if (paymentStatus === PAYMENT_STATUSES.SUCCESS) {
          updateCachesAfterPayment();
        } else if (paymentStatus === PAYMENT_STATUSES.FAIL) {
          invalidateCacheByTypename(client, 'UserFeatures', 'paymentLastOrder');
        }
      }
    });
});

export default startUpdatePaymentDataListener;
