import React, {forwardRef, useEffect} from 'react';
import PropTypes from 'prop-types';

import GalleryDisplayer from '@core/gallery/containers/GalleryDisplayer';
import useEventCallback from '@core/utils/react/useEventCallback';
import sendToGTM from '@core/gtm/utils/sendToGTM';
import EventName from '@core/gtm/constants/EventName';

import {Direction} from '@phoenix/ui/constants';

import GalleryPopupLayout from './GalleryPopupLayout';
import GalleryNavigationButton from './GalleryNavigationButton';
import mediaPropType from '../utils/mediaPropType';
import PhotoZoomableLayout from './PhotoZoomableLayout';
import VideoLayout from './VideoLayout';
import WebCamGalleryMotivation from './WebCamGalleryMotivation';

const defaultPhotoRenderer = (props) => <PhotoZoomableLayout {...props} />;

const defaultVideoRenderer = ({
  showWebCamMotivation,
  userId,
  forceWebCamPartner,
  ...props
}) => (
  <VideoLayout
    motivationAfterVideo={
      showWebCamMotivation && (
        <WebCamGalleryMotivation
          userId={userId}
          forceWebCamPartner={forceWebCamPartner}
        />
      )
    }
    userId={userId}
    {...props}
  />
);

defaultVideoRenderer.propTypes /* remove-proptypes */ = {
  userId: PropTypes.string.isRequired,
  showWebCamMotivation: PropTypes.bool,
  forceWebCamPartner: PropTypes.string,
};

const GalleryPopupWithGTMTrack = forwardRef(
  ({items, needGTMTrack, onSlide, ...props}, galleryRef) => {
    const track = useEventCallback((i) => {
      // eslint-disable-next-line no-undefined
      const index = i === undefined ? props.startIndex : i;
      if (needGTMTrack) {
        sendToGTM({
          eventName: items[index]?.video
            ? EventName.VIEW_VIDEO
            : EventName.VIEW_PHOTO,
        });
      }
    });

    // Track first slide render
    useEffect(() => {
      track();
    }, [track]);

    const handleSlideChange = useEventCallback((index) => {
      track(index);
      onSlide?.(index);
    });

    return (
      <GalleryPopupLayout
        items={items}
        ref={galleryRef}
        onSlide={handleSlideChange}
        {...props}
      />
    );
  },
);

GalleryPopupWithGTMTrack.propTypes /* remove-proptypes */ = {
  items: mediaPropType.isRequired,
  startIndex: PropTypes.number,
  needGTMTrack: PropTypes.bool,
  onSlide: PropTypes.func,
};

const Gallery = ({items, photoRenderer, videoRenderer, ...props}) => (
  <GalleryDisplayer
    items={items}
    navigationButtonComponent={GalleryNavigationButton}
    galleryPopupLayoutComponents={GalleryPopupWithGTMTrack}
    photoRenderer={photoRenderer}
    videoRenderer={videoRenderer}
    direction={Direction}
    {...props}
  />
);

Gallery.propTypes /* remove-proptypes */ = {
  photoRenderer: PropTypes.func,
  videoRenderer: PropTypes.func,
  items: mediaPropType.isRequired,
};

Gallery.defaultProps = {
  photoRenderer: defaultPhotoRenderer,
  videoRenderer: defaultVideoRenderer,
};

export default Gallery;
