import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import getHistory from '@core/application/utils/getHistory';

import MicrofeaturesSuccessPopup from '../containers/MicrofeaturesSuccessPopup';

/**
 * Show success popup after microfeature payment
 * @param {Object} params
 * @param {String} params.featureName (@see MICROFEATURES from common/constants/microfeatures.js)
 */
const openMicrofeaturesSuccessPopup = ({
  featureName,
  returnPath,
  ...options
} = {}) => {
  PopupService.openPopup(
    <MicrofeaturesSuccessPopup
      featureName={featureName}
      returnPath={returnPath}
    />,
    {
      small: true,
      trackingName: `microfeaturesSuccess_${featureName}`,
      autoLoadTracking: true,
      onClose: () => {
        returnPath && getHistory().replace(returnPath);
      },
      ...options,
    },
  );
};

export default openMicrofeaturesSuccessPopup;
