import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import {Icon} from '@phoenix/ui';
import {Text} from '@phoenix/typography';

import MediaWrapper from '../../common/components/MediaWrapper';
import css from '../../common/styles/MediaItem.css';

const MediaUploadButton = ({
  text,
  showText,
  onClick,
  grid,
  bold,
  type,
  iconType,
  bigIcon,
  ...props
}) => (
  <MediaWrapper grid={grid}>
    <AddBabciaUBTracking trackingName="uploadMediaBtn">
      <button
        className={css.itemUpload}
        type="button"
        onClick={onClick}
        {...props}
      >
        <div
          className={cn(css.add, bigIcon && css.bigIcon)}
          data-test="mediaUploadButtonIcon"
        >
          <div className={css.addIcon}>
            <Icon type={iconType} inherit />
          </div>
          {showText && (
            <Text
              className={css.addText}
              type={type}
              small
              bold={bold}
              data-test="mediaUploadButtonText"
            >
              {text}
            </Text>
          )}
        </div>
      </button>
    </AddBabciaUBTracking>
  </MediaWrapper>
);

MediaUploadButton.propTypes /* remove-proptypes */ = {
  onClick: PropTypes.func.isRequired,
  grid: PropTypes.oneOf(Object.values(MediaWrapper.GRID)),
  iconType: PropTypes.string,
  text: PropTypes.string,
  showText: PropTypes.bool,
  bigIcon: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(Text.TYPE)),
  bold: PropTypes.bool,
};

MediaUploadButton.defaultProps = {
  iconType: 'plus',
  showText: false,
  type: Text.TYPE.NORMAL,
  bold: true,
  bigIcon: false,
};

export default MediaUploadButton;
