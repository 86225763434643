/* global STATIC_FILES */

import {useEffect} from 'react';

import getCookie from '@core/utils/cookie/getCookie';

import {LOG_PWA_WORKER} from '../constants/progressiveWebApplicationCookies';

const ProgressiveWebApplicationWorker = () => {
  useEffect(() => {
    if (
      process.env.NODE_ENV === 'development' ||
      window.IS_INTEGRATION_TEST_ENVIRONMENT
    ) {
      return;
    }
    const isLogEnabled = getCookie(LOG_PWA_WORKER);
    if ('serviceWorker' in navigator) {
      if (navigator.serviceWorker.controller) {
        isLogEnabled &&
          console.log(
            'This page is currently controlled by:',
            navigator.serviceWorker.controller,
          );
      } else {
        const url = STATIC_FILES.PWA_FETCH;
        navigator.serviceWorker
          .register(url)
          .then((registration) => {
            isLogEnabled &&
              console.log(
                'Service worker registration succeeded:',
                registration,
              );
          })
          .catch((error) => {
            console.error('Error during service worker registration:', error);
          });
      }

      if (isLogEnabled) {
        navigator.serviceWorker.oncontrollerchange = () => {
          console.log(
            'This page is now controlled by',
            navigator.serviceWorker.controller,
          );
        };
      }
    }
  }, []);
};

export default ProgressiveWebApplicationWorker;
