import ROUTES from '@core/application/constants/routes';
import getHistory from '@core/application/utils/getHistory';
import type {
  PaymentAnswer,
  PaymentAnswerAbortable,
} from '@core/payment/payProcess/types/paymentAnswerProps';
import {PAYMENT_FLOW_ABORT} from '@core/payment/payProcess/utils/getProcessPaymentFlow';
import {MICROFEATURES_VIA} from '@core/payment/common/constants/vias';
import PAYMENT_METHODS from '@core/payment/common/constants/paymentMethods';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';

import {MICROFEATURES} from '@phoenix/microfeatures/common/constants/microfeatures';

const goToNextStepOnDeclineAfterMicroFeaturesPayment = (
  paymentAnswer: PaymentAnswer,
): PaymentAnswerAbortable => {
  const {action, status, failPageLogic, prevVia, stockId} = paymentAnswer;

  if (!status) {
    getHistory().push(
      generatePayUrl({
        stage: action,
        urlParams: {
          prevVia,
          viaMethod: PAYMENT_METHODS.CARD,
          stockId,
          returnPath: window.location.pathname,
          skipStepOnBack: false,
          via: failPageLogic || MICROFEATURES_VIA,
          ...(prevVia === MICROFEATURES.TELEGRAM_CONVERSATION
            ? {returnPath: ROUTES.SEARCH, skipStepOnBack: true}
            : null),
        },
      }),
    );

    return PAYMENT_FLOW_ABORT;
  }

  return paymentAnswer;
};

export default goToNextStepOnDeclineAfterMicroFeaturesPayment;
