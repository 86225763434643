import React from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';

import usePaymentParams from '@core/payment/common/utils/usePaymentParams';

import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';
import {PlaceholderBar} from '@phoenix/ui';
import {TextMuted} from '@phoenix/typography';
import {Align} from '@phoenix/ui/constants';

import FooterRow from '../components/FooterRow';
import GUARANTEES_AND_RETURN_STATEMENT_QUERY from '../graphql/queries/guaranteesAndReturnStatement.gql';

/**
 * @param {String} stage This stub is only for backbone PP, it will be removed with backbone PP
 *                       usePaymentParams::action doesn't work correctly for alt methods
 */
const PaymentFooterGuaranteesAndReturnsStatement = ({stage}) => {
  const {action} = usePaymentParams();
  const {data, loading, error} = useQuery(
    GUARANTEES_AND_RETURN_STATEMENT_QUERY,
    {
      variables: {
        action: stage || action,
      },
    },
  );

  if (error) {
    return (
      <FooterRow>
        <ErrorBoundary />
      </FooterRow>
    );
  }

  const guaranteesAndReturnsStatement =
    data?.payment?.paymentFooter?.blockDataMap?.guaranteesAndReturnsStatement;

  if (!loading && !guaranteesAndReturnsStatement) {
    return null;
  }

  return (
    <FooterRow align={Align.CENTER}>
      {loading ? (
        <PlaceholderBar />
      ) : (
        <TextMuted data-test="guarantees" small>
          {guaranteesAndReturnsStatement}
        </TextMuted>
      )}
    </FooterRow>
  );
};

PaymentFooterGuaranteesAndReturnsStatement.propTypes /* remove-proptypes */ = {
  stage: PropTypes.string,
};

PaymentFooterGuaranteesAndReturnsStatement.defaultProps = {
  stage: null,
};

export default PaymentFooterGuaranteesAndReturnsStatement;
