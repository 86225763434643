import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import LOGO_SIZE from '@core/logo/constants/logoSize';
import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import {Align} from '@phoenix/ui/constants';

import css from '../styles/LogoLayout.css';

const LogoLayout = ({
  className,
  alt,
  src,
  clickable,
  fullWidth,
  align,
  size,
  onLoad,
  onClick,
  ...props
}) => (
  <div className={cn(css.logo, css[size], css[align], className)}>
    <AddBabciaUBTracking trackingName={onClick ? 'siteLogo' : null}>
      <img
        className={cn(
          css.image,
          clickable && css.clickable,
          fullWidth && css.fullWidth,
        )}
        data-test="logoImage"
        alt={alt}
        src={src}
        onLoad={onLoad}
        onClick={onClick}
        role="presentation"
        {...props}
      />
    </AddBabciaUBTracking>
  </div>
);

LogoLayout.propTypes /* remove-proptypes */ = {
  className: PropTypes.string,
  alt: PropTypes.string,
  onLoad: PropTypes.func,
  onClick: PropTypes.func,
  src: PropTypes.string.isRequired,
  /**
   * @deprecated
   * Should be removed. Use alignment from parent instead.
   */
  align: PropTypes.oneOf(Object.values(Align)),
  clickable: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(LOGO_SIZE)),
  fullWidth: PropTypes.bool,
};

LogoLayout.defaultProps = {
  align: Align.LEFT,
  clickable: false,
  size: LOGO_SIZE.NORMAL,
  fullWidth: false,
};

export default LogoLayout;
