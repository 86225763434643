import defer from 'lodash/defer';

import logger from '@core/logger';
import {getClientInstance} from '@core/graphql/client';
import activePackageVar from '@core/graphql/vars/activePackageVar';
import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';
import resetPaymentPackagesCache from '@core/payment/common/utils/resetPaymentPackagesCache';

import updateCachesAfterPayment from '../../utils/updateCachesAfterPayment';
import updateIsOneClickAllowedAfterPayment from './updateIsOneClickAllowedAfterPayment';

const updateCacheAfterPayment = (paymentAnswer) => {
  if (paymentAnswer.replaceRedirectToPostMessage && paymentAnswer.status) {
    return paymentAnswer;
  }

  const client = getClientInstance();

  // Clear cache after payment
  updateIsOneClickAllowedAfterPayment(paymentAnswer);

  invalidateCacheByTypename(client, 'UserFeatures', 'paymentLastOrder');

  if (paymentAnswer.status) {
    updateCachesAfterPayment().catch((error) => {
      logger.sendError(`[updateCacheAfterPayment] Error is ${error}`);
    });

    /**
     * This solution need only for success payment with redirect to next step.
     * Need clear cache asynchronously after change payment route for prevent
     * query re-fetch if cache is empty.
     * useQuery unmounts, but it does not unsubscribe immediately.
     * Wait some time to avoid re-fetch.
     */
    defer(() => {
      client.cache.evict({
        id: 'Payment:{}',
        fieldName: 'stepFlow',
        broadcast: false,
      });

      client.cache.evict({
        id: 'Payment:{}',
        fieldName: 'longFormFields',
        broadcast: false,
      });
    });

    resetPaymentPackagesCache(false);

    activePackageVar(null);
  } else {
    client.cache.evict({
      id: 'Payment:{}',
      fieldName: 'longFormFields',
    });
  }

  return paymentAnswer;
};

export default updateCacheAfterPayment;
