import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

import logger from '@core/logger';

/**
 * @param {String} name
 * @param {Array.<Object>} dictionary
 */
const findMachineName = (name, dictionary) => {
  const result = dictionary.find((entry) => entry.name === name);
  return result ? result.key : null;
};

/**
 * Transform user readable data to machine names that should be sent to remote server
 * @param {Array.<Object>} data
 * @param {Object} dictionaries
 * @param {String} userId
 * @returns {Array.<Object> | null}
 */
const prepareInventoryDataBeforeSend = (data, dictionaries, userId) => {
  const convertedData = data.reduce((accumulator, entry) => {
    const {placement, anchorName, event, promo, promocode, ...other} = entry;

    // Convert track to readable format for tracking engine
    const track = {
      placementId: findMachineName(placement, dictionaries.placements),
      anchorId: findMachineName(anchorName, dictionaries.anchors),
      actionId: findMachineName(event, dictionaries.events),
      promo,
      promocode,
      uid: userId,
      ...other,
    };

    // If we can't convert value - we should exclude this entry from tracking
    if (!track.placementId || !track.anchorId || !track.actionId) {
      logger.sendError(
        `[prepareInventoryDataBeforeSend] Track was exclueded from sending. Can't convert track data "${JSON.stringify(
          entry,
        )}" to readable format based on dictionary.`,
      );
      return accumulator;
    }

    // Exlucde 'count' as value that is added after
    const index = accumulator.findIndex((storedTrack) =>
      isEqual(omit(storedTrack, ['count']), track),
    );

    // Or add new entry, or increase counter of existing entry
    if (index === -1) {
      accumulator.push({
        ...track,
        count: 1,
      });
    } else {
      accumulator[index].count += 1;
    }

    return accumulator;
  }, []);

  // If all data isn't valid - we return nothing.
  return convertedData.length ? convertedData : null;
};

export default prepareInventoryDataBeforeSend;
