/* eslint-disable no-underscore-dangle */

import {Subject} from 'rxjs';
import isEmpty from 'lodash/isEmpty';

import logger from '@core/logger';
import {getClientInstance} from '@core/graphql/client';
import BOOTSTRAP_QUERY from '@core/application/graphql/queries/bootstrap.gql';

import isAllowedByRoute from './isAllowedByRoute';
import getInventoryTrackingObservable from './getInventoryTrackingObservable';
import prepareInventoryDataBeforeSend from './prepareInventoryDataBeforeSend';
import INVENTORY_TRACKING_QUERY from '../graphql/queries/inventoryTracking.gql';
import TRACK_INVENTORY_MUTATION from '../graphql/mutations/trackInventory.gql';

/**
 * Be aware that there are non-obligatory data, such as
 * 'modelId' or 'userLanguage' that arrives in optional attributes field
 * @see useInventoryTracking.js
 *
 * @param {Object} data
 * @returns {Boolean}
 */
const isDataValid = (data) =>
  !isEmpty(data) &&
  data.placement &&
  data.anchorName &&
  data.promocode &&
  data.promo &&
  data.event;

/**
 * Inventory (remarketing) tracking engine as a service.
 * Collects tracks from UI components, and sends them to remote server
 * Exported for tests only (@see InventoryTrackingService.test.js)
 */
export class InventoryTracking {
  /**
   * Indicates if we can use service by itself
   * @private
   * @type {Boolean}
   */
  allowed = null;

  /**
   * Cached settings in case if inventory tracking is allowed.
   * @private
   *
   * @typedef InventoryDictionaries
   * @property {Array.<Object>} events
   * @property {Array.<Object>} anchors
   * @property {Array.<Object>} placements
   *
   * @typedef InventorySettings
   * @property {Number} countObjects - Max count before sending pack of tracks
   * @property {Number} delay - Max time how many we should wait before send pack of tracks
   * @property {InventoryDictionaries} dictionaries
   *
   * @type {InventorySettings}
   */
  settings = null;

  /**
   * Current user ID
   * @type {String}
   */
  userId = null;

  /**
   * Data source for collecting data and sending it batched to server,
   * that is piped for collecting data during some interval
   * @type {Observer}
   */
  subject = new Subject();

  /**
   * Cached subscription for possibility to unsubscribe
   * @type {Subscriber|null}
   */
  subscription = null;

  /**
   * Take in mind is we became anonymous - this value doesn't change.
   * If frontend became fully functional on anonymous zone - we should in some way change this logic.
   * We cache this flag since we call it on every track. And track can trigger too many times during scroll, etc.
   *
   * @private
   * @returns {Promise.<Object>}
   */
  async isAllowed() {
    if (!this.allowed) {
      this.allowed = getClientInstance()
        .query({query: BOOTSTRAP_QUERY})
        .then(({data}) => data.isAuthorizedZone && !data.isBanned);
    }

    return this.allowed;
  }

  /**
   * If resolved promise returns 'null' it means that functionality isn't available
   * since user is anonymous or since webcam functionality isn't allowed at this time.
   *
   * @private
   * @returns {Promise.<Object>}
   */
  async getInventoryTrackingSettings() {
    if (this.settings) {
      return this.settings;
    }

    const isAllowed = await this.isAllowed();
    if (!isAllowed) {
      this.settings = null;
      return this.settings;
    }

    this.settings = getClientInstance()
      .query({
        query: INVENTORY_TRACKING_QUERY,
      })
      .then(({data}) => {
        this.userId = data.myUser.id;
        // Can be null if functionality isn't available.
        return data.userFeatures.inventoryTracking;
      });

    return this.settings;
  }

  /**
   * @public
   * @param {Object} data
   * @param {String} data.placement
   * @param {String} data.anchorName
   * @param {String} data.promocode
   * @param {String} data.promo
   * @param {String} data.event
   * @returns {Promise.<Object>}
   */
  async track(data) {
    if (!isDataValid(data)) {
      logger.sendError(
        `[InventoryTrackingService] Can't perform track with such data: ${JSON.stringify(
          data,
        )}.`,
      );
      return;
    }

    if (!isAllowedByRoute()) {
      return;
    }

    const settings = await this.getInventoryTrackingSettings();

    if (!settings) {
      return;
    }

    // If data doesn't exist, or we are already unsubscribed from it
    if (!this.subscription || this.subscription.closed) {
      const {countObjects, delay} = settings;

      this.subscription = getInventoryTrackingObservable(
        this.subject,
        countObjects,
        delay,
      ).subscribe((result) => {
        const preparedData = prepareInventoryDataBeforeSend(
          result,
          settings.dictionaries,
          this.userId,
        );

        // Avoid wasting remote data storages with possibly invalid data
        if (preparedData) {
          getClientInstance().mutate({
            mutation: TRACK_INVENTORY_MUTATION,
            variables: {
              data: preparedData,
            },
          });
        }

        // Unsubscribe since we don't need every 100ms make checks if we can send something or not.
        this.subscription.unsubscribe();
      });
    }

    this.subject.next(data);
  }
}

const InventoryTrackingService = new InventoryTracking();
export default InventoryTrackingService;
