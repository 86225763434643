import {Subject} from 'rxjs';

import {getClientInstance} from '@core/graphql/client';
import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';
import clearSearchBannersCache from '@core/graphql/utils/clearSearchBannersCache';
import {writeToSessionStorage} from '@core/utils/storage';

import {
  RECIPIENT_ID_EMPTY_DIALOG_WHERE_FREE_MESSAGES_ENDED,
  RECIPIENT_ID_WHERE_FREE_MESSAGES_ENDED,
} from '@phoenix/messenger/constants/storageKeys';
import clearReelsCache from '@phoenix/reels/utils/clearReelsCache';
import isEnabledPmaRequestPhoto from '@phoenix/user/photo/utils/isEnabledPmaRequestPhoto';

import DETAILS_AFTER_PAYMENT_QUERY from '../graphql/queries/detailsAfterPayment.gql';
import updateMicroFeaturesData from './updateMicroFeaturesData';
import invalidateCacheIsProfileVideoUploadDisabled from './invalidateCacheIsProfileVideoUploadDisabled';

/**
 * Is updating data after payment Observable.
 * Use @apollo/client features instead.
 * @deprecated
 */
export const isUpdating$ = new Subject();

let updating = false;

/**
 * Is updating data after payment.
 * @deprecated
 * @return {boolean}
 */
export const isUpdating = () => updating;

/**
 * @param {boolean} value
 */
const setUpdating = (value) => {
  updating = value;
  isUpdating$.next(value);
};

/**
 * Updates payment-dependent data.
 * @param {boolean} triggerUpdate
 * @param {Array<string>} invalidateArgs - arguments for invalidateCacheByTypename function.
 * @returns {Promise}
 */
const updateCachesAfterPayment = (triggerUpdate = true, invalidateArgs) => {
  const client = getClientInstance();

  if (triggerUpdate) {
    setUpdating(true);
  }

  const result = client
    .query({
      query: DETAILS_AFTER_PAYMENT_QUERY,
      fetchPolicy: 'network-only',
    })
    .then(() => {
      setUpdating(false);
    });

  /**
   * Remove all possible users from cache due to trusted functionality.
   * The main problem is, that we can't send real 'trustedStatus' of users
   * from server and take this status unchanged since evil users can
   * identify trusted users and push them with fraud messages.
   *
   * This, trusted flag, is always 'false' if you are not trusted. When you
   * became trusted (via payment) we should update this flag.
   */
  invalidateCacheByTypename(client, 'UserData', 'viewPermissions');
  invalidateCacheByTypename(client, 'UserData', 'messagePermissions');

  // Messenger
  invalidateCacheByTypename(client, 'Messenger');
  invalidateCacheByTypename(client, 'Message');
  invalidateCacheByTypename(client, 'Recipient');
  invalidateCacheByTypename(client, 'MessengerData', 'recipients');
  invalidateCacheByTypename(
    client,
    'GlobalFreeMessages',
    'freeMessagesTimeout',
  );

  updateMicroFeaturesData();

  writeToSessionStorage(RECIPIENT_ID_WHERE_FREE_MESSAGES_ENDED, null, true);
  writeToSessionStorage(
    RECIPIENT_ID_EMPTY_DIALOG_WHERE_FREE_MESSAGES_ENDED,
    null,
    true,
  );

  // not PP pages
  invalidateCacheByTypename(client, 'Search', 'settings');
  invalidateCacheByTypename(client, 'Search', 'features');
  invalidateCacheByTypename(client, 'Search', 'users');
  invalidateCacheByTypename(client, 'searchWithMap', 'hasFreeTrialAvailable');
  invalidateCacheByTypename(client, 'ROOT_QUERY', 'billingHistory');
  invalidateCacheByTypename(client, 'ROOT_QUERY', 'remarketing');
  invalidateCacheByTypename(client, 'ROOT_QUERY', 'searchWithMap');
  invalidateCacheByTypename(client, 'Banners');
  invalidateCacheByTypename(
    client,
    'UserFeatures',
    'marketingProductConfigurationName',
  );
  invalidateCacheByTypename(client, 'ROOT_QUERY', 'gifts');

  clearSearchBannersCache(client);

  // Payment data
  invalidateCacheByTypename(client, 'Payment', 'banner');
  invalidateCacheByTypename(client, 'Payment', 'accountStatus');
  invalidateCacheByTypename(client, 'Payment', 'discountData');
  invalidateCacheByTypename(client, 'Payment', 'isUserBoughtPackage');
  invalidateCacheByTypename(client, 'AlertBanner');
  invalidateCacheByTypename(client, 'AccountStatus');
  invalidateCacheByTypename(client, 'Credits');
  invalidateCacheByTypename(client, 'MotivationBanner');

  invalidateCacheByTypename(
    client,
    'RemoveAccount',
    'isCancelBillingPageBeforeRemove',
  );

  // Clear virtual assistant cache
  invalidateCacheByTypename(client, 'UserFeatures', 'bioGeneration');
  invalidateCacheByTypename(client, 'UserFeatures', 'bioGenerationV2');
  invalidateCacheByTypename(client, 'UserFeatures', 'messageAssistant');

  invalidateCacheIsProfileVideoUploadDisabled(client);

  invalidateCacheByTypename(client, 'WorldWideSearch');

  // Clear reels data
  clearReelsCache(client);

  // Clear pma photo request cache
  if (isEnabledPmaRequestPhoto()) {
    invalidateCacheByTypename(client, 'UserFeatures', 'pmaRequestPhoto');
  }

  if (invalidateArgs) {
    invalidateCacheByTypename(client, ...invalidateArgs);
  }

  return result;
};

export default updateCachesAfterPayment;
