import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import PaymentUrl from '@core/payment/banners/paymentUrl/containers/PaymentUrl';
import {GenderTypeEnum, ViaEnum} from '@core/types';
import PRESET from '@core/user/photo/constants/PhotoPreset';
import SIZE from '@core/user/photo/constants/PhotoSize';
import LEVEL from '@core/user/photo/constants/PhotoLevel';
import MEDIA_ITEMS_GRID from '@core/user/profile/common/constants/MediaItemsGrid';
import photoPropType from '@core/user/profile/common/utils/photoPropType';

import PhotoGoChattingPreviewBlurred from '@phoenix/user/photo/components/PhotoGoChattingPreviewBlurred';

import MediaWrapper from '../components/MediaWrapper';
import PhotoMenu from '../components/PhotoMenu';
import PhotoItem from '../components/PhotoItem';
import openPhotoGallery from '../utils/openPhotoGallery';
import openMediaGallery from '../utils/openMediaGallery';
import openOnlyFirstVideoRenderer from '../../utils/openOnlyFirstVideoRenderer';

const PhotoListInner = ({
  photos,
  userId,
  withMenu,
  my,
  grid,
  gender,
  size,
  onClick,
  mediaCounter,
  mustPayToView,
  needSendFirst,
  paymentUrl,
  withShadow,
  primaryPhotoId,
  joinedMediaList,
  withLockOverlay,
  showMore,
  needGTMTrack,
  forceLevel,
  withActions,
  openProfileFirstVideoId,
  customOverlay,
}) => {
  const history = useHistory();

  const handleClick = useCallback(
    (photoId) => () => {
      if (mustPayToView) {
        history.push(paymentUrl);
        return;
      }
      if (joinedMediaList) {
        openMediaGallery({
          showLoadingBackground: false,
          userId,
          my,
          needGTMTrack,
          withShadow,
          mediaId: photoId,
          ...(openProfileFirstVideoId
            ? {
                videoRenderer: (props) =>
                  openOnlyFirstVideoRenderer({
                    processPaidVideoView: () => history.push(paymentUrl),
                    openProfileFirstVideoId,
                    forceLevel,
                    userId,
                    my,
                    ...props,
                  }),
              }
            : {}),
        });
      } else {
        openPhotoGallery({
          showLoadingBackground: false,
          userId,
          photoId,
          withShadow,
          my,
          needGTMTrack,
        });
      }
    },
    [
      mustPayToView,
      joinedMediaList,
      history,
      paymentUrl,
      userId,
      my,
      needGTMTrack,
      withShadow,
      openProfileFirstVideoId,
      forceLevel,
    ],
  );

  return photos.map((photo, i, {length}) => {
    const lastItem = i + 1 === length;
    const lastWithShowMore = showMore && lastItem;

    if (needSendFirst && !lastWithShowMore) {
      return (
        <MediaWrapper key={photo.id} grid={grid}>
          <PhotoGoChattingPreviewBlurred
            photo={photo}
            userId={userId}
            withShadow={false}
          />
        </MediaWrapper>
      );
    }

    let onClickHandler = () => {};
    if (withActions) {
      onClickHandler =
        lastWithShowMore && !mustPayToView ? showMore : handleClick(photo.id);
    }

    return (
      <PhotoItem
        onNaughtyModeClick={withActions ? onClick : null}
        key={photo.id}
        size={size}
        userId={userId}
        withNaughtyMode={!mustPayToView}
        withLockOverlay={!lastWithShowMore && withLockOverlay}
        /* We must show counter only on last item if mediaCounter is true by counting iteration */
        mediaCounter={mediaCounter && lastItem ? mediaCounter : 0}
        showOverlay={!mustPayToView || photo.level > LEVEL.NORMAL}
        photo={{
          id: photo.id,
          gender,
          level: lastWithShowMore ? LEVEL.NORMAL : photo.level,
          forceLevel:
            lastWithShowMore || mustPayToView ? forceLevel : LEVEL.NORMAL,
          censored: photo.censored,
          updatedOn: photo.updatedOn,
          createdOn: photo.createdOn,
          preset: PRESET.AVATAR,
          pendingDelete: photo.pendingDelete,
          privateAttributes: lastWithShowMore ? null : photo.privateAttributes,
        }}
        my={my}
        // eslint-disable-next-line local-rules/tracking-name -- has tracking inside PhotoLayout
        onClick={onClickHandler}
        menu={
          withMenu && (
            <PhotoMenu
              photoId={photo.id}
              isPrimary={photo.id === primaryPhotoId}
            />
          )
        }
        customOverlay={customOverlay}
        grid={grid}
        withShadow={withShadow}
      />
    );
  });
};

const PhotoList = ({
  photos,
  primaryPhotoId,
  userId,
  mediaCounter,
  mustPayToView,
  isWebcamProfile,
  openProfileFirstVideoId,
  ...restProps
}) => {
  if (!photos.length) return null;

  const props = {
    ...restProps,
    userId,
    photos,
    mediaCounter,
    mustPayToView,
    primaryPhotoId,
    openProfileFirstVideoId,
  };

  return mustPayToView || openProfileFirstVideoId ? (
    <PaymentUrl
      id={userId}
      via={isWebcamProfile ? ViaEnum.photo_more_wc : ViaEnum.photo_more}
    >
      {({paymentUrl}) => <PhotoListInner paymentUrl={paymentUrl} {...props} />}
    </PaymentUrl>
  ) : (
    <PhotoListInner {...props} />
  );
};

PhotoList.propTypes /* remove-proptypes */ = {
  photos: PropTypes.arrayOf(photoPropType.isRequired),
  forceLevel: PropTypes.oneOf(Object.values(LEVEL)),
  primaryPhotoId: PropTypes.string,
  gender: PropTypes.oneOf(Object.values(GenderTypeEnum)).isRequired,
  userId: PropTypes.string.isRequired,
  isWebcamProfile: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZE)),
  mediaCounter: PropTypes.number,
  needSendFirst: PropTypes.bool,
  mustPayToView: PropTypes.bool,
  withMenu: PropTypes.bool,
  my: PropTypes.bool,
  grid: PropTypes.oneOf(Object.values(MEDIA_ITEMS_GRID)),
  withShadow: PropTypes.bool,
  joinedMediaList: PropTypes.bool,
  onClick: PropTypes.func,
  needGTMTrack: PropTypes.bool,
  withActions: PropTypes.bool,
  openProfileFirstVideoId: PropTypes.string, // Open only first video in "open profile" (maintenance.20250129.voloshin.TN_213342)
  customOverlay: PropTypes.node,
};

PhotoList.defaultProps = {
  size: SIZE.NORMAL,
  withMenu: false,
  onClick: () => {},
  my: false,
  needSendFirst: false,
  mediaCounter: 0,
  mustPayToView: false,
  withActions: true,
  joinedMediaList: false,
  grid: MEDIA_ITEMS_GRID.FOUR_COLUMNS,
  withShadow: true,
  primaryPhotoId: null,
  photos: [],
  needGTMTrack: false,
  forceLevel: LEVEL.HIGHLY_BLURRED,
};

export default PhotoList;
