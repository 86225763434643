import getHistory from '@core/application/utils/getHistory';
import activePackageVar from '@core/graphql/vars/activePackageVar';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import cachedPurchasedPackage from '@core/payment/common/utils/cachedPurchasedPackage';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import PaymentPageSuccessOrders from '@core/payment/payProcess/utils/PaymentPageSuccessOrders';
import PopupService from '@core/popup/utils/PopupService';

import openCoinsSuccessPopup from '@phoenix/coins/utils/openCoinsSuccessPopup';
import updateShowCoinsBuyOfferBanner from '@phoenix/messenger/senderForm/utils/updateShowCoinsBuyOfferBanner';

const getCoinsBuyOfferStepsAfterPayment = (paymentAnswer) => {
  PopupService.closePopup();
  const activePackage = activePackageVar();
  PaymentPageSuccessOrders.clear();

  if (paymentAnswer.failPageLogic) {
    // When we got Decline we redirect to PP and reset Cached package
    const purchasedPackage = cachedPurchasedPackage.get();
    getHistory().push(
      generatePayUrl({
        stage: PAYMENT_ACTIONS.CREDIT,
        urlParams: {
          via: paymentAnswer.failPageLogic,
          prevVia: paymentAnswer.via,
          stockId: purchasedPackage.stockId,
        },
      }),
    );
  }

  if (paymentAnswer.status) {
    updateShowCoinsBuyOfferBanner(false);
    openCoinsSuccessPopup({
      packages: [paymentAnswer.boughtPackageTitle || activePackage.title],
    });
  }
};

export default getCoinsBuyOfferStepsAfterPayment;
