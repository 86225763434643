import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {motion, AnimatePresence} from 'framer-motion';

import logger from '@core/logger';
import getAnimationTime from '@core/utils/animation/utils/getAnimationTime';

import ANIMATE_PRESENCE_MODE from './constants/animatePresenceMode';
import css from './AnimatedSwitchable.css';

/**
 * @const {number}
 */
const RISE_DOWN_ANIMATION_DISTANCE = 20;

/**
 * Component for animating mount/unmount of inner content.
 */
const SwitcherAnimation = ({
  children,
  switched,
  shiftOnAnimation,
  duration,
  mode,
  ...props
}) => {
  let mountRiseAnimation = 0;
  let unmountDownAnimation = 0;

  const positionDuringAnimation =
    mode === ANIMATE_PRESENCE_MODE.WAIT ? 'static' : 'absolute';

  if (shiftOnAnimation) {
    mountRiseAnimation = switched ? 0 : RISE_DOWN_ANIMATION_DISTANCE;
    unmountDownAnimation = switched ? 0 : RISE_DOWN_ANIMATION_DISTANCE;
  }

  return (
    <motion.div
      {...props}
      variants={{
        enter: {
          opacity: 0,
          position: positionDuringAnimation,
          y: mountRiseAnimation,
        },
        center: {
          opacity: 1,
          position: 'static',
          y: 0,
        },
        exit: {
          opacity: 0,
          position: positionDuringAnimation,
          y: unmountDownAnimation,
        },
      }}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{duration: getAnimationTime({duration})}}
    >
      {children}
    </motion.div>
  );
};

SwitcherAnimation.propTypes /* remove-proptypes */ = {
  children: PropTypes.node,
  switched: PropTypes.bool,
  shiftOnAnimation: PropTypes.bool,
  duration: PropTypes.number,
  mode: PropTypes.oneOf(Object.values(ANIMATE_PRESENCE_MODE)),
};

/**
 * Animated switching layout component.
 * Be aware that without KEY passed to children it wouldn't work.
 */
const AnimatedSwitchable = ({
  switched,
  children,
  shiftOnAnimation,
  duration,
  mode,
  onAnimationComplete,
  className,
}) => {
  useEffect(() => {
    if (!children.key) {
      logger.sendError(
        '[AnimatedSwitchable] You are using children component without "key" prop. Without it animation will break.',
      );
    }
  }, [children]);

  return (
    <AnimatePresence initial={false} mode={mode}>
      <SwitcherAnimation
        className={cn(css.wrap, className)}
        key={children.key}
        switched={switched}
        shiftOnAnimation={shiftOnAnimation}
        duration={duration}
        mode={mode}
        onAnimationComplete={onAnimationComplete}
      >
        <div className={css.proxy}>{children}</div>
      </SwitcherAnimation>
    </AnimatePresence>
  );
};

AnimatedSwitchable.MODE = ANIMATE_PRESENCE_MODE;

AnimatedSwitchable.propTypes /* remove-proptypes */ = {
  children: PropTypes.element.isRequired,
  /**
   * Set 'true' if you have animation between 2 component forward and backward.
   * So if you toggle back - it play reverse animation.
   * If 'false' - on switch will be applied only one animation.
   */
  switched: PropTypes.bool,
  className: PropTypes.string,
  /**
   * If true - when content mounts\unmounts it will move down from top (unmount) or up from bottom (mount)
   */
  shiftOnAnimation: PropTypes.bool,
  /**
   * Animation duration, in seconds.
   */
  duration: PropTypes.number,
  mode: PropTypes.oneOf(Object.values(AnimatedSwitchable.MODE)),
  onAnimationComplete: PropTypes.func,
};

AnimatedSwitchable.defaultProps = {
  shiftOnAnimation: true,
  mode: AnimatedSwitchable.MODE.WAIT,
};

export default AnimatedSwitchable;
