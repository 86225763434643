import {from} from 'rxjs';
import once from 'lodash/once';
import {filter} from 'rxjs/operators';

import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import isPayUrl from '@core/utils/url/isPayUrl';
import addLinkNode from '@core/application/utils/addLinkNode';

const startProgressiveWebApplicationListener = once((client) => {
  from(client.watchQuery({query: ROUTE_QUERY}))
    .pipe(
      filter(({data}) => {
        return !isPayUrl(data.route.current);
      }),
    )
    .subscribe(() => {
      addLinkNode({id: 'pwaManifest', url: '/manifest.json', rel: 'manifest'});
    });
});

export default startProgressiveWebApplicationListener;
