import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {useHistory} from 'react-router-dom';

import {TOOLBAR_ID} from '@core/header/constants/header';

import {ButtonFlat, Spacing, Container} from '@phoenix/ui';
import {Align, SpacingSize, SpacingDirection} from '@phoenix/ui/constants';

import css from '../styles/HeaderToolbarLayout.css';

const HeaderToolbarLayout = ({
  backButtonPosition,
  showBackButton,
  showSideRight,
  showSideLeft,
  children,
  spacingSize,
  onBackClick,
  inverse,
  muted,
  fixedHeight,
  spacingDirection,
  transparent,
}) => {
  const history = useHistory();

  const handleBackClick = useCallback(() => {
    if (onBackClick) {
      onBackClick();
    } else {
      history.goBack();
    }
  }, [onBackClick, history]);

  return (
    <div
      id={TOOLBAR_ID}
      data-test="headerToolbarLayout"
      className={cn(
        css.wrapper,
        inverse && css.inverse,
        transparent && css.transparent,
        fixedHeight && css.fixedHeight,
      )}
    >
      <Container unspaced className={css.container}>
        <Spacing
          direction={spacingDirection}
          size={spacingSize}
          className={cn(css.toolbar, !showBackButton && css.center)}
        >
          {showSideLeft &&
            (!showBackButton || backButtonPosition !== Align.LEFT) && (
              <div data-test="sideLeft" className={css.sideLeft} />
            )}

          {showBackButton && (
            <div className={cn(css.action, css[`action${backButtonPosition}`])}>
              <ButtonFlat
                round
                icon={
                  backButtonPosition === Align.LEFT ? 'arrow-left' : 'cross'
                }
                data-test="backButton"
                onClick={handleBackClick}
                inverse={inverse}
                muted={muted}
                trackingName="back"
              />
            </div>
          )}
          {children && <div className={css.content}>{children}</div>}
          {showSideRight && showBackButton && (
            <div data-test="sideRight" className={css.sideRight} />
          )}
        </Spacing>
      </Container>
    </div>
  );
};

HeaderToolbarLayout.propTypes /* remove-proptypes */ = {
  backButtonPosition: PropTypes.oneOf(Object.values(Align)),
  showBackButton: PropTypes.bool,
  showSideRight: PropTypes.bool,
  showSideLeft: PropTypes.bool,
  children: PropTypes.node,
  inverse: PropTypes.bool,
  muted: PropTypes.bool,
  /**
   * It's used for customize back button behavior,
   * if we want to override goBack history call which is used by default
   */
  onBackClick: PropTypes.func,
  spacingSize: PropTypes.oneOf(Object.values(SpacingSize)),
  spacingDirection: PropTypes.oneOf(Object.values(SpacingDirection)),
  fixedHeight: PropTypes.bool,
  transparent: PropTypes.bool,
};

HeaderToolbarLayout.defaultProps = {
  backButtonPosition: Align.LEFT,
  spacingDirection: SpacingDirection.HORIZONTAL,
  fixedHeight: true,
  showSideLeft: false,
  showSideRight: false,
  transparent: false,
};

export default HeaderToolbarLayout;
