import PaymentScenario from '@core/payment/common/constants/paymentScenario';
import type {
  PaymentAnswer,
  PaymentDataProps,
} from '@core/payment/payProcess/types/paymentAnswerProps';
import payByMethod from '@core/payment/payProcess/utils/payByMethod';
import addPaymentData from '@core/payment/payProcess/paymentFlow/methods/addPaymentData';

const paypalPay = async (
  paymentData: PaymentDataProps,
): Promise<PaymentAnswer> => {
  const {
    action,
    via,
    activePackage,
    country,
    siteName,
    userId,
    locale,
    method,
    returnPath,
    selectedAdditionalPackages,
    scenario,
  } = paymentData;

  const payScenario = scenario || PaymentScenario.INITIAL;

  /**
   * @TODO: Think about add 'addPaymentData' method to 'getPayPalFlow' util,
   *        after removing Backbone.
   */
  const data = await addPaymentData({
    action,
    activePackage,
    method,
    selectedAdditionalPackages,
    returnPath,
    via,
  });

  const result = await payByMethod({
    ...data,
    action,
    country,
    currencyCode: activePackage.tokenPrice.currencyCode,
    domain: siteName,
    hidePaymentForm: 1,
    locale,
    scenario: payScenario,
    userId,
  });

  if (
    payScenario === PaymentScenario.INITIAL &&
    // @ts-expect-error - orderId is not required
    result.orderId &&
    // @ts-expect-error - url is not required
    result.url
  ) {
    return {
      ...result,
      method,
      /**
       * @see continueExternalPaymentInAnotherWindow
       */
      isAllowedRedirect: true,
    };
  }

  return {
    ...result,
    method,
  };
};

export default paypalPay;
