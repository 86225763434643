import type {SyntheticEvent} from 'react';
import {useEffect, useRef, useCallback} from 'react';
import type {InViewHookResponse} from 'react-intersection-observer';
import {useInView} from 'react-intersection-observer';
import {useLocation} from 'react-router-dom';

import type {Placement} from '@core/types';

import InventoryTrackingService from './InventoryTrackingService';
import isAllowedByRoute from './isAllowedByRoute';
import EVENTS from '../constants/events';
import {WEB_CAM_PROMO} from '../constants/anchorNames';

/**
 * Two trigger points helps to implement next logic:
 * Track view when element becomes at least 70% visible.
 * Do not track again if visibility then changes in range between 10% and 100%.
 * Track again if visibility changes from <10% to >70%.
 */
const threshold = [0.1, 0.7];

type Args = [] | [SyntheticEvent];

type InventoryTrackingOptions = {
  partner: Placement['partner'];
  placement: Placement['trackingName'];
  attributes: Placement['trackingAttributes'];
  promocode: Placement['promoCode'];
  onClick: (...args: Args) => void;
};

type InventoryTrackingResult = {
  // ref - set this ref to DOM node to track view.
  ref: InViewHookResponse['ref'];
  // onClick - call it to track click
  onClick: (...args: Args) => void;
};

/**
 * Hook to use inventory tracking.
 * Set 'ref' to the DOM node, 'view' will be tracked automatically.
 * Call 'onClick' to track 'click'.
 */
export default function useInventoryTracking({
  partner,
  placement,
  attributes,
  promocode,
  onClick,
}: InventoryTrackingOptions): InventoryTrackingResult {
  const [ref, , entry] = useInView({threshold});
  const inView = Boolean(entry && entry.intersectionRatio > 0.5);
  const {pathname} = useLocation();
  const isAllowed = isAllowedByRoute(pathname);

  // useRef to simplify dependencies
  const trackingData = useRef<{
    placement: Placement['trackingName'];
    anchorName: string;
    promocode: Placement['promoCode'];
    promo: Placement['partner'];
    [key: string]: string;
  }>();
  trackingData.current = {
    placement,
    anchorName: WEB_CAM_PROMO,
    promocode,
    promo: partner,
    /**
     * Attributes is an array of objects of pairs name-value that should be converted
     * to a plain object with key-value.
     */
    ...(attributes
      ? attributes.reduce((accumulator, current) => {
          accumulator[current.name] = current.value;
          return accumulator;
        }, {})
      : {}),
  };

  /**
   * In case when we need change placement tracking data dynamically
   */
  const dataSnapshot = `${partner || ''}${placement || ''}${promocode || ''}`;

  useEffect(() => {
    if (isAllowed && inView && dataSnapshot) {
      InventoryTrackingService.track({
        ...trackingData.current,
        event: EVENTS.VIEW,
      });
    }
  }, [isAllowed, inView, dataSnapshot]);

  const handleClick = useCallback(
    (...args: Args) => {
      InventoryTrackingService.track({
        ...trackingData.current,
        event: EVENTS.CLICK,
      });

      onClick && onClick(...args);
    },
    [onClick],
  );

  return {ref, onClick: handleClick};
}
