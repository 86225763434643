import type {PaymentAnswer} from '@core/payment/payProcess/types/paymentAnswerProps';
import fetchData from '@core/application/utils/fetchData';

const remarketingPopupCancelSubscription = async (
  paymentAnswer: PaymentAnswer,
): Promise<PaymentAnswer> => {
  const {orderId, cancelRemarketingSubscription} = paymentAnswer;

  if (cancelRemarketingSubscription && orderId) {
    // Need wait cancel request before redirect to payment page
    await fetchData({
      url: '/api/v1/remarketingPopup/cancel',
      body: {orderId},
    });
  }

  return paymentAnswer;
};

export default remarketingPopupCancelSubscription;
