import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import ColorScheme from '@core/theming/constants/ColorScheme';

import {Icon} from '@phoenix/ui';
import useThemeColorScheme from '@phoenix/theming/utils/useThemeColorScheme';

import {MICROFEATURES} from '../../common/constants/microfeatures';
import {ICONS} from '../constants/icons';
import MicrofeaturesIconLayout from './MicrofeaturesIconLayout';
import css from '../styles/MicrofeaturesIconIncognito.css';

const MicrofeaturesIconIncognito = ({withAnimation, active, ...props}) => {
  const colorScheme = useThemeColorScheme();

  return (
    <MicrofeaturesIconLayout
      icon={
        <Icon
          className={cn(withAnimation && css.animation)}
          type={ICONS[MICROFEATURES.MICRO_INCOGNITO]}
          inherit={!active}
        />
      }
      type={MicrofeaturesIconLayout.TYPE.HEADER_COLOR}
      active={active}
      inverse={colorScheme === ColorScheme.DARK}
      {...props}
    />
  );
};

MicrofeaturesIconIncognito.SIZE = MicrofeaturesIconLayout.SIZE;

MicrofeaturesIconIncognito.propTypes /* remove-proptypes */ = {
  withAnimation: PropTypes.bool,
  active: PropTypes.bool,
};

MicrofeaturesIconIncognito.defaultProps = {
  withAnimation: true,
  active: false,
};

export default MicrofeaturesIconIncognito;
