import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {Card, Container, Button} from '@phoenix/ui';
import PopupFrame from '@phoenix/popup/components/PopupFrame';

import css from '../styles/StaticPageLayout.css';

const StaticPageLayoutWrapper = ({
  children,
  isPopup,
  isMobAppearance,
  onCloseButtonClick,
}) => {
  if (isPopup) {
    return (
      <PopupFrame
        spacedAbove
        content={children}
        spacedContent
        actions={
          <Button
            onClick={onCloseButtonClick}
            trackingName="closePopup"
            fullWidth={isMobAppearance}
            className={isMobAppearance ? '' : css.close}
          >
            {t('popupStaticPage', 'button.close')}
          </Button>
        }
      />
    );
  }

  const ContentWrapper = isMobAppearance ? 'div' : Card;

  return (
    <div className={css.wrap}>
      <Container>
        <ContentWrapper>{children}</ContentWrapper>
      </Container>
    </div>
  );
};

StaticPageLayoutWrapper.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  onCloseButtonClick: PropTypes.func,
  isMobAppearance: PropTypes.bool,
  isPopup: PropTypes.bool,
};

StaticPageLayoutWrapper.defaultProps = {
  isPopup: false,
  isMobAppearance: false,
  onCloseButtonClick: () => {},
};

export default StaticPageLayoutWrapper;
