import BaseTracker from '@core/tracking/base/BaseTracker';
import prepareData from '@core/tracking/base/prepareData';
import {RM_POPUP} from '@core/tracking/constants/identities';

import TRACK_RM_POPUP_MUTATION from './graphql/mutations/trackRmPopup.gql';

/**
 * Private instance of tracking
 * @type {null||BaseTracker}
 */
let rmPopupTracker = null;

/**
 * TRacks for remarketing popups (by websocket api)
 * @param {Object} data
 * @param {String} data.action
 * @param {String} data.popupId
 * @param {String} data.buttonTitle
 */
const trackRmPopup = ({action, popupId, buttonTitle}) => {
  if (!rmPopupTracker) {
    rmPopupTracker = new BaseTracker(
      TRACK_RM_POPUP_MUTATION,
      prepareData,
      RM_POPUP.METHOD,
    );
  }

  rmPopupTracker.checkAndTrack(
    {action, popupId, ...(buttonTitle ? {buttonTitle} : null)},
    true,
  );
};

export default trackRmPopup;
