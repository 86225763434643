import {createPath} from 'history';
import {Subject} from 'rxjs';

import {gotoUrl} from '@core/utils/url';
import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import {IN_APP_NAME} from '@core/application/constants/bootstrapParams';
import InAppBrowserName from '@core/application/constants/inAppBrowserName';
import isNeedOpenPaymentPageInNewTab from '@core/payment/common/utils/isNeedOpenPaymentPageInNewTab';
import {getClientInstance} from '@core/graphql/client';
import getHistory from '@core/application/utils/getHistory';
import PopupService from '@core/popup/utils/PopupService';
import DEFAULT_ROUTE_QUERY from '@core/utils/routing/graphql/queries/defaultRoute.gql';
import BASE_ROUTES from '@core/application/constants/routes';

import {isPopupRoute} from '@phoenix/application/utils/getPopupBaseRoute';
import ROUTES from '@phoenix/application/constants/routes';

import {READY_PAYMENT_PAGE_OPENED_IN_NEW_TAB_EVENT} from './dispatchReadyPaymentPageEvent';

export const paymentPageOpenedInNewTab$ = new Subject();

/**
 * Open payment page in new tab
 * Need open payment page in current tab if windowOpen is empty for browsers which not support call window.open in async function
 * @param nextLocation
 * @returns {Promise<*|boolean>}
 * @see paymentPageNavigationInterceptor.js
 */
const openPaymentPageInNewTab = async (nextLocation) => {
  /**
   * We must take data only from cache, because loading here,
   * we can break logic in some browsers and OS
   */
  const needOpenInNewTab = await isNeedOpenPaymentPageInNewTab('cache-only');

  if (!needOpenInNewTab) {
    return false;
  }

  let openedTab;

  if (needOpenInNewTab) {
    const url = createPath(nextLocation);

    openedTab = gotoUrl(url, true, '_blank');
    if (openedTab) {
      /**
       * Reload page when user has come from PP in inApp browsers from new window
       * (site content can be cached with requests that were canceled when user opens new tab without
       * waiting for full page load)
       */
      if (getBootstrapParam(IN_APP_NAME) !== InAppBrowserName.NORMAL_BROWSER) {
        window.addEventListener(
          'focus',
          () => {
            window.location.reload();
          },
          {
            once: true,
          },
        );
      }

      const history = getHistory();

      if (isPopupRoute(history.location.pathname)) {
        PopupService.closePopup();
        const data = getClientInstance().readQuery({
          query: DEFAULT_ROUTE_QUERY,
        });
        const defaultRoute = data?.site?.defaultRoute || BASE_ROUTES.SEARCH;
        history.replace(defaultRoute);
      } else if (history.location.pathname.startsWith(ROUTES.REMOVE_ACCOUNT)) {
        history.replace(BASE_ROUTES.ACCOUNT);
      }

      /**
       * Allow update payment data in another tabs only when payment page opened in new tab
       * @see startUpdatePaymentDataListener.js
       */
      openedTab.addEventListener(
        READY_PAYMENT_PAGE_OPENED_IN_NEW_TAB_EVENT,
        () => {
          paymentPageOpenedInNewTab$.next(true);
        },
      );
    }
  }

  return Boolean(openedTab);
};

export default openPaymentPageInNewTab;
