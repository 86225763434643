/* eslint-disable jsx-a11y/media-has-caption */
import React, {Fragment, useState, useCallback} from 'react';
import PropTypes from 'prop-types';

import createVideoPreviewUrl from '@core/user/video/utils/createVideoPreviewUrl';
import {PRESET} from '@core/user/photo/constants';

import {Overlay} from '@phoenix/ui';
import {OverlaySaturation} from '@phoenix/ui/constants';

import Video from '../components/Video';

const VideoWithMotivation = ({
  url,
  previewUrl,
  motivationAfterVideo,
  withOverlay,
  onCanPlay,
}) => {
  const [videoEnded, setVideoEnded] = useState(false);

  const handleVideoEnded = useCallback(() => {
    if (motivationAfterVideo) setVideoEnded(true);
  }, [motivationAfterVideo]);

  const video = (
    <Fragment>
      <Video
        url={url}
        controls
        previewUrl={createVideoPreviewUrl(previewUrl, PRESET.NORMAL)}
        onVideoEnded={handleVideoEnded}
        onCanPlay={onCanPlay}
      />
      {videoEnded && motivationAfterVideo}
    </Fragment>
  );

  return (
    <Fragment>
      {withOverlay ? (
        <Overlay saturation={OverlaySaturation.LITE}>{video}</Overlay>
      ) : (
        video
      )}
    </Fragment>
  );
};

VideoWithMotivation.propTypes /* remove-proptypes */ = {
  url: PropTypes.string.isRequired,
  previewUrl: PropTypes.string.isRequired,
  motivationAfterVideo: PropTypes.node,
  withOverlay: PropTypes.bool,
  onCanPlay: PropTypes.func,
};

VideoWithMotivation.defaultProps = {
  withOverlay: false,
};

export default VideoWithMotivation;
