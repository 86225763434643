/* global STATIC_FILES */
// eslint-disable-next-line max-classes-per-file
import isNull from 'lodash/isNull';

import URI from '@core/utils/url';
import getUserAgentParser from '@core/utils/getUserAgentParser';
import {getClientInstance} from '@core/graphql/client';
import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import {ENABLE_PWA} from '@core/application/constants/bootstrapParams';
import CSRF_TOKEN_QUERY from '@core/graphql/graphql/queries/csrfToken.gql';

import NOTIFICATION_EVENT from './constants/notificationEvent';
import TRACK from './constants/track';

/**
 * Possible gesture time for silent mode
 *
 * @const {number}
 */
const POSSIBLE_GESTURE_TIME = 100;

let webPush = null;

/**
 * @class WebPushService
 */
class WebPushService {
  /**
   * @param {object} options
   * @return {null|WebPushService}
   */
  static getInstance(options) {
    if (webPush) {
      webPush.setOptions(options);
      return webPush;
    }

    if (WebPushService.isSupported(options)) {
      webPush = new WebPushService(options);
    } else {
      options?.loggerEnabled && console.log('Push messaging not supported');
    }

    /**
     * Return null if native push service not allowed
     * normal course of events the service is not available in some browsers
     * this behavior does not need to be logged
     */
    return webPush;
  }

  /**
   * @param {object} options
   */
  constructor(options) {
    /**
     * Determine the blur time in order to further understand the interaction of the user with the
     * notifier in silent mode
     */
    window.addEventListener('focus', () => {
      this.blurredTime = null;
    });
    window.addEventListener('blur', () => {
      this.blurredTime = new Date().getTime();
    });

    this.options = {};
    this.setOptions(options);
    this.isSubscriptionProposed = false;
  }

  /**
   * @param {object} options
   * @return {boolean}
   */
  static isSupported(options) {
    const loggerEnabled = options && options.loggerEnabled && window.console;
    if (!('serviceWorker' in navigator)) {
      // eslint-disable-next-line no-console
      loggerEnabled &&
        console.warn("Service workers aren't supported in this browser.");
      return false;
    }

    if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
      // eslint-disable-next-line no-console
      loggerEnabled && console.warn("Notifications aren't supported.");
      return false;
    }

    if (!('PushManager' in window)) {
      // eslint-disable-next-line no-console
      loggerEnabled && console.warn("Push messaging isn't supported.");
      return false;
    }
    return true;
  }

  /**
   * Return true if user block push notification
   * @public
   * @return {boolean}
   */
  isPermissionDenied() {
    return Notification.permission === this.NOTIFICATION_PERMISSION.DENIED;
  }

  /**
   * Return true if user allow push notification
   * @public
   * @return {boolean}
   */
  isPermissionGranted() {
    return Notification.permission === this.NOTIFICATION_PERMISSION.GRANTED;
  }

  /**
   * @return {boolean}
   */
  isPermissionDefault() {
    return Notification.permission === this.NOTIFICATION_PERMISSION.DEFAULT;
  }

  get NOTIFICATION_PERMISSION() {
    return {
      DEFAULT: 'default',
      DENIED: 'denied',
      GRANTED: 'granted',
    };
  }

  /**
   * For denied track in silent mode immediately after clicking the switch in the browser window
   *
   * @private
   */
  initSilentDeniedListener() {
    window.navigator.permissions
      .query({
        name: 'notifications',
      })
      .then((permissionStatus) => {
        permissionStatus.onchange = (event) => {
          if (event.target.state === this.NOTIFICATION_PERMISSION.DENIED) {
            this.track(TRACK.ACTIONS.DENIED);
          }
        };
      });
  }

  /**
   * Track to understand that the user saw the web push notification in silent mode
   * for this we pay attention that there was no blur (the blur event did not work and did not set
   * the time, or set it longer than possible for the gesture) and not much time passed between the
   * blur and the closing event
   *
   * @private
   */
  trackSilent() {
    if (
      this.isSubscriptionProposed &&
      [
        this.NOTIFICATION_PERMISSION.DENIED,
        this.NOTIFICATION_PERMISSION.DEFAULT,
      ].includes(Notification.permission) &&
      !(
        !isNull(this.blurredTime) &&
        this.blurredTime + POSSIBLE_GESTURE_TIME > new Date().getTime()
      )
    ) {
      this.track(TRACK.ACTIONS.SILENT);
    }
  }

  /**
   * Register user or renew web PushSubscription data on server.
   * @return {Promise.<*>}
   */
  subscribe() {
    if (this.isPermissionDefault()) {
      this.isSubscriptionProposed = true;
      this.track(TRACK.ACTIONS.PROPOSED);
      this.triggerEvent(NOTIFICATION_EVENT.PROPOSED);
    }

    /**
     * Notification.requestPermission() resolves with permission param, but we don't use it
     * because of a bug: when user close native popup, Notification.permission === 'denied',
     * but instead promise resolves with permission 'default'
     */
    return Notification.requestPermission()
      .then(() => {
        if (!this.isPermissionGranted()) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject("Permission wasn't granted.");
        }

        return this.registerServiceWorker();
      })
      .then((serviceWorkerRegistration) => {
        return serviceWorkerRegistration.pushManager
          .getSubscription()
          .then((pushSubscription) => {
            if (pushSubscription instanceof PushSubscription) {
              this.log('Get subscription ', pushSubscription);
              return pushSubscription;
            }
            if (!this.subscribeFlag) {
              this.subscribeFlag = true;
              const subscribeParams = {userVisibleOnly: true};
              if (this.options.vapidPublicKey) {
                subscribeParams.applicationServerKey =
                  this.urlBase64ToUint8Array(this.options.vapidPublicKey);
              }
              return serviceWorkerRegistration.pushManager
                .subscribe(subscribeParams)
                .then((newPushSubscription) => {
                  this.log('Subscribe ', newPushSubscription);
                  return newPushSubscription;
                });
            }

            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject();
          });
      })
      .then((pushSubscription) => {
        if (this.isSubscriptionProposed) {
          this.track(
            TRACK.ACTIONS.ACCEPTED,
            this.getTrackUrlSuffix(pushSubscription),
          );
          this.triggerEvent(NOTIFICATION_EVENT.ACCEPTED);
        }

        this.initSilentDeniedListener();

        const data = this.getSubscriptionDataForSend(pushSubscription);
        return this.sendSubscriptionToServer(data);
      })
      .catch((error) => {
        this.error(error);
        this.subscribeFlag = false;

        this.trackSilent();

        if (
          this.isSubscriptionProposed &&
          Notification.permission === this.NOTIFICATION_PERMISSION.DENIED
        ) {
          this.triggerEvent(NOTIFICATION_EVENT.DECLINED);
          this.track(TRACK.ACTIONS.DENIED);
        }

        if (
          this.isSubscriptionProposed &&
          Notification.permission === this.NOTIFICATION_PERMISSION.DEFAULT
        ) {
          this.triggerEvent(NOTIFICATION_EVENT.CLOSE);
          this.track(TRACK.ACTIONS.CLOSED);
        }

        const {name, major} = getUserAgentParser().getBrowser();

        /**
         * Starting with Firefox 72, the notification permission prompt is gated behind a user gesture
         * Firefox will instantly reject the promise returned by Notification.requestPermission()
         * and PushManager.subscribe().
         * @see article https://hacks.mozilla.org/2019/11/upcoming-notification-permission-changes-in-firefox-72/
         */
        if (name === 'Firefox' && Number(major) >= 72) {
          window.navigator.permissions
            .query({name: 'notifications'})
            .then((status) => {
              const handler = () => {
                if (status.state === this.NOTIFICATION_PERMISSION.GRANTED) {
                  this.track(TRACK.ACTIONS.ACCEPTED);
                  this.triggerEvent(NOTIFICATION_EVENT.ACCEPTED);

                  /**
                   * In FF need to register service worker even if we do not accept web push notifications,
                   * because if we turn on it manually - the event "ready" will not triggered.
                   */
                  if (!navigator.serviceWorker.controller) {
                    this.registerServiceWorker();
                  }

                  navigator.serviceWorker.ready.then(
                    (serviceWorkerRegistration) => {
                      this.subscribeFlag = true;
                      const subscribeParams = {userVisibleOnly: true};
                      if (this.options.vapidPublicKey) {
                        subscribeParams.applicationServerKey =
                          this.urlBase64ToUint8Array(
                            this.options.vapidPublicKey,
                          );
                      }
                      serviceWorkerRegistration.pushManager
                        .subscribe(subscribeParams)
                        .then((pushSubscription) => {
                          this.log('Subscribe ', pushSubscription);
                          const data =
                            this.getSubscriptionDataForSend(pushSubscription);
                          return this.sendSubscriptionToServer(data);
                        })
                        .catch((pushSubscriptionError) => {
                          this.error(pushSubscriptionError);
                          this.subscribeFlag = false;
                        });
                    },
                  );
                }

                if (status.state === this.NOTIFICATION_PERMISSION.DENIED) {
                  this.track(TRACK.ACTIONS.DENIED);
                  this.triggerEvent(NOTIFICATION_EVENT.DECLINED);
                }

                status.removeEventListener('change', handler, false);
              };

              status.addEventListener('change', handler, false);
            });
        }

        return Promise.reject(error);
      })
      .finally(() => {
        this.triggerEvent(NOTIFICATION_EVENT.CHANGED, Notification.permission);
      });
  }

  /**
   * @param {object} base64String
   * @return {Uint8Array}
   * @private
   */
  urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let index = 0; index < rawData.length; ++index) {
      outputArray[index] = rawData.charCodeAt(index);
    }

    return outputArray;
  }

  /**
   * The ready read-only property of the ServiceWorkerContainer interface provides a way of delaying code execution
   * until a service worker is active. It returns a Promise that will never reject, and which waits indefinitely
   * until the ServiceWorkerRegistration associated with the current page has an active worker. Once that condition
   * is met, it resolves with the ServiceWorkerRegistration.
   * @see https://developer.mozilla.org/ru/docs/Web/API/ServiceWorkerContainer/ready
   * @return {Promise.<ServiceWorkerRegistration>}
   */
  registerServiceWorker() {
    const url = getBootstrapParam(ENABLE_PWA)
      ? STATIC_FILES.CRM_WEB_PUSH_FETCH
      : STATIC_FILES.CRM_WEB_PUSH;
    return navigator.serviceWorker.register(url).then(
      (serviceWorkerRegistration) => {
        this.log('Worker was registered ', serviceWorkerRegistration);
        return navigator.serviceWorker.ready;
      },
      (error) => {
        this.error(error);
        return Promise.reject(error);
      },
    );
  }

  /**
   * Create additional param for track URL
   * @param {object} pushSubscription
   * @return {*|string|null}
   */
  getTrackUrlSuffix(pushSubscription) {
    return pushSubscription ? this.getEndpoint(pushSubscription) : null;
  }

  /**
   * Return data that is needed for registration.
   * @param {object} pushSubscription
   * @return {Promise.<Object>}
   */
  getSubscriptionDataForSend(pushSubscription) {
    const csrfToken = this.getCsrfToken();

    let data = `userId=${this.getUserId()}&CSRF_TOKEN=${csrfToken}`;

    if (
      pushSubscription.subscriptionId &&
      pushSubscription.endpoint.indexOf(pushSubscription.subscriptionId) === -1
    ) {
      // Handle version 42 where you have separate subId and Endpoint
      data += `&endpoint=${pushSubscription.endpoint}/${
        pushSubscription.subscriptionId
      }`;
    } else {
      data += `&subscription=${JSON.stringify(pushSubscription)}`;
    }
    if (this.options.vapidAccountId) {
      data += `&vapidAccountId=${this.options.vapidAccountId}`;
    }

    return data;
  }

  /**
   * This method handles the removal of subscriptionId
   * in Chrome 44 by concatenating the subscription Id
   * to the subscription endpoint
   * @param {object} pushSubscription
   * @return {string|*}
   */
  getEndpoint(pushSubscription) {
    // Make sure we only mess with GCM
    if (
      pushSubscription.endpoint.indexOf(
        'https://android.googleapis.com/gcm/send',
      ) !== 0
    ) {
      return pushSubscription.endpoint;
    }

    let mergedEndpoint = pushSubscription.endpoint;
    // Chrome 42 + 43 will not have the subscriptionId attached
    // to the endpoint.
    if (
      pushSubscription.subscriptionId &&
      pushSubscription.endpoint.indexOf(pushSubscription.subscriptionId) === -1
    ) {
      // Handle version 42 where you have separate subId and Endpoint
      mergedEndpoint = `${pushSubscription.endpoint}/${
        pushSubscription.subscriptionId
      }`;
    }
    return mergedEndpoint;
  }

  /**
   * @public
   * @param {Object} options
   */
  setOptions(options = {}) {
    this.options = {
      ...this.options,
      ...options,
    };
    this.loggerEnabled = this.options.loggerEnabled;
  }

  /**
   * Use hand made ajax function because this file may use on *.dateinform site, where jQuery don't include.
   * @param {object} options
   * @return {Promise}
   */
  ajax(options) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      // Open the socket
      xhr.open(
        options.type || 'GET',
        options.url,
        options.async || true,
        options.username,
        options.password,
      );

      // Override mime type if needed
      if (options.mimeType && xhr.overrideMimeType) {
        xhr.overrideMimeType(options.mimeType);
      }
      const headers = options.headers || {};

      // X-Requested-With header
      // For cross-domain requests, seeing as conditions for a preflight are
      // akin to a jigsaw puzzle, we simply never set it to be sure.
      // (it can always be set on a per-request basis or even using ajaxSetup)
      // For same-domain requests, won't change header if already provided.
      if (!options.crossDomain && !headers['X-Requested-With']) {
        headers['X-Requested-With'] = 'XMLHttpRequest';
      }

      // Set headers
      Object.keys(headers).forEach((key) => {
        xhr.setRequestHeader(key, headers[key]);
      });
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          options.success && options.success.call(null, xhr.response);
          resolve(xhr.response);
        } else {
          options.error && options.error.call(null, xhr.response);

          reject(xhr.statusText);
        }
      };
      xhr.onerror = () => {
        options.error && options.error.call(null, xhr.response);
        reject(xhr.statusText);
      };
      xhr.send(options.data || null);
    });
  }

  /**
   * trigger event for run disable other side when we show web push subscription
   * @param eventName
   * @param detail
   */
  triggerEvent(eventName, detail) {
    window.dispatchEvent(new CustomEvent(eventName, {detail}));
  }

  /**
   * @param {*} error
   */
  error(error) {
    this.loggerEnabled && window.console.error(error);
  }

  /**
   * @param {*} message
   */
  log(message) {
    this.loggerEnabled && window.console.log(message);
  }

  /**
   * Use hand made ajax function because this file may use on *.dateinform site, where jQuery don't include.
   * @param {object} obj
   * @return {boolean}
   */
  isEmptyObject(obj) {
    return (Object.keys(obj) || []).length === 0;
  }

  /**
   * @param {string} action
   * @param {string} endpoint
   * @return {Promise}
   */
  track(action, endpoint) {
    this.log(`Track subscribe ${action}`);

    const params = {
      logic: this.options.tracking.logic,
      logicName: this.options.tracking.logicName,
      logicType: this.options.tracking.logicType,
      action: this.options.tracking.action,
      popup: this.options.tracking.popup,
      timeout: this.options.tracking.timeout,
      userId: this.getUserId(),
      track: action,
    };

    if (endpoint) {
      params.endpoint = endpoint;
    }

    const baseUrl = '/api/v1/crm/webPush/track';
    const urlString = this.addUrlParams(baseUrl, params);

    return this.ajax({
      url: urlString,
      type: 'GET',
      success: this.log.bind(this, 'Tracked '),
      error: this.log.bind(this, 'Error while track '),
    });
  }

  /**
   * dmitry.teplyakov say that google can expired regid, and then it will be deleted on server.
   * Need to resend data after each page reload.
   * @public
   */
  resendDataIfGranted() {
    if (!this.isPermissionGranted()) {
      return;
    }

    /**
     * If we already have granted status subscribe just send data to server.
     */
    this.subscribe();
  }

  /**
   * use to add url search params
   * @param {string} url
   * @param {object} params
   * @return {string}
   */
  addUrlParams(url, params) {
    // eslint-disable-next-line new-cap
    return URI(url).addSearch(params).toString();
  }

  /**
   * @return {string|*}
   */
  getUserId() {
    if (this.options.userId) {
      return this.options.userId;
    }
    if (this.isDatinginform()) {
      return this.getURLParam('wpUserId');
    }
    return '';
  }

  /**
   * @return {boolean}
   */
  isDatinginform() {
    return window.location.host.indexOf('dateinform') !== -1;
  }

  /**
   * @param {string} name
   * @return {*}
   */
  getURLParam(name) {
    return this.getURLParamsObject()[name];
  }

  /**
   * @return {*|{}}
   */
  getURLParamsObject() {
    if (!this.urlObject) {
      this.urlObject = {};
      window.location.href.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        (m, name, value) => {
          this.urlObject[name] = value;
        },
      );
    }
    return this.urlObject;
  }

  /**
   * Send subscription data to server
   * @param {object} data
   * @return {Promise}
   */
  sendSubscriptionToServer(data) {
    this.log('Update user subscription');
    return this.ajax({
      url: '/api/v1/crm/webPush/subscribe',
      type: 'POST',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      data,
      success: this.log.bind(this, 'Subscribe success '),
      error: this.log.bind(this, 'Subscribe fail '),
    });
  }

  /**
   * @returns {string}
   */
  getCsrfToken() {
    return getClientInstance().readQuery({query: CSRF_TOKEN_QUERY}).site
      .csrfToken;
  }
}

export default WebPushService;
