import {race} from 'rxjs';
import {filter, bufferTime, bufferCount} from 'rxjs/operators';

/**
 * Such observable implements racing logic between max count of tracks
 * and max waiting time
 * @see InventoryTrackingService.js
 *
 * @param {Subject} subject
 * @param {Number} count
 * @param {Number} delay
 * @returns {Observable}
 */
const getInventoryTrackingObservable = (subject, count, delay) =>
  race(subject.pipe(bufferCount(count)), subject.pipe(bufferTime(delay))).pipe(
    // Just skip emited empty arrays of tracks if they was excluded before
    filter((data) => data && data.length),
  );

export default getInventoryTrackingObservable;
