import React from 'react';
import PropTypes from 'prop-types';

import {ButtonFlat, Icon} from '@phoenix/ui';

import css from '../styles/MediaUploadPopupBack.css';

const MediaUploadPopupBack = ({onClick, inverse}) => (
  <div className={css.back}>
    <ButtonFlat
      round
      onClick={onClick}
      data-test="mediaUploadBack"
      trackingName="back"
    >
      <Icon standard type="arrow-left" inverse={inverse} />
    </ButtonFlat>
  </div>
);

MediaUploadPopupBack.propTypes /* remove-proptypes */ = {
  onClick: PropTypes.func.isRequired,
  inverse: PropTypes.bool,
};

MediaUploadPopupBack.defaultProps = {
  inverse: false,
};

export default MediaUploadPopupBack;
