import defer from 'lodash/defer';

import {getClientInstance} from '@core/graphql/client';
import type {PaymentAnswer} from '@core/payment/payProcess/types/paymentAnswerProps';

const updateIsOneClickAllowedAfterPayment = (
  paymentAnswer: PaymentAnswer,
): PaymentAnswer => {
  /**
   * 'defer' needs let OneClickParamsQuery has finished request from request batch, and after that the cache will
   * be evicted, and another request with new variables started in new request batch
   */
  defer(() => {
    getClientInstance().cache.evict({
      id: 'Payment:{}',
      fieldName: 'isOneClickAllowed',
      broadcast: !paymentAnswer.status,
    });
  });

  return paymentAnswer;
};

export default updateIsOneClickAllowedAfterPayment;
